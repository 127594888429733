import React, { useContext, useEffect } from "react";
import kurta from "../../Assets/Images/WomenPage/kurta.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../Url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../../context/ProductContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
function WesternCard() {
  const navigate = useNavigate();
  const { westernCollection, } = useContext(ProductContext);

  const [categoryId, setCategoryId] = useState("");

  const handleClick = (categoryId) => {
    setCategoryId(categoryId);
    navigate(`/products/${categoryId}`);
  };

  console.log("Ethinic COllection===>>>>", westernCollection);

  return (
    <>
      <div className="hidden md:block">
        <div className="py-3">
          <div className="flex justify-around  gap-2 px-16">
            {westernCollection?.map((e, id) => (
              <>
                {id <= 3 && (
                  <div
                    key={e._id}
                    className="w-[20vw]"
                  >
                    <img
                      src={kurta}
                      className="w-full h-[35vh] object-fill"
                      alt="..."
                    />
                    <div className=" py-1  w-full">
                      <div className="md:text-2xl text-black font-semibold text-center">
                        {e.categoryName}
                      </div>
                    </div>
                    <div className="flex justify-center py-1">
                      <button
                        className="text-md  font-semibold bg-black text-white px-4 py-1 cursor-pointer  rounded-sm"
                        onClick={() => {
                          handleClick(e.id);
                          // setCategoryName(e.category.name);
                        }}
                      >
                        EXPLORE
                      </button>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="py-8">
        <div className="flex justify-around  gap-2 px-16">
            {westernCollection?.map((e, id) => (
              <>
                {id > 3 && id <= 6 && (
                  <div
                    key={e._id}
                    className="w-[27vw] "
                  >
                    <img
                      src={kurta}
                      className="w-full h-[51vh] object-fill"
                      alt="..."
                    />
                    <div className=" py-1  w-full">
                      <div className="md:text-2xl text-black font-semibold text-center">
                        {e.categoryName}
                      </div>
                    </div>
                    <div className="flex justify-center py-1">
                      <button
                        className="text-md  font-semibold bg-black text-white px-4 py-1 cursor-pointer rounded-sm"
                        onClick={() => {
                          handleClick(e.id);
                          // setCategoryName(val.category.name);
                        }}
                      >
                        EXPLORE
                      </button>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="block w-full md:hidden">
        <div className="w-full py-5 px-5 ">
          <Swiper
            // loop={true}
            spaceBetween={20}
            slidesPerView={2.3} // Adjust this value
          >
            {westernCollection.map((val, idx) => (
              <>
                {idx <= 4 && (
                  <>
                    <SwiperSlide key={val._id}>
                      <div className=" rounded-xl  h-full w-full">
                        <img
                          src={kurta}
                          className="h-[18vh]  w-[45vw] object-fill rounded-xl"
                          alt="..."
                        />
                        <div className=" ">
                          <div className="text-xl text-center text-black py-2 uppercase tracking-widest font-semibold">
                            {val.categoryName.substring(0, 8)}
                          </div>
                        </div>
                        <div className=" flex justify-center w-full">
                          <div
                            className="text-md font-semibold  bg-black text-white px-3 py-1 cursor-pointer"
                            onClick={() => {
                              handleClick(val.id);
                              // setCategoryName(val.category.name);
                            }}
                          >
                            EXPLORE
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                )}
              </>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="block w-full md:hidden">
        <div className="w-full py-5 px-5 ">
          <Swiper
            // loop={true}
            spaceBetween={20}
            slidesPerView={1.3} // Adjust this value
          >
            {westernCollection.map((val, idx) => (
              <>
                {idx > 4 && idx < 11 && (
                  <>
                    <SwiperSlide key={val._id}>
                      <div className=" rounded-xl h-full w-full ">
                        <img
                          src={kurta}
                          className="h-[28vh]  w-[74vw]  rounded-xl"
                          alt="..."
                        />
                        <div className=" ">
                          <div className="text-2xl text-center text-black py-2 font-semibold">
                            {val.categoryName}
                          </div>
                        </div>
                        <div className=" flex justify-center w-full">
                          <div
                            className="text-md font-semibold  bg-black text-white px-4 py-2 cursor-pointer"
                            onClick={() => {
                              handleClick(val.id);
                              // setCategoryName(val.category.name);
                            }}
                          >
                            EXPLORE
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                )}
              </>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default WesternCard;
