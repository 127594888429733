import React, { useEffect, useState } from "react";
// import Navbar from "../Components/Navbar";
import Filter from "../Components/Filter";
import Product from "../Components/Product";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import Breadcrumb from "../Components/Breadcrumb";
import { products } from "../Assets/Data/Products";
import FooterBtn from "../Components/FooterBtn";
import FooterHeader from "../Components/FooterHeader";
import { useParams } from "react-router-dom";
import { API_URL } from "../Url";
import axios from "axios";
import { toast } from "react-toastify";
import ProductCard from "../Components/ProductCard";
import Pagination from "../Components/atoms/Pagination";
import Loader from "../Components/atoms/ProductLoader";
import ProductLoader from "../Components/atoms/ProductLoader";
import Switch from "react-switch";
import { IoIosCloseCircleOutline } from "react-icons/io";

function ProductPage() {
  const { categoryId } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [sortOption, setSortOption] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [checked, setChecked] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // Added currentPage state
  const [allProducts, setAllProducts] = useState([]);
  const [categoryFilterArray, setCategoryFilterArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState("");

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const uncheckedIcon = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 26,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      III
    </div>
  );

  const checkedIcon = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 26,
        color: "#fff",
        paddingRight: 3,
      }}
    >
      IIIII
    </div>
  );

  const getProductByCategory = async () => {
    setIsLoading(true);
    const requestBody = {
      variationOptions: categoryFilterArray,
      priceRange: selectedPrice,
      page: currentPage,
      limit: 9,
      sortOrder: sortOption,
    };

    try {
      const res = await axios.post(
        `${API_URL}/products/category/${categoryId}`,
        requestBody
      );
      if (res?.data) {
        setAllProducts(res?.data?.data);
        setTotalPages(res?.data?.totalNumberOfPages);
        setIsLoading(false);
      }

      // console.log(">>", res?.data);
    } catch (error) {
      console.log("error====>>>", error);
      toast.error("Error in fetching Product By Category");
    }
  };

  const handlePageChange = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    // getProductByCategory(page);
  };
  const handleFilterSet = (filterArray) => {
    console.log("handleFilterSet filterArray====>", filterArray);
    setCategoryFilterArray([...filterArray]);
    if (filterArray.length > 0) {
      setCurrentPage(1);
    }
  };
  const handlePriceSelect = (price) => {
    setSelectedPrice(price);
    setCurrentPage(1); // Set current page to 1 whenever a price is selected
  };

  useEffect(() => {
    setCategoryFilterArray([]);
    setSortOption("");
    setSelectedPrice("");
    // getProductByCategory();
  }, [categoryId]);

  useEffect(() => {
    getProductByCategory();
  }, [currentPage, categoryFilterArray, categoryId, sortOption, selectedPrice]);

  const categoryName = localStorage.getItem("category");

  console.log("allProducts===>>", allProducts);
  return (
    <>
      <div className="hidden md:block">
        <Breadcrumb
          breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Products", path: `/products/${categoryId}` },
            // { title: "Productdetail", path: `/products/product/${id}` },
          ]}
        />
      </div>
      {showFilter && (
        <div
          className={`fixed w-full top-16 h-full pb-16 overflow-y-auto z-50 bg-slate-300 left-0 ${
            showFilter ? "block" : "hidden"
          }`}
        >
          <div className="px-3 py-2">
            <div className="flex justify-end py-2" onClick={() => setShowFilter(false)}>
            <IoIosCloseCircleOutline size={30} color="gray" />

            </div>
            <div className=" p-1 rounded-md border-2">
              {/* <label htmlFor="price">Price</label> */}
              <div
                onClick={() => setIsOpen(!isOpen)}
                className="cursor-pointer py-2  px-3 flex justify-between items-center"
              >
                <div className="text-lg">Price</div>
                <div>
                  {isOpen ? (
                    <FaChevronDown color="gray" />
                  ) : (
                    <FaChevronRight color="gray" />
                  )}
                </div>
              </div>
              {isOpen && (
                <div className=" ml-4 py-2 space-y-2">
                  <div>
                    <input
                      className=""
                      type="radio"
                      id="0-5000"
                      name="price"
                      value="0-5000"
                      checked={selectedPrice === "0-5000"}
                      onChange={() => handlePriceSelect("0-5000")}
                    />
                    <label htmlFor="0-5000" className="ml-2">
                      Below 5000
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="5000-10000"
                      name="price"
                      value="5000-10000"
                      checked={selectedPrice === "5000-10000"}
                      onChange={() => handlePriceSelect("5000-10000")}
                    />
                    <label htmlFor="5000-10000" className="ml-2">
                      5000-10,000
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="10000-20000"
                      name="price"
                      value="10000-20000"
                      checked={selectedPrice === "10000-20000"}
                      onChange={() => handlePriceSelect("10000-20000")}
                    />
                    <label htmlFor="10000-20000" className="ml-2">
                      10,000-20,000
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="20000-50000"
                      name="price"
                      value="20000-50000"
                      checked={selectedPrice === "20000-50000"}
                      onChange={() => handlePriceSelect("20000-50000")}
                    />
                    <label htmlFor="20000-50000" className="ml-2">
                      Above 20,000
                    </label>
                  </div>
                  {/* Add more options here */}
                </div>
              )}
              {selectedPrice && (
                <div className="flex flex-row-reverse mr-2 my-2">
                  <button
                    className="  text-black py-1 px-2 rounded"
                    onClick={() => handlePriceSelect("")}
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
            <Filter filterset={handleFilterSet} />
          </div>
        </div>
      )}

      {/* // */}
      <div className="flex w-full gap-6 lg:px-20">
        <div className="lg:w-[22%] hidden md:block">
          <div className=" p-1 rounded-md border-2">
            {/* <label htmlFor="price">Price</label> */}
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="cursor-pointer py-2  px-3 flex justify-between items-center"
            >
              <div className="text-lg">Price</div>
              <div>
                {isOpen ? (
                  <FaChevronDown color="gray" />
                ) : (
                  <FaChevronRight color="gray" />
                )}
              </div>
            </div>
            {isOpen && (
              <div className=" ml-4 py-2 space-y-2">
                <div>
                  <input
                    className=""
                    type="radio"
                    id="0-5000"
                    name="price"
                    value="0-5000"
                    checked={selectedPrice === "0-5000"}
                    onChange={() => handlePriceSelect("0-5000")}
                  />
                  <label htmlFor="0-5000" className="ml-2">
                    Below 5000
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="5000-10000"
                    name="price"
                    value="5000-10000"
                    checked={selectedPrice === "5000-10000"}
                    onChange={() => handlePriceSelect("5000-10000")}
                  />
                  <label htmlFor="5000-10000" className="ml-2">
                    5000-10,000
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="10000-20000"
                    name="price"
                    value="10000-20000"
                    checked={selectedPrice === "10000-20000"}
                    onChange={() => handlePriceSelect("10000-20000")}
                  />
                  <label htmlFor="10000-20000" className="ml-2">
                    10,000-20,000
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="20000-50000"
                    name="price"
                    value="20000-50000"
                    checked={selectedPrice === "20000-50000"}
                    onChange={() => handlePriceSelect("20000-50000")}
                  />
                  <label htmlFor="20000-50000" className="ml-2">
                    Above 20,000
                  </label>
                </div>
                {/* Add more options here */}
              </div>
            )}
            {selectedPrice && (
              <div className="flex flex-row-reverse mr-2 my-2">
                <button
                  className="  text-black py-1 px-2 rounded"
                  onClick={() => handlePriceSelect("")}
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          <Filter key={categoryId} filterset={handleFilterSet} />
        </div>
        <div className="lg:w-[78%] w-full  ">
          {isLoading ? (
            <>
              <ProductLoader />
            </>
          ) : (
            <>
              <div className="px-3 md:py-3 py-5 md:w-[100%] w-full text-center md:text-3xl inline-block font-bold uppercase">
                {categoryName} COLLECTION
              </div>
              <div className="lg:w-[813px] hidden md:block text-center mx-auto mb-5 mt-2 text-sm">
                Daily-wear kurta sets to breezy loungewear, summer dresses to
                stunning lehengas, find everything you're looking for in our
                collection of onlineclothes for women.
              </div>
              <div className="flex bg-[#EAEAEA] border-t-4  border-b-4 items-center justify-between my-2 px-3 text-gray-700 py-2">
                <div
                  className="block md:hidden uppercase"
                  onClick={() => setShowFilter(true)}
                >
                  Filter
                </div>
                {/* {showFilter &&
              (
              <div className="absolute  w-full top-14 h-[80vh] overflow-y-auto z-50 bg-slate-400 left-0">
                <div className="px-3 py-2">

                    <Filter showFilter={ showFilter} setShowFilter={setShowFilter} />
                </div>
                  </div>
                )
                } */}
                <div className="pr-2 hidden md:block">
                  {allProducts?.length} Products
                </div>
                <div className="flex items-center gap-5">
                  {/* //add a togle btn for grid 3 and grid 4 */}
                  <div className="">
                    <Switch
                      onChange={handleChange}
                      checked={checked}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={25}
                      uncheckedIcon={uncheckedIcon}
                      checkedIcon={checkedIcon}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={30}
                      width={69}
                      className="react-switch"
                    />
                  </div>
                  <div>
                    <select
                      name="sort"
                      id="sort"
                      className="rounded-lg py-1 px-1 uppercase"
                      value={sortOption}
                      onChange={(e) => setSortOption(e.target.value)}
                    >
                      <option value="">Sort By</option>
                      {/* <option value="best">Best Selling</option>
                    <option value="low">Price, low to high</option>
                    <option value="high">Price, high to low</option> */}
                      <option value="1">Date, old to new</option>
                      <option value="-1">Date, new to old</option>
                    </select>
                  </div>
                </div>
              </div>
              <ProductCard data={allProducts} gridStyle={checked} />
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}

          {/* <Product  /> */}
        </div>
      </div>
      <FooterHeader />
      {/* border */}
    </>
  );
}

export default ProductPage;
