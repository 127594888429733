import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "../Css/Modal.css";
import { useVarible } from "../utils/store/useVariable";
//import data from "../JSONs/Users.json"

function LoginSignup(props) {
	const { show, setShow } = props;
	const [userData, setUserData] = useState([]);
	const [login, setLogin] = useState(true);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const user = useVarible((state) => state.user);
	const setUser = useVarible((state) => state.setUser);
	const setClear = useVarible((state) => state.setClear);
	useEffect(() => {
		fetchData();
		console.log("users", userData);
	}, []);

	const handleClose = () => {
		setShow(false);
		setLogin(true);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const fetchData = async () => {
		try {
			const response = await fetch("./JSONs/Users.json");
			const data = await response.json();
			setUserData(data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const validateEmail = (email) => {
		// Regular expression for validating Gmail address
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	};

	const validatePassword = (password) => {
		// Regular expression for validating password (minimum 8 characters, at least one uppercase letter, one lowercase letter, and one special character)
		const passwordRegex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		return passwordRegex.test(password);
	};

	const handleLogin = () => {
		console.log("login handle");
		const isValidEmail = validateEmail(formData.email);
		const isValidPassword = validatePassword(formData.password);

		if (isValidEmail && isValidPassword) {
			setFormData({ email: "", password: "" });
			const User = userData?.find(
				(User) => User?.email === formData?.email
			);

			if (User) {
				const hashedPassword = formData?.password;
				if (hashedPassword === User?.password) {
					alert("Login successful!");
					setFormData({ email: "", password: "" });
					setUser(User);
					//handleClose();
				} else {
					alert("Incorrect password");
				}
			} else {
				alert("User not found");
			}
		} else {
			alert("Invalid email or password format.");
		}
	};
	console.log("login credentials", formData);
	return (
		<div>
			<Modal show={show} onHide={handleClose}>
				<div className="Login_modal p-3">
					{user?.username ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								justifySelf: "center",
								alignItems: "center",
							}}
						>
							<img
								style={{
									width: "12vh",
									height: "12vh",
									borderRadius: "50%",
									position: "relative",
								}}
								src={user?.profile_picture_url}
								alt="jhf"
							/>
							<div>{user?.username}</div>
							<button
								onClick={() => {
									setClear();
									window.location.reload(true);
								}}
							>
								Logout
							</button>
						</div>
					) : (
						<>
							{login ? (
								<>
									<div className="modal_header">Login</div>
									<Modal.Body>
										<form>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
												}}
											>
												<label>Email address</label>
												<input
													type="email"
													name="email"
													id="email"
													placeholder="name@example.com"
													autoFocus
													value={formData?.email}
													onChange={(e) =>
														handleInputChange(e)
													}
													style={{
														borderRadius: 5,
														borderWidth: 0,
														backgroundColor:
															"#ffd980",
														width: "20vw",
														height: "3vh",
														padding: "2%",
													}}
												/>
											</div>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
												}}
											>
												<label>Password</label>
												<input
													type="password"
													name="password"
													id="password"
													placeholder="Password"
													value={formData?.password}
													onChange={(e) =>
														handleInputChange(e)
													}
													style={{
														borderRadius: 5,
														borderWidth: 0,
														backgroundColor:
															"#ffd980",
														width: "15vw",
														height: "3vh",
														padding: "2%",
													}}
												/>
											</div>
										</form>
									</Modal.Body>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-evenly",
											justifyItems: "center",
											alignItems: "center",
										}}
									>
										-------OR----------
										<div
											style={{ padding: 6 }}
											onClick={() => setLogin(false)}
										>
											Signup
										</div>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "space-evenly",
											justifyItems: "center",
											alignItems: "center",
										}}
									>
										<Button
											className="btn-login"
											variant="primary"
											onClick={() => {
												handleLogin();
											}}
										>
											Login
										</Button>
									</div>
								</>
							) : (
								<>
									<div className="modal_header">Signup</div>
									<Modal.Body>
										<Form>
											<Form.Group
												className="mb-3"
												controlId="exampleForm.ControlInput1"
											>
												<Form.Label>
													Email address
												</Form.Label>
												<Form.Control
													type="email"
													placeholder="name@example.com"
													autoFocus
												/>
											</Form.Group>
											<Form.Group
												className="mb-3"
												controlId="exampleForm.ControlInput1"
											>
												<label>Phone number</label>
												<Form.Control
													type="phone"
													placeholder="10 digit mobile number"
													autoFocus
												/>
											</Form.Group>
											<Form.Group
												className="mb-3"
												controlId="exampleForm.ControlInput1"
											>
												<label>First name</label>
												<Form.Control
													type="name"
													placeholder="First name"
													autoFocus
												/>
											</Form.Group>
											<Form.Group
												className="mb-3"
												controlId="exampleForm.ControlInput1"
											>
												<label>Last name</label>
												<Form.Control
													type="name"
													placeholder="Last name"
													autoFocus
												/>
											</Form.Group>
											<Form.Group
												className="mb-3"
												controlId="exampleForm.ControlInput1"
											>
												<label>Password</label>
												<Form.Control
													type="password"
													placeholder="Password"
												/>
											</Form.Group>
											<Form.Group
												className="mb-3"
												controlId="exampleForm.ControlInput1"
											>
												<label>Confirm Password</label>
												<input
													type="password"
													placeholder="Confirm Password"
												/>
											</Form.Group>
										</Form>
									</Modal.Body>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-evenly",
											justifyItems: "center",
											alignItems: "center",
										}}
									>
										-----Already have an account--------
										<div
											style={{ padding: 6 }}
											onClick={() => setLogin(true)}
										>
											Login
										</div>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "space-evenly",
											justifyItems: "center",
											alignItems: "center",
										}}
									>
										<Button
											className="btn-login"
											variant="primary"
										>
											Signup
										</Button>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</Modal>
		</div>
	);
}

export default LoginSignup;
