import "./App.css";
import Header from "./Components/Header";
import ProductDetails from "./Pages/ProductDetails";
import Footer from "./Components/Footer";
import ComponentRoutes from "./ComponentRoutes";
import Navbar from "./Components/Navbar";

function App() {
  
	return (
		<>
			{/* <Header /> */}
			<Navbar/>
			<ComponentRoutes />
			
			<Footer />
		</>
	);
}

export default App;
