import React from "react";
import sort from "../Assets/Images/ProductDetails/sort.png";
function Ratings() {
  return (
    <>
      <div className="text-center font-medium text-lg py-3">Ratings</div>
      <div className="flex justify-between">
        <div></div>
        <div className="flex my-2 px-20">
          <div
            className="ml-4 inline-block border-2 rounded-lg px-2 py-3 border-[#CDCDCD]"
          >
            Write a review
          </div>
          <div>
            <img src={sort} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Ratings;
