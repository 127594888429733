import React, { useContext, useState } from 'react'
import CheckoutCard from '../../Components/atoms/CheckoutCard'
import axios from 'axios';
import { API_URL } from '../../Url';
import { ProductContext } from '../../context/ProductContext';
import NumericCaptcha from 'react-numeric-captcha';
import Captcha from "react-numeric-captcha";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
// import * as dotenv from 'dotenv';

// dotenv.config();
// require('dotenv').config();
console.log("test===>>>>.",process.env.REACT_APP_TEST_STRIPE_PUBLISH_KEY)
function Summery({ address, selectedAdd }) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

 
    const [captchaSuccess, setCaptchaSuccess] = useState(false);
    console.log(process.env.REACT_APP_TEST_STRIPE_PUBLISH_KEY);
        console.log("selectedAdd", selectedAdd);
    console.log("address", address)
    const filteredAddress = address.filter(addr => addr._id === selectedAdd);
    console.log("filteredAddress", filteredAddress);
    const { totalAmount } = useContext(ProductContext);
    const navigate=useNavigate()

    const handleCheckout = async() => {
        try {
          const res= await axios.post(`${API_URL}/payment`,{amount:totalAmount},{
            headers: {
              Authorization: localStorage.getItem('token')
            }
          });
          console.log("payments===..............",res?.data);
    
          if (res?.data?.status === 200) {
            
            window.location.href = res?.data?.redirection;
          }
        } catch (error) {
          console.log("Error in checkout", error);
          throw error; // Rethrow the error to propagate it further if needed
          
        }
    }
    const handleStripeCheckout = async () => {
      try {
        // Load Stripe
        const stripe = await loadStripe(process.env.REACT_APP_TEST_STRIPE_PUBLISH_KEY);        // Fetch the checkout session from your server (replace with your server's URL)
        const response = await axios.post(`${API_URL}/payment/stripe`,{},{
          headers: {
            Authorization: localStorage.getItem('token')
          }
        
        });
        const session = response.data;
    
        // Redirect to the checkout session
        console.log("session")
        const result = await stripe.redirectToCheckout({ sessionId: session.id });
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network error, display the localized error message to your customer.
          alert(result.error.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    console.log("captchaSuccess", captchaSuccess)
  return (
      <>
        <div className='flex flex-col-reverse md:flex-row w-full gap-10 md:px-20 px-5 '>
              <div className='md:w-[60%] text-black  '>
                  <div className='md:flex gap-5 md:p-5 p-4 bg-white md:h-[25vh]'>
                      
                  <div className='md:font-semibold md:py-0 py-3 '>
                      Delivery to
                      </div>
                      <div>
                      <div className='md:px-4'>
                        <div className='flex justify-between items-baseline'>
                        <div className='font-bold text-lg mb-3'>{filteredAddress[0]?.name}</div>
                        <div className='mb-3 text-sm py-1 border-2 rounded-2xl px-2 bg-slate-100'>{filteredAddress[0]?.addressType}</div>
                        </div>
                        <div className='text-sm'>{filteredAddress[0]?.flatNumber},{filteredAddress[0]?.landmark}, {filteredAddress[0]?.district}</div>
                        <div className='text-sm'>{filteredAddress[0]?.locality}, {filteredAddress[0]?.state}</div>
                        <div className='text-sm'>India- {filteredAddress[0]?.pincode}</div>  
                        <div className='flex justify-between mt-4'>
                        <div className='text-sm'>Phone- <span className='font-semibold'>{filteredAddress[0]?.mobile}</span></div>
                       
                        </div>   
                    </div>
                      </div>
    
                  </div>
                  <div className=' mt-10'>
                      <div className='text-sm text-gray-500'>Payment method</div>
                      <div className='md:py-3 py-5 md:mb-0 mb-8'>
                      <div className='bg-white flex items-center py-2 px-5'>
    <input type="radio" className='h-5 w-5' id="stripe" name="payment" value="Stripe Gateway" onClick={()=>setSelectedPaymentMethod('stripe')}  />
    <label htmlFor="stripe" className='px-5 cursor-pointer'>Stripe </label>
</div> 
{selectedPaymentMethod === 'stripe' && (
    <button className='px-2 py-2 bg-green-600 text-white my-5 rounded mx-10' onClick={handleStripeCheckout}>Submit</button>
)}
<div className='bg-white flex items-center py-2 px-5 mt-3'>
    <input type="radio" className='h-5 w-5' id="phonepay" name="payment" value="PhonePay Gateway" onClick={()=>setSelectedPaymentMethod('phonepay')} />
    <label htmlFor="phonepay" className='px-5 cursor-pointer'>PhonePay </label>
</div>
{selectedPaymentMethod === 'phonepay' && (
    <button className='px-2 py-2 bg-green-600 text-white my-5 rounded mx-10' onClick={handleCheckout}>Submit</button>
)}
<div className='bg-white flex items-center py-2 px-5 mt-3'>
    <input type="radio" className='h-5 w-5' id="cod" name="payment" value="Cash on Delivery" onClick={() => setSelectedPaymentMethod('cod')}/>
    <label htmlFor="cod" className='px-5 cursor-pointer'>Cash on Delivery</label>
</div>
{selectedPaymentMethod === 'cod' && (
    <>
        <div className='mt-5 ' >
            <Captcha onChange={(status) => setCaptchaSuccess(status)} />
        </div>                 
        <button className='px-2 py-2 bg-green-600 text-white my-5' onClick={() => {
            if (captchaSuccess) {
                navigate('/success');
            } else {
                toast.error('Please enter the captcha!');
            }
        }}>Submit</button>
    </>
)}
                      </div>
                    </div>
        </div>
        {/* this div will show 1st in mobile view */}
        <div className='md:w-[40%]  md:my-0 my-1 rounded-md'>  
          <div className='bg-white p-3'>

            <CheckoutCard />
          </div>
    </div>
          </div>
          
      </>
  )
}

export default Summery