import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useNavigate } from "react-router-dom";
import closed from "../Assets/Images/ProductDetails/close-icon.png";
import axios from "axios";
import { toast } from "react-toastify";
import { ProductContext } from "../context/ProductContext";
import { API_URL } from "../Url";
import Breadcrumb from "../Components/Breadcrumb";

function AddProductitems() {
  const { parentCategory,handleAddProductItem,productId } = useContext(ProductContext);

  const [productName, setProductName] = useState("");

  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  const [mpn, setMpn] = useState("");
  const [brandId, setBrandId] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showupload, setShowUpload] = useState(false);
  const [category, setCategory] = useState({
    mainCategory: "",
    subCategory: "",
    subSubCategory: "",
    subSubSubCategory: "", 
  });
  // console.log(imageFiles);
  const [childCategories, setChildCategories] = useState([]);
  const [subchildCategories, setSubChildCategories] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [selectedChildId, setSelectedChildId] = useState("");
  const [selectedSubChildId, setSelectedSubChildId] = useState("");
  const [subSubChildCategories, setSubSubChildCategories] = useState([]);
  const [selectedSubSubChildId, setSelectedSubSubChildId] = useState("");
  // const [productId, setProductId] = useState([]);
  const navigate = useNavigate();
  const resetForm = () => {
    setProductName("");
    setDescription("");
    setSku("");
    setMpn("");
    setBrandId("");
    setCategory({
      mainCategory: "",
      subCategory: "",
      subSubCategory: "",
      subSubSubCategory: "",
    });
  };

  const handleAddProductSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission and page reload
    if (
      !productName ||
      !description ||
      !category.mainCategory ||
      !category.subCategory ||
      !sku ||
      !mpn ||
      !brandId
      //   !selectedFiles
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (
      !selectedParentId ||
      !selectedChildId 
      // !selectedSubChildId ||
      // !selectedSubSubChildId
    ) {
      toast.error("Please select all categories.");
      return;
    }
    const productData = {
      name: productName,
      description: description,
      sku: sku,
      mpn: mpn,
      brandId: brandId,
      categoryIds: `${selectedParentId}/${selectedChildId}`,
      // productImage:
    };
    // console.log("ProductId=>>>",productData)
    try {
      console.log(">>>",productData)
     await handleAddProductItem(productData);
      setShowUpload(!showupload);
      resetForm();

    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Error adding product. Check console for details.");
    }
  };














  const handleFileChange = (event, fileType) => {
    const files = Array.from(event.target.files);
    console.log("Selected files:", files);

    if (fileType === "image") {
      setImageFiles([...imageFiles, ...files]);
    } else if (fileType === "video") {
      setVideoFiles([...videoFiles, ...files]);
    }
  };

  const removeFile = (index, fileType) => {
    if (fileType === "image") {
      const newImageFiles = [...imageFiles];
      newImageFiles.splice(index, 1);
      setImageFiles(newImageFiles);
    } else if (fileType === "video") {
      const newVideoFiles = [...videoFiles];
      newVideoFiles.splice(index, 1);
      setVideoFiles(newVideoFiles);
    }
  };

  const handleChildCategory = async (id) => {
    try {
      const response = await axios.get(
        `${API_URL}/products/child-category/${id}`
      );
      const childCategories = response.data;
      // console.log("Child Categories:", childCategories);
      setChildCategories(childCategories);
    } catch (error) {
      console.error("Error fetching child categories:", error.message);
    }
  };
  // ...

  const handleSubChildCategory = async (id) => {
    try {
      const response = await axios.get(
        `${API_URL}/products/child-category/${id}`
      );
      setSubChildCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubSubChildCategory = async (id) => {
    try {
      const response = await axios.get(
        `${API_URL}/products/child-category/${id}`
      );
      setSubSubChildCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlefileUpload = async () => {
    try {
      setLoader(true);
      const formData = new FormData();

      for (let i = 0; i < imageFiles.length; i++) {
        formData.append("images", imageFiles[i]);
      }

      if (videoFiles.length > 0) {
        for (let j = 0; j < videoFiles.length; j++) {
          formData.append("videos", videoFiles[j]);
        }
      }

      const res = await fetch(
        `${API_URL}/products/add-product-item-assets/${productId}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await res.json();
      console.log(data);
      if (res.ok) {
        toast.success("Assets upload successful");
        setLoader(false);
        setImageFiles([]);
        setVideoFiles([]);
      }
      // navigate("/products");
      setShowUpload(!showupload);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (productId) {
      console.log("Product ID updated:", productId);
    }
  }, [productId, imageFiles, videoFiles]);

  return (
    <>
      {/* {console.log("img", imageFiles)} */}
      <Breadcrumb
        breadcrumbs={[
          { title: "Home", path: "/" },
          { title: "AddProductsItems", path: "/addproductsitem" },
          // { title: "Shoes", path: "/products/shoes" },
        ]}
      />
      <div className=" bg-gray-200  text-[#9f2f2f] text-xl md:text-3xl py-3 px-5 md:px-16 font-bold  ">
        ADD PRODUCT ITEMS
      </div>
      {!showupload && (
        <>
          <div className="flex justify-center h-full md:px-0 px-2 bg-gray-100 ">
            <div className="md:w-[80vw] md:px-12 py-4  rounded-md ">
              <form>
                <label className="block text-gray-700 text-lg font-bold mb-2">
                  Product Name : <span className="text-red-800">*</span>
                </label>
                <input
                  className="w-full p-2 border rounded-md"
                  type="text"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  required
                />
                <label className="block text-gray-700 text-lg font-bold mt-4 mb-2">
                  Description: <span className="text-red-800">*</span>
                </label>
                <textarea
                  rows={5}
                  className="w-full p-2 border rounded-md"
                  type="text"
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
                {/* Catgory */}
                <div className="mt-4 text-lg">
                  <label className="block text-gray-700 text-lg font-bold mb-2">
                    Category :<span className="text-red-800">*</span>
                  </label>

                  <div className="md:flex space-y-5 md:space-y-0  md:w-full md:space-x-0 space-x-0 md:gap-2  ">
                    {/* Main Category Dropdown */}
                    <select
                      required
                      className="md:w-1/4 p-2 border w-full md:mx-0  rounded-md"
                      value={category.mainCategory}
                      onChange={(e) => {
                        const selectedMainCategory = parentCategory.find(
                          (mainCat) => mainCat.categoryName === e.target.value
                        );
                        if (selectedMainCategory) {
                          setCategory({
                            ...category,
                            mainCategory: e.target.value,
                            subCategory: "",
                            subSubCategory: "",
                            subSubSubCategory: "",
                          });
                          handleChildCategory(selectedMainCategory.id);
                          setSelectedParentId(selectedMainCategory.id);
                        }
                      }}
                    >
                      <option value="">Main Category</option>
                      {parentCategory.map((mainCat) => (
                        <option key={mainCat.id} value={mainCat.name}>
                          {mainCat.categoryName}
                        </option>
                      ))}
                    </select>

                    {/* Sub Category Dropdown */}
                    {childCategories.length > 0 && (
                      <select
                        required
                        className="md:w-1/4 w-full p-2 border rounded-md"
                        value={category.subCategory}
                        onChange={(e) => {
                          const selectedChildCategory = childCategories.find(
                            (childCat) =>
                              childCat.categoryName === e.target.value
                          );

                          setCategory({
                            ...category,
                            subCategory: e.target.value,
                            subSubCategory: "",
                            subSubSubCategory: "",
                          });

                          if (selectedChildCategory) {
                            handleSubChildCategory(selectedChildCategory.id);
                            setSelectedChildId(selectedChildCategory.id);
                          }
                        }}
                      >
                        <option value="">Child Category</option>
                        {childCategories.map((childCat) => (
                          <option key={childCat.id} value={childCat.name}>
                            {childCat.categoryName}
                          </option>
                        ))}
                      </select>
                    )}

                    {/* Sub Sub Category Dropdown */}
                    {/* {subchildCategories.length > 0 && (
                      <select
                        required
                        className="w-1/4 p-2 border rounded-md"
                        value={category.subSubCategory}
                        onChange={(e) => {
                          const selectedSubChildCategory =
                            subchildCategories.find(
                              (subchildCat) =>
                                subchildCat.categoryName === e.target.value
                            );

                          setCategory({
                            ...category,
                            subSubCategory: e.target.value,
                            subSubSubCategory: "",
                          });

                          if (selectedSubChildCategory) {
                            handleSubSubChildCategory(
                              selectedSubChildCategory.id
                            );
                            setSelectedSubChildId(selectedSubChildCategory.id);
                          }
                        }}
                      >
                        <option value="">Select Sub Sub Category</option>
                        {subchildCategories.map((subchildCat) => (
                          <option key={subchildCat.id} value={subchildCat.name}>
                            {subchildCat.categoryName}
                          </option>
                        ))}
                      </select>
                    )} */}

                    {/* Sub Sub Sub Category Dropdown */}
                    {/* {subSubChildCategories.length > 0 && (
                      <select
                        required
                        className="w-1/4 p-2 border rounded-md"
                        value={category.subSubSubCategory}
                        onChange={(e) => {
                          const selectedSubSubChildCategory =
                            subSubChildCategories.find(
                              (subSubChildCat) =>
                                subSubChildCat.categoryName === e.target.value
                            );

                          setCategory({
                            ...category,
                            subSubSubCategory: e.target.value,
                          });

                          if (selectedSubSubChildCategory) {
                            setSelectedSubSubChildId(
                              selectedSubSubChildCategory.id
                            );
                          }
                        }}
                      >
                        <option value="">Select Sub Sub Sub Category</option>
                        {subSubChildCategories.map((subSubChildCat) => (
                          <option
                            key={subSubChildCat.id}
                            value={subSubChildCat.name}
                          >
                            {subSubChildCat.categoryName}
                          </option>
                        ))}
                      </select>
                    )} */}
                  </div>
                </div>

                <div className="flex gap-3 w-full">
                  <div className="w-[50%] mt-4">
                    <div className="flex justify-between ">
                      <label className="block text-gray-700 text-lg font-bold ">
                        SKU : <span className="text-red-800">*</span>
                      </label>
                      <label
                        className="cursor-pointer"
                        data-tooltip-id="sku-tooltip"
                        data-tooltip-content="Stock Keeping Unit."
                      >
                        <img
                          className="h-5"
                          src="https://cdn.iconscout.com/icon/free/png-256/free-information-notice-info-ui-tooltip-guide-30515.png"
                          alt="..."
                        />
                      </label>
                    </div>
                    <Tooltip id="sku-tooltip" place="top" effect="solid" />

                    {/* <label htmlFor="">helo</label> */}
                    <input
                      className="w-full p-2 border rounded-md"
                      type="text"
                      value={sku}
                      onChange={(e) => setSku(e.target.value)}
                      required
                    />
                  </div>
                  <div className="w-[50%] mt-4">
                    <div className="flex justify-between ">
                      <label className="block text-gray-700 text-lg font-bold ">
                        MPN : <span className="text-red-800">*</span>
                      </label>
                      <label
                        className="cursor-pointer"
                        data-tooltip-id="mpn-tooltip"
                        data-tooltip-content="Manufacturer Part Number"
                      >
                        <img
                          className="h-5"
                          src="https://cdn.iconscout.com/icon/free/png-256/free-information-notice-info-ui-tooltip-guide-30515.png"
                          alt=""
                        />
                      </label>
                    </div>
                    <Tooltip id="mpn-tooltip" place="top" effect="solid" />
                    <input
                      className="w-full p-2 border rounded-md"
                      type="text"
                      value={mpn}
                      onChange={(e) => setMpn(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <label className="block text-gray-700 text-lg font-bold mt-4 mb-2">
                  Brand Name : <span className="text-red-800">*</span>
                </label>
                <input
                  className="w-full p-2 border rounded-md"
                  type="text"
                  value={brandId}
                  onChange={(e) => setBrandId(e.target.value)}
                  required
                />

                {/* Save Button */}

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="mt-6 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-700"
                    onClick={handleAddProductSubmit}
                  >
                    Add Product Items
                  </button>
                </div>
              </form>
            </div>
            <hr />
          </div>
        </>
      )}

      {/* file upload */}
      <div className="md:px-20 px-5 py-3">
        {showupload && (
          <div>
            <div className="w-full mt-4">
              <div className="block text-gray-700 text-lg font-bold mb-2">
                Product Image : <span className="text-red-800">*</span>
              </div>
              <label
                htmlFor="image-file" // Updated id here
                className="w-full h-64  border-gray-300  rounded-lg cursor-pointer b  "
              >
                <div className="flex flex-col border-2 border-dashed items-center justify-center pt-5 pb-6">
                  {/* ... (SVG icon) */}
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    {/* Your SVG path here */}
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    Supported formats: Images (PNG, JPG, GIF), Videos (MP4)
                  </p>
                </div>
                <input
                  id="image-file" // Updated id here
                  type="file"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, "image")}
                  multiple
                  accept="image/*"
                />
              </label>
            </div>

            <div className="flex flex-wrap py-3">
              {imageFiles.map((file, index) => (
                <div key={index} className="relative mr-2 mb-2">
                  <img
                    className="w-32 h-32 object-cover rounded-md"
                    src={URL.createObjectURL(file)}
                    alt={`Image ${index + 1}`}
                  />
                  <button
                    className="absolute top-0 right-0  text-white p-1 rounded-full"
                    onClick={() => removeFile(index, "image")}
                  >
                    <img className="h-5" src={closed} alt="" />
                  </button>
                </div>
              ))}
            </div>
            {/* video */}
            <div className="w-full mt-4">
              <div className="block text-gray-700 text-lg font-bold mb-2">
                Product Video : <span className="text-red-800">*</span>
              </div>
              <label
                htmlFor="video-file" // Updated id here
                className="w-full h-64  border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  "
              >
                <div className="flex border-2 border-dashed flex-col items-center justify-center pt-5 pb-6">
                  {/* ... (SVG icon) */}
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    {/* Your SVG path here */}
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    Supported formats: Images (PNG, JPG, GIF), Videos (MP4)
                  </p>
                </div>
                <input
                  id="video-file" // Updated id here
                  type="file"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, "video")}
                  multiple
                  accept="video/*"
                />
              </label>
            </div>

            <div className="flex flex-wrap my-3">
              {videoFiles.map((file, index) => (
                <div key={index} className="relative mr-2 mb-2">
                  <video className="w-32 h-32 object-cover rounded-md" controls>
                    <source src={URL.createObjectURL(file)} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button
                    className="absolute top-0 right-0  text-white p-1 rounded-full"
                    onClick={() => removeFile(index, "video")}
                  >
                    <img className="h-5" src={closed} alt="" />
                  </button>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-700"
                onClick={() => {
                  handlefileUpload();
                }}
                disabled={loader}
                style={{
                  display:
                    imageFiles.length > 0 || videoFiles.length > 0
                      ? "block"
                      : "none",
                }}
              >
                {loader ? "Uploading..." : "Upload Assets"}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddProductitems;
