import React from "react";
import banner1 from "../../Assets/Images/WomenPage/banner1.png";
import banner3 from "../../Assets/Images/WomenPage/banner3.png";
import banner4 from "../../Assets/Images/WomenPage/banner4.png";
import offer from "../../Assets/Images/WomenPage/offer-banner.png";
import sareebg from "../../Assets/Images/WomenPage/sareebg.png";
import saree from "../../Assets/Images/WomenPage/saree.png";
import kurta from "../../Assets/Images/WomenPage/kurta.png";
import lehenga from "../../Assets/Images/WomenPage/lehenga.png";
import salwar from "../../Assets/Images/WomenPage/salwar.png";
import indo from "../../Assets/Images/WomenPage/indo.png";
import { useNavigate } from "react-router-dom";
import FooterBtn from "../../Components/FooterBtn";
import Carousel from "../../Components/atoms/Carousel";
import ethnicbg from "../../Assets/Images/WomenPage/ethnic bg.png";
import westerncbg from "../../Assets/Images/WomenPage/westernbg.png";
import EthnicCard from "../../Components/atoms/EthnicCard";
import WesternCard from "../../Components/atoms/WesternCard";
import WomenBrands from "../../Components/atoms/WomenBrands";
import WeekOfProduct from "../../Components/WeekOfProduct";
function WomenPage() {
  // const navigate = useNavigate();
  // const handleProduct = () => {
  //   navigate("/products");
  // };

  return (
    <>
      {/* <FooterBtn/> */}
      <div>
        {/* Carosel */}
        <div>
          <Carousel img1={banner1} img2={banner4} />
        </div>
        {/* <div className="  w-[100%] md:my-8 my-3 ">
          <img src={banner3} className="w-full md:h-[20vh] h-[6vh] " alt="" />
        </div> */}
        {/* ETHNIC COLLECTION */}
        <div className="py-24">
          <div className=" w-full md:px-1 px-5 rounded-t-lg  ">
            <div className="text-center text-xl lg:text-3xl tracking-widest font-extrabold pb-11">
              ETHNIC COLLECTION
            </div>
          </div>
          <EthnicCard />
        </div>


        <div className="lg:px-24 px-3">
          <WeekOfProduct/>
        </div>
        <div className="py-24">
          {/* <div className="h-full w-full  lg:py-10 px-3">
            <div>
              <img src={offer} className="h-full w-full object-fill" alt="" />
            </div>
          </div> */}
          {/* WESTERN COLLECTION */}
          <div className=" w-full mt-5 md:mt-0  md:px-3 px-5 rounded-t-lg ">
            <div className="text-center text-xl lg:text-3xl tracking-widest font-extrabold pb-11">
              WESTERN COLLECTION
              </div>
          </div>
          <WesternCard />
        </div>
        <div>
          <WomenBrands/>
        </div>

       
      </div>
    </>
  );
}

export default WomenPage;
