import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../Url";
import { ProductContext } from "../../context/ProductContext";
import { FaTrash } from "react-icons/fa"; // Importing trash icon from react-icons
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/atoms/ProductLoader";
import ProductLoader from "../../Components/atoms/ProductLoader";
import Breadcrumb from "../../Components/Breadcrumb";

const Wishlist = () => {
  const { wishlistproducts, setWishlistProducts, getWishList, whislistLoader } =
    useContext(ProductContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const {wishlistproducts, getWishList} = useContext(ProductContext);
console.log("wishlistLoader===>>",whislistLoader)
  useEffect(() => {
    getWishList();
  }, []);
  console.log("wishlistproducts===>>", wishlistproducts);
  const handleDelete = async (id) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this product from wishlist?"
    );
    if (confirmation) {
      try {
        const res = await axios.put(
          `${API_URL}/user-credential/remove-wishlist/${id}`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        console.log("Server response:", res?.data);
        if (res.status === 200) {
          toast.success("Product removed from wishlist successfully");
          // Remove the deleted product from the state
          setWishlistProducts(
            wishlistproducts.filter((product) => product._id !== id)
          );
        }
      } catch (error) {
        console.log("Error in deleting product from wishlist", error);
        toast.error("Error in deleting product from wishlist");
      }
    }
  };
  const handleCLick = (id) => {
    navigate(`/products/product/${id}`);
  };
  return (
    <>
       <Breadcrumb
        breadcrumbs={[
          { title: "Home", path: "/" },
          { title: "wishlist", path: "/wishlist" },
          // { title: "Products", path: `/products/${categoryId}` },
          // { title: "Productdetail", path: `/products/product/${id}` },
        ]}
      />
      {whislistLoader ? (
        <>
          <ProductLoader />
        </>
      ) : (
        <>
          <header className="text-center py-5  text-black">
            <h1 className="text-3xl">My Wishlist</h1>
          </header>
          <div className="flex justify-center h-[90vh] overflow-y-auto  ">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5 ">
              {wishlistproducts?.length > 0 ? (
                wishlistproducts?.map((e) => {
                  const imageUrl =
                    e.productImage?.image?.length > 0
                      ? e.productImage.image[0]
                      : "https://www.pdquipment.com/wp-content/uploads/dummy-product-image.jpg";
                  return (
                    <div className=" md:w-[18vw]" key={e._id}>
                      <div className=" relative ">
                        <img
                          className="object h-[30vh] md:h-[50vh] w-[100%]"
                          src={imageUrl}
                          alt="prod1"
                          onClick={() => handleCLick(e._id)}
                        />
                      </div>
                      <div className="flex w-full gap-2 px-2 py-2 justify-between font-semibold">
                        {e.name}
                        <FaTrash
                          className="ml-2 cursor-pointer"
                          onClick={() => handleDelete(e._id)}
                        />{" "}
                        {/* Delete icon */}
                      </div>
                      <div className="flex">
                        <div className="font-semibold text-lg px-2">
                          {" "}
                          ₹{" "}
                          {e.details && e.details.length > 0
                            ? e.details[0].price
                            : 0}
                        </div>
                        <div className=" text-lg line-through text-gray-500">
                          {" "}
                          ₹1500
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center text-2xl">No product found</div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Wishlist;
