import React from 'react'
import car from "../Assets/Images/ProductCard/Vector.png";
import cube from "../Assets/Images/ProductCard/cube.png";
import secure from '../Assets/Images/HomePage/secure.png'
function FooterHeader() {
  return (
    <>
   <div className=" md:mt-[4rem] bg-gray-300   border-[#D7CACA] ">
        <div className="flex justify-around md:py-3 py-2 ">
          <div className=" px-3">
            <img className="mx-auto md:h-10 md:w-9 h-10 w-9" src={secure} alt="shipping-car" />
            <div className='py-2 md:text-lg text-xs md:w-full w-20'>Secure Payment</div>
          </div>
          <div className=" px-3">
            <img className="mx-auto md:h-10 md:w-12 h-10 w-12" src={car} alt="shipping-car" />
            <div className='py-2 md:text-lg text-xs md:w-full w-20 '>Enjoy Free Shipping</div>
          </div>
          <div className=" px-3">
            <img className="mx-auto md:h-10 md:w-10 h-10 w-10" src={cube} alt="shipping-car" />
            <div className='py-2 md:text-lg text-xs md:w-full w-20 '>48 hours of easy returns</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterHeader