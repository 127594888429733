import React from "react";
import men from "../Assets/Images/HomePage/men 3.png";
import girls from "../Assets/Images/HomePage/girls.png";

function About() {
  return (
    <>
    <div className="bg-[#BDCBD3] w-full h-full p-3 md:p-32 ">
      <div className="p-4  2xl:mx-auto">
        <div className="lg:flex items-center lg:gap-10">
          <img
            className=" object-fill md:h-[600px] md:w-[550px]"
            src={men}
            alt="..."
          />
          <div className="md:mt-0 mt-4 ">
            <div className="text-[#3E5B87] font-semibold">ABOUT US</div>
            <div className="md:text-4xl text-3xl font-semibold py-6">
              A Destination for Elite Ethnic Wear
            </div>
            <div className="text-justify text-lg">
              At Ethnic, we bring you an exclusive range of ethnic wear for both
              men and women. From classic sarees and elegant lehengas to
              sophisticated kurta sets and traditional Nehru jackets, we offer a
              wide variety of ethnic wear to suit your style and preferences.
            </div>
            <div className="text-[#3E5B87] mt-2 lg:mt-5 cursor-pointer border-b-2 inline-block border-[#3E5B87]">
              READ MORE
            </div>
          </div>
        </div>
        <div className="flex justify-center pt-32 2xl:pt-40">
          <div>
            <div className="text-center text-3xl md:text-4xl py-3 font-semibold">
              Why Choose Ethnic?
            </div>
            <div className="md:text-center text-justify text-lg md:px-10 py-2">
              At Ethnic, we offer a range of benefits that make us the go-to
              destination for ethnic wear enthusiasts. Our collection is
              stylish, authentic, and of high quality. We also offer
              customization options, fast shipping, and excellent customer
              service, ensuring a seamless shopping experience.
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Middle Section */}
    <div className="md:flex md:justify-evenly items-center md:px-12 py-20 md:p-8 bg-[#D6E1E8]">
        <div>
          <div className="md:h-[32vh] md:w-[15vw] rounded-full mx-12">
            <img className="h-full w-full object-contain" src={girls} alt="" />
          </div>
        </div>
        <div className="md:w-[60vw] px-4 md:px-12 text-lg md:mt-0 mt-10">
          At Ethnic, we are conscious of our environmental and social
          responsibility. We use eco-friendly materials and ensure that our
          manufacturing processes are ethical and sustainable. Our commitment to
          sustainability extends to every aspect of our business, ensuring that
          you can enjoy our collection without any guilt.
        </div>
      </div>
    </>
  );
}

export default About;
