import React, { useContext, useEffect, useRef, useState } from "react";
import CartHeader from "../Components/CartHeader";
import girl from "../Assets/Images/HomePage/anjali.png";
import { ProductContext } from "../context/ProductContext";
import FooterBtn from "../Components/FooterBtn";
import FooterHeader from "../Components/FooterHeader";
import Address from "./AddressPage";
import axios from "axios";
import { API_URL } from "../Url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CheckoutCard from "../Components/atoms/CheckoutCard";

function Cart({ setActiveView }) {
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const couponInputRef = useRef();
  const [isCouponValid, setIsCouponValid] = useState(false);

  // console.log(first)
  // const[cartProductSizeId,setCartProductSizeId]=useState('')
  const navigate = useNavigate();
  const { cartItems, getCartItems, cartItemsLength, totalAmount } =
    useContext(ProductContext);

  const handleDeleteItem = async (sizeId, productId) => {
    try {
      const res = await axios.put(
        `${API_URL}/cart/remove-product/${sizeId}/${productId}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("Server response:", res?.data);
      if (res?.data?.status === 200) {
        // console.log(res?.data?.message);
        toast.success(res?.data?.message);
        getCartItems();
      }
      if (res?.data?.status !== 200) {
        console.log(res?.data?.message);

        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log("Error in deleting product from cart", error);
      throw error; // Rethrow the error to propagate it further if needed
    }
  };
  const handleAddQty = async (sizeId, productId, qty) => {
    const data = {
      qty: qty + 1,
    };
    try {
      const res = await axios.put(
        `${API_URL}/cart/update-cart-product/${sizeId}/${productId}`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("Server response====:", res?.data?.message);
      if (res?.data?.status === 200) {
        console.log(res?.data?.message);
        // toast.error(res?.data?.message);
        getCartItems();
      } else if (res?.data?.status === 400) {
        toast.error(res?.data?.message);
        console.log(res?.data?.message);
      }
    } catch (error) {
      console.log("Error in adding qty product from cart", error);
      throw error; // Rethrow the error to propagate it further if needed
    }
  };
  const handleDeleteQty = async (sizeId, productId, qty) => {
    if (qty <= 1) {
      toast.error("Cannot decrease quantity below 1");
      return;
    }

    const data = {
      qty: qty - 1,
    };
    try {
      const res = await axios.put(
        `${API_URL}/cart/update-cart-product/${sizeId}/${productId}`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("Server response:", res?.data);
      if (res?.data?.status === 200) {
        // toast.success(res?.data?.message);
        getCartItems();
      }
      if (res?.data?.status !== 200) {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log("Error in Delete qty product from cart", error);
      throw error; // Rethrow the error to propagate it further if needed
    }
  };
  const handleCoupon = (event) => {
    event.preventDefault();
    const couponCode = couponInputRef.current.value.trim();

    if (couponCode === "") {
      // Don't show any message if the input field is empty
      setIsCouponApplied(false);
      setIsCouponValid(false);
      return;
    }

    // Replace this with your actual logic to verify the coupon
    const isValid = verifyCoupon(couponCode);

    setIsCouponApplied(true);
    setIsCouponValid(isValid);
  };

  function verifyCoupon(couponCode) {
    // This is a placeholder array of valid coupons. In a real application, you would check the coupon code against a database or an API.
    const validCoupons = ["COUPON1", "COUPON2", "COUPON3"];

    // Check if the coupon code is in the array of valid coupons
    return validCoupons.includes(couponCode);
  }
  // useEffect(() => {

  // },[])
  return (
    <>
      {/* <FooterBtn /> */}
      {/* <CartHeader /> */}

      <div className="md:flex md:px-24 w-full bg-slate-100 px-3 ">
        <div className="md:w-[60%]    md:h-full overflow-y-auto  ">
          <div className="border-b-2 border-black text-xl font-semibold py-2">
            {cartItemsLength} item{cartItemsLength !== 1 ? "s" : ""}
          </div>
          {cartItems.map((item, index) => {
            const imageUrl =
              item.productImage?.image?.length > 0
                ? item.productImage.image[0]
                : "https://www.pdquipment.com/wp-content/uploads/dummy-product-image.jpg";
            return (
              <>
                <div
                  key={index}
                  className="flex md:h-40 w-full  my-3  justify-between"
                >
                  <div className="flex md:gap-1 md:w-[30vw] w-[60vw]  gap-2 ">
                    <div className=" w-full h-full">
                      <img
                        onClick={() =>
                          navigate(`/products/product/${item.productId}`)
                        }
                        className="md:h-40 md:w-40 h-32 w-28 cursor-pointer  object-cover"
                        src={imageUrl}
                        alt="..."
                      />
                    </div>
                    <div className="md:py-3 md:w-[50vw] w-[65vw] ">
                      <div className="font-semibold md:text-lg text-sm">
                        {item.name}
                      </div>
                      <div className="pt-2">Size: {item.chosenSize}</div>
                      <div>Quantity: {item.qty}</div>
                    </div>
                  </div>
                  <div className="md:px-12 md:py-3 py-2 px-2">
                    <div className="flex border-1 md:w-[10vw]    py-2 border-2 border-zinc-500 rounded  justify-between">
                      <div
                        className="cursor-pointer font-bold md:w-[3vw] w-[9vw] text-center"
                        onClick={() =>
                          handleDeleteQty(item.sizeId, item.productId, item.qty)
                        }
                      >
                        -
                      </div>
                      <div className="border-l border-r md:w-[4vw] w-[10vw]  flex justify-center border-black px-3 ">
                        {item.qty}
                      </div>
                      <div
                        className="cursor-pointer font-bold md:w-[3vw] w-[9vw] text-center"
                        onClick={() =>
                          handleAddQty(item.sizeId, item.productId, item.qty)
                        }
                      >
                        +
                      </div>
                    </div>
                    <div className="md:py-3 md:w-[10vw]  text-center py-2  font-semibold">
                      Rs {item.price * item.qty}
                    </div>
                    <div
                      className="cursor-pointer text-red-600 font-semibold  text-center"
                      onClick={() =>
                        handleDeleteItem(item.sizeId, item.productId)
                      }
                    >
                      Delete
                    </div>
                  </div>
                </div>
                <hr />
              </>
            );
          })}
        </div>
        <div className="md:w-[40%] w-full ">
          {/* <div className="flex gap-2 md:py-3 py-4 md:mx-12  border-b-2 border-black">
              <div>
                <input
                  className="py-2 border-1 border-black md:w-[24vw] w-[76vw] rounded px-2"
                  type="text"
                  placeholder="Enter Coupon Code"
                />
              </div>
              <div className="bg-black px-3 py-2 text-white rounded font-semibold cursor-pointer">
                {" "}
                Apply
              </div>
            </div>
            <div className=" py-3  border-b-2 border-black md:mx-12 mx-5">
              Order Summery
            </div>
            <div className="border-b-2 border-black py-3  md:mx-12 mx-5">
              <div className="py-2">
                price Detail ({cartItems.length} items)
              </div>
              <div className="flex  justify-between">
                <div>Total MRP</div>
                <div>{totalAmount}</div>
              </div>
              <div className="flex  py-2 justify-between">
                <div>Delivery Changes</div>
                <div>free</div>
              </div>
            </div>
            <div className="flex md:px-12 px-5 py-2 justify-between">
              <div>Amount Payable</div>
              <div>{totalAmount}</div>
            </div>
            <div
              className="text-center py-3 rounded-3xl my-4 font-medium text-white hover:bg-[#995353] bg-[#A35B6C] mx-12 cursor-pointer"
              onClick={() => setActiveView("address")}
              // onClick={() => handleCheckout(totalAmount)}
            >
              Proceed To Checkout
            </div> */}
          <form onSubmit={handleCoupon}>
            <div className="flex gap-2 md:py-3 py-4 md:mx-12">
              <div>
                <input
                  ref={couponInputRef}
                  className="py-2 border-1 border-black md:w-[24vw] w-[76vw] rounded px-2"
                  type="text"
                  placeholder="Enter Coupon Code"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-black px-3 py-2 text-white rounded font-semibold cursor-pointer"
              >
                Apply
              </button>
            </div>
          </form>
          {isCouponApplied && (
            <div
              className={`text-right px-12 text-sm ${
                isCouponValid ? "text-green-600" : "text-red-600"
              }`}
            >
              {isCouponValid ? "Coupon Applied!" : "Invalid Coupon!"}
            </div>
          )}
          <CheckoutCard />
          <div
            className="text-center py-3 rounded-3xl my-4 font-medium text-white hover:bg-[#995353] bg-[#A35B6C] mx-12 cursor-pointer"
            onClick={() => setActiveView("address")}
            // onClick={() => handleCheckout(totalAmount)}
          >
            Proceed To Checkout
          </div>
        </div>
      </div>

      {/* <Address/> */}
    </>
  );
}

export default Cart;
