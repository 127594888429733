import React from "react";
import twitter from "../Assets/Images/Footer/twitter.png";
import youtube from "../Assets/Images/Footer/youtube.png";
import insta from "../Assets/Images/Footer/insta.png";
function Footer() {
  return (
    <div className="bg-zinc-800 w-full text-white  lg:px-24 px-5 py-7 lg:pt-20">
      <div className="lg:flex lg:justify-between lg:px-5   ">
        <div className="grid lg:grid-cols-3 grid-cols-2 gap-10 lg:gap-24">
          <div>
            <ul className="space-y-2 text-white/30">
              <li className="font-semibold text-white/80">TOP CATEGORIES</li>
              <li >Salwar Suit Sets</li>
              <li >Sarees</li>
              <li >Women Kurta Sets</li>
              <li >Lehengas</li>
              <li >Dresses</li>
              <li >Juittis</li>
              <li >Jewellery</li>
              <li >Footwear</li>
            </ul>
          </div>
          <div>
            <ul className="space-y-2 text-white/30">
              <li className="font-semibold text-white/70">DISCOVER</li>
              <li>About Us</li>
              <li>Celebrities</li>
              <li>Careers</li>
              <li>Customer Reviews</li>
              <li>Media</li>
              <li>Business Query</li>
              <li>Blog</li>
            </ul>
          </div>
          <div>
            <ul className="space-y-2 text-white/30">
              <li className="font-semibold text-white/70">SUPPORT</li>
              <li>Track Order</li>
              <li>Gift Card</li>
              <li>Exchange Request</li>
              <li>Terms Of Services</li>
              <li>Sitemap</li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="text-3xl font-semibold cursor-pointer lg:py-0 py-5">LOGO</div>
          <div className="border-b-2 lg:pt-4 sm:pt-2  " >
            <input
              type="text"
              placeholder="Enter your email"
              className="flex-1 lg:w-[15vw] py-1 border-none focus:outline-none bg-transparent"
            />
          </div>
          <div className="flex  pt-5 lg:pt-24 gap-4 lg:gap-16 justify-around  ">
          <img className="cursor-pointer" src={twitter} alt="..." />
          <img className="cursor-pointer" src={youtube} alt="..." />
          <img className="cursor-pointer" src={insta} alt="..." />
        </div>
        </div>
      </div>
      {/* <div className="lg:flex lg:justify-end justify-center flex lg:px-10  ">
        <div className="flex  pt-5 lg:pt-0 gap-4 lg:gap-16 justify-around  ">
          <img className="cursor-pointer" src={twitter} alt="..." />
          <img className="cursor-pointer" src={youtube} alt="..." />
          <img className="cursor-pointer" src={insta} alt="..." />
        </div>
      </div> */}
      <div className="flex  pt-10 lg:pt-5 justify-between  items-center">
        <div className="lg:w-[38%] border-b-2"></div>
        <div className=" text-[#717171] px-5 ">@LOGO . ALL RIGHTS RESERVED</div>
        <div className="lg:w-[38%] border-b-2"></div>
      </div>
    </div>
  );
}

export default Footer;
