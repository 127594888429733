import React from 'react'
import nike from '../../Assets/Images/TopBrand/nike.png'
import hnm from '../../Assets/Images/TopBrand/hnm.png'
import uspolo from '../../Assets/Images/TopBrand/uspolo.png'
import levis from '../../Assets/Images/TopBrand/levis.png'
import puma from '../../Assets/Images/TopBrand/puma.png'
function TopBrandDeal() {
  return (
    <div className='bg-black p-4 mx-2 lg:mx-12 rounded-xl lg:py-10'>
        <div className='text-center font-extrabold text-white text-2xl lg:text-3xl tracking-widest'>Top brands Deal</div>
        <div className='text-white text-center text-base lg:text-lg'>Up To <span className='text-yellow-300'>60%</span> off on brands </div>
        <div className='grid grid-cols-5 gap-3 lg:gap-10 lg:px-10  py-10'>
             <img src={nike} className='w-full' alt="" />
             <img src={hnm} className='w-full'  alt="" />
             <img src={levis} className='w-full' alt="" />
             <img src={uspolo} className='w-full' alt="" />
             <img src={puma} className='w-full' alt="" />
             {/* <img src={hnm} alt="" /> */}
        </div>
    </div>
  )
}

export default TopBrandDeal