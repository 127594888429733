import React, { useContext, useEffect, useState } from "react";
import FooterHeader from "../Components/FooterHeader";
import g1 from "../Assets/Images/HomePage/anjali.png";
import AddAddressModal from "../Components/AddAddressModal"; // Import the modal component
import axios from "axios";
import { API_URL } from "../Url";
import { ProductContext } from "../context/ProductContext";
import { toast } from "react-toastify";
import AddressCard from "../Components/atoms/AddressCard";
import CheckoutCard from "../Components/atoms/CheckoutCard";

function AddressPage({address,setAddress,selectedAdd,setSelectedAdd, setActiveView}) {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const{cartItems,userAddress,totalAmount}=useContext(ProductContext)
  const [render,setRender] = useState(false)
  const token = localStorage.getItem("token")
  const [product, setProduct] = useState()
  const [defaultAdd,setDefaultAdd] = useState("")
  const defaultData = {
    addressType: "Home",
    district: "",
    flatNumber: "",
    landmark: "",
    locality: "",
    mobile: "",
    name: "",
    pincode:"",
    state: "",
    userId: "",
    _id: ""
  }

  const resetProduct = ()=>{
    setProduct(defaultData)
  }

  useEffect(()=>{
    axios.get(`${API_URL}/user-credential/address/`,{
      headers: {
        Authorization: token,
      }
    }).then((res)=>{
      if(res.data.status===200){
        setAddress(res.data.addressDetails)
      }
    }).catch((err)=>{console.log(err)})

    
    axios.get(`${API_URL}/user-credential/default-address`,{
      headers: {
        Authorization: token,
      }
    }).then((res)=>{
      if(res.data.status===200){
        setDefaultAdd(res.data.addressDetails._id)
        setSelectedAdd(res.data.addressDetails._id)
      }else{
        console.log("res from addr ",res)
      }
    }).catch((err)=>{console.log(err)})

  },[render,isModalOpen])
  
  const toggleModal = (data) => {
    setProduct(data)
    setIsModalOpen(!isModalOpen);
  };

  const deleteAddress = (id)=>{
    axios.delete(`${API_URL}/user-credential/delete-address/${id}`,{
      headers: {
        Authorization: token,
      }
    }).then((res)=>{
      if(res.data.status===200){
        toast.success(res.data.message)
        setRender(!render)
      }else{
        toast.error(res.data.message)
      }
    }).catch((err)=>{
      toast.error(err)
    })
  }


  

  
  return (
    <>
      {/* <FooterHeader /> */}
      <div className="md:flex md:px-24 px-5 w-full justify-between bg-slate-100">
        <div className="md:w-[60%] w-full">
          <h1 className="text-3xl md:pb-3 md:mb-3 border-b-2 border-slate-400">Select Address</h1>
        <div id='add' className='md:mb-6 mb-3 mt-4 border-3 border-[#D18B91] md:py-0 py-1 p-1  md:bg-none text-[#D18B91] cursor-pointer text-center w-48 ms-auto rounded-lg hover:bg-pink-50' onClick={()=>toggleModal(defaultData)}>ADD NEW ADDRESS</div>
        <div className='grid md:grid-cols-2 gap-x-10 gap-y-4'>
        {
          address?.map((data,id)=>{
            return(
              <div key={id} >
              <AddressCard data={data} toggleModal={toggleModal} deleteAddress={deleteAddress} defaultAdd={defaultAdd} setDefaultAdd={setDefaultAdd} render={render} setRender={setRender} selectedAdd={selectedAdd} setSelectedAdd={setSelectedAdd}/>
              </div>
            )
          })
        }
        </div>
        </div>
        <div className="h-full md:w-[30%] w-full  bg-white border-2 rounded-lg md:my-12 my-5">

        <CheckoutCard />
            <div
              className="text-center py-3 rounded-md my-4 font-medium text-white hover:bg-[#995353] bg-[#A35B6C] mx-12 cursor-pointer"
              onClick={() => setActiveView("payment")}
              // onClick={() => handleCheckout(totalAmount)}
            >
              Proceed To Checkout
            </div>
          
        </div>
      </div>
      {isModalOpen && (
        <AddAddressModal product={product} setProduct={setProduct} resetProduct={resetProduct} onClose={toggleModal} /> // Render the modal if isModalOpen is true
      )}
    </>
  );
}

export default AddressPage;
