import React from 'react'
// import { GrDeliver } from "react-icons/gr";
import { HiMiniReceiptRefund } from "react-icons/hi2";

function RefundedCard() {
  return (
    <div className="bg-white p-5">

    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        <div>
          <HiMiniReceiptRefund size={30} />
        </div>
        <div className="text-xl font-semibold">Refunded</div>
      </div>
      {/* <div className="px-3 py-2 bg-red-400 text-white rounded-md hover:bg-red-500 cursor-pointer">
        Return / Exchange{" "}
      </div> */}
    </div>
    {/* <div className="text-zinc-500">Deliverd on Tuesday, 5 March</div> */}
    <div className="text-zinc-500">Order ID: 1234567890</div>
    {/* //order card */}
    <div className="py-5">
      <div className="flex  gap-2 my-3">
        <div>
          <img
            src="https://cdn.shopify.com/s/files/1/0278/7802/1571/products/1_1_300x300.jpg?v=1633120609"
            alt="product"
            className="w-[7vw] h-[15vh] bg-red-50 object-cover"
          />
        </div>
        <div>
          <div className="text-xl font-semibold">ABC COMPANY</div>
          <div>Product Name</div>
          <div className="text-gray-400">Delivered on 20th September</div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RefundedCard