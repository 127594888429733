import React from "react";

function SummeryPaymentCard() {
  return (
    <div className="bg-white">
      <div className="bg-[#DBB3B3] p-5">
        <div>Order # Ex0022777316</div>
        <div className="text-sm text-zinc-600">
          Order placed on 31 August 2024
        </div>
      </div>
      <div className="px-5 py-3">
        <div className="border-b-2 border-[#B3B3B3] py-1 text-xl">
          Order Payment Details
        </div>
        <div className="flex justify-between py-1">
          <div>Order Amount</div>
          <div>₹ 2000</div>
        </div>
        <div className="flex justify-between py-1">
          <div>Order Savings</div>
          <div>₹ 2000</div>
        </div>
        <div className="flex justify-between py-1">
          <div>Delivery Fee</div>
          <div>₹ 2000</div>
        </div>
        <div className="flex justify-between font-semibold py-1">
          <div className="">Order Total</div>
          <div>₹ 2000</div>
        </div>
        <div className="border-b-2 my-4 border-[#B3B3B3]"></div>
        <div className="flex justify-between items-center">
          <div>
            <div>Payment Method</div>
            <div>Cash on Delivery</div>
          </div>
          <div>₹ 2000</div>
        </div>
      </div>
    </div>
  );
}

export default SummeryPaymentCard;
