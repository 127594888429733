import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../Url";
import { AccountNavigation } from "../Components/molecules/AccountNavigation";
import OrderDetails from "../Components/OrderDetails";
import ProfileDetails from "../Components/ProfileDetails";
import AddressDetails from "../Components/AddressDetails";
import { ProductContext } from "../context/ProductContext";
import Breadcrumb from "../Components/Breadcrumb";
import Loader from "../Components/Loader";

const Account = () => {
  const {setWishlistProducts,setCartItems,setTotalAmount,setCartItemsLength} = useContext(ProductContext)
  const [active, setActive] = useState(false);
  const [data, setData] = useState({});
  const [section, setSection] = useState("order");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");



  const handleUserDetails = async() => {
    try {
      setActive(true);
      const res= await axios.get(`${API_URL}/user-credential/user-details`, {
        headers: {
          Authorization: token,
        },
      })
      if (res?.data?.status === 200) {
        setData(res.data.response);
        setActive(false);
        // return;
      }
      
    } catch (error) {
      console.log("Error in UserDetail", error);
      throw error; // Rethrow the error to propagate it further if needed
      
    }
  }

  useEffect(() => {
    handleUserDetails()
  }
  , []);

  // useEffect(() => {
  //   setActive(false);
  //   axios
  //     .get(`${API_URL}/user-credential/user-details`, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setData(res.data.response);
  //         setActive(true);
  //         // return;
  //       }
  //       // navigate("/login");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [active]);



  const handleLogout = () => {
    setWishlistProducts([])
    setCartItems([])
    setTotalAmount(0)
    setCartItemsLength(0)
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <>
    {active?(
      <>
       <div className="h-[50vh] flex justify-center items-center">
       <div class="animate-spin inline-block size-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
        <span class="sr-only">Loading...</span>
      </div>
       </div>
      </>
    ):(
      <>

        <Breadcrumb
        breadcrumbs={[
          { title: "Home", path: "/" },
          { title: "account", path: "/account" },
          // { title: "Products", path: `/products/${categoryId}` },
          // { title: "Productdetail", path: `/products/product/${id}` },
        ]}
      />
       <div className="bg-slate-100 py-4 overflow-x-hidden lg:px-20">
        <button
          className="logout bg-red-300 p-2 cursor-pointer block ms-auto me-3 w-20 text-center rounded-lg"
          onClick={handleLogout}
        >
          Logout
        </button>
        <div className="mx-auto lg:w-[80vw] w-full">
          <h1 className="text-xl font-medium ms-5 mb-2">My Account</h1>
          <div className="lg:flex gap-5">
            <AccountNavigation section={section} setSection={setSection} />
            {section === "order" && <OrderDetails />}
            {section === "profile" && <ProfileDetails data={data} />}
            {section === "address" && <AddressDetails />}
          </div>
        </div>
      </div>
      </>
    )}
    
  </>
  );
};

export default Account;

