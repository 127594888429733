import React, { useContext } from 'react'
import { ProductContext } from '../../context/ProductContext'

function CheckoutCard() {
    const{totalAmount,cartItems}=useContext(ProductContext)
  return (
      <div className=''>
           {/* <div className="flex gap-2 md:py-3 py-4 md:mx-12  border-b-2 border-black">
              <div>
                <input
                  className="py-2 border-1 border-black md:w-[24vw] w-[76vw] rounded px-2"
                  type="text"
                  placeholder="Enter Coupon Code"
                />
              </div>
              <div className="bg-black px-3 py-2 text-white rounded font-semibold cursor-pointer">
                {" "}
                Apply
              </div>
            </div> */}
            <div className=" py-3  border-b-2 border-black md:mx-12 mx-5">
              Order Summery
            </div>
            <div className="border-b-2 border-black py-3  md:mx-12 mx-5">
              <div className="py-2">
                price Detail ({cartItems.length} items)
              </div>
              <div className="flex  justify-between">
                <div>Total MRP</div>
                <div>{totalAmount}</div>
              </div>
              <div className="flex  py-2 justify-between">
                <div>Delivery Changes</div>
                <div>free</div>
              </div>
            </div>
            <div className="flex md:px-12 px-5 py-2 justify-between">
              <div>Amount Payable</div>
              <div>{totalAmount}</div>
            </div>
            {/* <div
              className="text-center py-3 rounded-3xl my-4 font-medium text-white hover:bg-[#995353] bg-[#A35B6C] mx-12 cursor-pointer"
              onClick={() => setActiveView("address")}
              // onClick={() => handleCheckout(totalAmount)}
            >
              Proceed To Checkout
            </div> */}
      </div>
  )
}

export default CheckoutCard