import React from "react";

function ProductLoader() {
  return (
    <div className="lg:flex lg:justify-center ">
      <div className=" grid lg:grid-cols-3 grid-cols-2 lg:gap-10 gap-5 py-7 px-5  lg:px-20">
        {[...Array(6)].map((_, i) => (
          <div
            key={i}
            className="animate-pulse  border rounded-lg p-4 lg:w-64 lg:h-64 h bg-gray-300"
          >
            <div className="h-32 bg-gray-400 rounded"></div>
            <div className="mt-4 h-4 bg-gray-400 rounded w-3/4"></div>
            <div className="mt-2 h-4 bg-gray-400 rounded w-1/2"></div>
            <div className="mt-2 h-4 bg-gray-400 rounded w-1/4"></div>
            <div className="mt-2 h-4 bg-gray-400 rounded w-1/4"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductLoader;
