import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb({ breadcrumbs }) {
  return (
    <div className="md:px-20 px-3 py-3">
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index} className="inline-flex items-center">
              {index !== 0 && (
                <svg
                  className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              )}
              <Link
                to={breadcrumb.path}
                className={`ms-1 md:text-lg font-medium ${
                  index === breadcrumbs.length - 1
                    ? "text-gray-700"
                    : "text-blue-600 hover:text-blue-600"
                } md:ms-2`}
              >
                {breadcrumb.title}
              </Link>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumb;