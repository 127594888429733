import React from "react";

function SummerAddressCard() {
  return (
    <div className="bg-white p-5">
      <div className="text-md font-semibold">Deliver to</div>
      <div className="py-3">
        <div>Subarna Keshari Sutar</div>
        <div>DLF , Patia ,Bhubaneswar</div>
        <div>KHORDA, </div>
        <div>ODISHA</div>
        <div>India,754220</div>
        <div>Phone- 7077786090</div>
      </div>
    </div>
  );
}

export default SummerAddressCard;
