import React from "react";
import DeliveryCard from "../Components/molecules/DeliveryCard";
import RefundedCard from "../Components/molecules/RefundedCard";
import CancelCard from "../Components/molecules/CancelCard";
import SummeryPaymentCard from "../Components/molecules/SummeryPaymentCard";
import SummerAddressCard from "../Components/molecules/SummerAddressCard";

function OrderSummery() {
  return (
    <>
      <div className="bg-zinc-300 flex px-20 py-16 gap-10">
        <div className="w-[60%]">
          <div>
            <DeliveryCard />
          </div>
          <div className="py-10">
            <RefundedCard />
          </div>
          <div className="">
            <CancelCard />
          </div>
        </div>
        <div className="w-[40%]  ">
          <div>
            <SummeryPaymentCard />
          </div>
          <div className="my-5">
            <SummerAddressCard/>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSummery;
