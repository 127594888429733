import React from "react";
import right from "../../Assets/Images/ProductDetails/right-arrow.png";
import img from "../../Assets/Images/WomenPage/salwar.png";
import { useNavigate } from "react-router-dom";

const MyOrderCard = ({ data }) => {

  const navigate=useNavigate()
  console.log("==order data==", data);
  return (
    <div>
      {data.map((val, id) => {
        // console.log(val.name);
        return (
          <div
            key={val.productIds}
            className="h-[20vh] w-full border-2 flex gap-8"
          >
            <img
              src={val.productImage.image[0]}
              alt="product"
              className="p-2 lg:w-[13%] w-[35%] object-fill cursor-pointer"
              onClick={() => navigate(`/products/product/${val.productId}`)}
            />
            <div className="flex flex-col justify-center">
              <div className="font-bold">{"ABC COMPANY"}</div>
              <div>{val.name}</div>
              <div className="text-gray-400">Delivered on 20th September</div>
            </div>
            <div className="h-full bg-slate-50 ms-auto text-4xl text-gray-500 px-2 grid items-center cursor-pointer"onClick={()=>navigate('/order-summery')}>
              {">"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MyOrderCard;
