import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../Url";

function AddAddressModal({ product, setProduct, resetProduct, onClose }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value
    });
  };

  const handleReset = () => {
    setProduct({
      ...product,
      name: "",
      mobile: "",
      pincode: "",
      locality: "",
      flatNumber: "",
      landmark: "",
      district: "",
      state: "",
      addressType: "Home",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {userId,_id,...data} = product
      console.log("data",data)
      if(_id===""){
        const res = await axios.post(`${API_URL}/user-credential/add-address`, data, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
        }
      }
      else{
        const res = await axios.put(`${API_URL}/user-credential/update-address/${product._id}`, data, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
        }
      }
      resetProduct()
      onClose();

    } catch (error) {
      console.log("Error in adding address", error);
      throw error; // Rethrow the error to propagate it further if needed
      
    }
    // Here you can handle form submission, for example, send the data to your backend or perform any other action
    // Close the modal after submission
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50">
      <div
        className="bg-white p-8 md:w-[40%] w-full h-full scrollbar-hide"
        style={{ overflowY: "auto", WebkitOverflowScrolling: "touch" }}
      >
        <style>
          {`
            .scrollbar-hide::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-4">New Shipping Address</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={product?.name}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="mobile"
              className="block text-sm font-medium text-gray-700"
            >
              Mobile
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={product?.mobile}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="pincode"
              className="block text-sm font-medium text-gray-700"
            >
              Pincode
            </label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              value={product?.pincode}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="locality"
              className="block text-sm font-medium text-gray-700"
            >
              Locality
            </label>
            <input
              type="text"
              id="locality"
              name="locality"
              value={product?.locality}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="flatNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Flat Number
            </label>
            <input
              type="text"
              id="flatNumber"
              name="flatNumber"
              value={product?.flatNumber}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="landmark"
              className="block text-sm font-medium text-gray-700"
            >
              Landmark
            </label>
            <input
              type="text"
              id="landmark"
              name="landmark"
              value={product?.landmark}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="district"
              className="block text-sm font-medium text-gray-700"
            >
              District
            </label>
            <input
              type="text"
              id="district"
              name="district"
              value={product?.district}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700"
            >
              State
            </label>
            <input
              type="text"
              id="state"
              name="state"
              value={product?.state}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Address Type
            </label>
            <div className="flex">
              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  name="addressType"
                  value="Home"
                  checked={product?.addressType === "Home"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Home
              </label>
              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  name="addressType"
                  value="Work"
                  checked={product?.addressType === "Work"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Work
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="addressType"
                  value="Others"
                  checked={product?.addressType === "Others"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Others
              </label>
            </div>
          </div>
          <div className="flex w-full">
            <button
              type="button"
              onClick={handleReset}
              className="mr-4 py-2 w-[50%] bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold rounded-md"
            >
              Reset
            </button>
            <button
              type="submit"
              className="py-2 w-[50%] bg-[#b1464f] hover:bg-red-400 text-white font-semibold rounded-md"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddAddressModal;
