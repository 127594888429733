import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const useVarible = create(
	persist(
		(set) => ({
			lan: "en",
			phonenumber: "",
			email: "",
			JWT: "",
			currentSong: "0",
			wallet: {},
			health: {},
			user: {},
			detailuser: {},
			ishealth: false,

			setLang: (value) => set(() => ({ lan: value })),
			setPhone: (value) => set(() => ({ phonenumber: value })),
			setEmail: (value) => set(() => ({ email: value })),
			setToken: (value) => set(() => ({ JWT: value })),
			setUser: (value) => set(() => ({ user: value })),
			setDetailuser: (value) => set(() => ({ detailuser: value })),
			setCurrentSong: (value) => set(() => ({ currentSong: value })),
			setHealth: (value) => set(() => ({ health: value })),
			setWallet: (value) => set(() => ({ wallet: value })),
			setHealthdata: (value) => set(() => ({ ishealth: value })),
			setClear: () => {
				set(() => ({ JWT: "", phonenumber: "" }));
				localStorage.clear();
			},
		}),

		{
			name: "varible", // name of item in the storage (must be unique)
			storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
		}
	)
);
