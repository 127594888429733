import React, { useState } from "react";
import "../Css/SignupLogin.css";
import welcome from "../Assets/Images/SignupLogin/welcome.png";
import { API_URL } from "../Url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Signup() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Inavlid Credential!!");
  const handleRegister = () => {
    setIsError(false);
    if (
      !firstName ||
      !lastName ||
      !gender ||
      !dob ||
      !phoneNumber ||
      !dob ||
      !password ||
      !confirmPassword
    ) {
      setIsError(true);
      setErrorMsg("Please fill all the fields!!");
      return;
    }
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email && !regex.test(email)) {
      setIsError(true);
      setErrorMsg("Email is not valid");
      return;
    }
    const regexNum = /^[0-9]{10}$/;
    if (phoneNumber) {
      if (phoneNumber.length !== 10) {
        setIsError(true);
        setErrorMsg("Phone number should be of 10 digits");
        return;
      }
      if (!regexNum.test(phoneNumber)) {
        setIsError(true);
        setErrorMsg("Phone number is not valid");
        return;
      }
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      gender: gender,
      dob: dob,
      phoneNumber: phoneNumber,
      password: password,
      confirmPassword: confirmPassword,
    };
    console.log(data);
    axios.post(`${API_URL}/user-credential`, data).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message);
        localStorage.setItem("token", res.data.token);
        // localStorage.setItem("user", JSON.stringify(res.data.user))
        toast.success(res.data.message);
        navigate("/");
      } else {
        setIsError(true);
        setErrorMsg(res.data.message);
      }
    });
  };
  return (
    <div>
      <section className="md:h-screen">
        <div className="container h-full lg:px-20 px-5 md:py-10 py-5 mx-auto">
          <div className="g-6 flex h-full flex-wrap items-center justify-center">
            {/* <!-- Left column container with background--> */}
            <div className="hidden mb-12 md:flex items-center justify-center md:mb-0 md:w-8/12 lg:w-6/12 image-container">
              <img src={welcome} className="image p-20" alt="Phone" />
            </div>

            {/* <!-- Right column container with form --> */}
            <div className="md:w-8/12 lg:bg-[#f1f1f1] lg:w-6/12 md:rounded-r-3xl   flex items-center justify-center h-full w-full">
              <div className="w-80 md:py-0 py-5 ">
                <div>
                  <div
                    className="relative mb-3 h-full"
                    data-te-input-wrapper-init
                  >
                    {/* <label
                      htmlFor="firstName"
                      className="text-gray-500 text-sm"
                    >
                      First Name
                    </label> */}
                    <input
                      type="text"
                      className="peer text-sm block min-h-[auto] w-full rounded-xl border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div
                    className="relative mb-3 h-full"
                    data-te-input-wrapper-init
                  >
                    <input
                      type="text"
                      className="peer text-sm block min-h-[auto] w-full rounded-xl border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div
                    className="relative mb-3 h-full"
                    data-te-input-wrapper-init
                  >
                    <input
                      type="text"
                      className="peer text-sm block min-h-[auto] w-full rounded-xl border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="email"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="relative mb-3" data-te-input-wrapper-init>
                    <input
                      type="password"
                      className="peer text-sm block min-h-[auto] w-full rounded-xl border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="relative mb-3" data-te-input-wrapper-init>
                    <input
                      type="password"
                      className="peer text-sm block min-h-[auto] w-full rounded-xl border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="cnfpassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <div
                    className="relative mb-3 h-full"
                    data-te-input-wrapper-init
                  >
                    <input
                      type="text"
                      className="peer text-sm block min-h-[auto] w-full rounded-xl border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="phone"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div
                    className="relative mb-3 h-full"
                    data-te-input-wrapper-init
                  >
                    <label htmlFor="dob" className="text-gray-600 text-sm">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="peer text-sm block min-h-[auto] w-full rounded-xl border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="dob"
                      placeholder="Date of Birth"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </div>
                  <div
                    className="relative mb-6 h-full"
                    data-te-input-wrapper-init
                  >
                    <p className="text-gray-500 text-sm">Gender</p>
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      onClick={(e) => setGender(e.target.value)}
                    />
                    <label htmlFor="male" className="ms-2 me-4">
                      Male
                    </label>
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="male"
                      onClick={(e) => setGender(e.target.value)}
                    />
                    <label htmlFor="female" className="ms-2 me-4">
                      Female
                    </label>
                  </div>
                  {isError && <p className="text-red-500 mb-2">{errorMsg}</p>}
                  <button
                    type="submit"
                    className="inline-block w-full rounded bg-black px-7 pb-2.5 pt-3 mb-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    onClick={handleRegister}
                  >
                    REGISTER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Signup;
