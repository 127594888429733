import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import heartfilled from "../Assets/Images/ProductCard/heartfilled.svg";
import heart from "../Assets/Images/ProductCard/heart.svg";
import { ProductContext } from "../context/ProductContext";

function ProductCard({ data, gridStyle }) {
  const navigate = useNavigate();
  const [wishlistUpdated, setWishlistUpdated] = useState(false);
  const {
    wishlistproducts,
    handleDeleteFromWishlist,
    handleWhilistProduct,
    getWishList,
  } = useContext(ProductContext);

  console.log("dataaaa=====>>>>>>", data);

  const handleClickProduct = (id) => {
    navigate(`/products/product/${id}`);
  };
  const handleWhilistProductClick = async (id) => {
    if (wishlistproducts?.some((product) => product._id === id)) {
      handleDeleteFromWishlist(id);
    } else {
      handleWhilistProduct(id);
    }
    setWishlistUpdated((prevState) => !prevState); // Toggle wishlistUpdated state
  };

  useEffect(() => {
    getWishList();
    setWishlistUpdated(false); // Reset wishlistUpdated state after fetching wishlist
  }, [wishlistUpdated]);

  return (
    <>
      <div className="py-2 w-full lg:px-0 px-3">
        <div
          className={`grid w-full py-3 ${
            gridStyle
              ? "lg:grid-cols-5 grid-cols-1 lg:gap-5 lg:gap-y-5 gap-y-5"
              : "grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
          } lg:gap-10 lg:gap-y-10 gap-y-2`}
        >
          {data?.length > 0 ? (
            data?.map((e) => {
              const imageUrl =
                e.productImage?.image?.length > 0
                  ? e.productImage.image[0]
                  : "https://www.pdquipment.com/wp-content/uploads/dummy-product-image.jpg";

              return (
                <div className=" p-2" key={e._id}>
                  <div className={`relative  bg-neutral-600 md:relative ${gridStyle ? "lg:h-[35vh] h-[50vh]  " :"lg:h-[55vh] h-[30vh] "} `}>
                    <img
                      onClick={() => handleClickProduct(e._id)}
                      className="h-full w-full cursor-pointer object-fill"
                      src={imageUrl}
                      alt="prod1"
                    />
                    <img
                      onClick={() => handleWhilistProductClick(e._id)}
                      className={`imgab2 absolute md:absolute cursor-pointer ${gridStyle?"md:right-3 top-2 right-2 md:top-3":"md:right-5 top-2 right-2 md:top-5"} `}
                      src={
                        wishlistproducts?.some(
                          (product) => product._id === e._id
                        )
                          ? heartfilled
                          : heart
                      } // Use the filled heart if the product is in the wishlist
                      style={{ height: "30px" }}
                      alt="..."
                    />
                  </div>
                  <div className="flex  md:px-0 md:py-1 overflow-hidden font-semibold">
                    {e.name}
                  </div>
                  <div className="flex md:gap-2 gap-2">
                    <div className="font-semibold md:px-0">
                      Rs.{" "}
                      {e.details && e.details.length > 0
                        ? e.details[0].price
                        : 0}
                    </div>
                    <div className="line-through text-gray-500"> ₹1500</div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No product found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProductCard;
