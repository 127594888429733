import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { API_URL } from "../Url";
import { toast } from "react-toastify";
import useDebounce from "../utils/useDebounce";
import { useLocation } from "react-router-dom";
export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  // const[cartLength,setCartLength]=useState(0)
  // const[categoryName,setCategoryName]=useState('')
  const [whislistLoader, setWhislistLoader] = useState(false);
  const [serachQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState();
  const [wishlistproducts, setWishlistProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [categoryId, setCategoryId] = useState("");
  const [searchlimits, setSearchLimits] = useState("9");
  const [occasionIds, setOccasionIds] = useState([]); //1st array
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [parentCategory, setParentCategory] = useState([]);
  const [allproduct, setAllProduct] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [OccasionProducts, setOccasionProducts] = useState([]);
  const [productId, setProductId] = useState("");
  const [filter, setFilter] = useState([]); //
  const [query, setQuery] = useState("");
  const [filterArray, setFilterArray] = useState([]); //2nd arry
  const [ethnicCollection, setEthnicCollection] = useState([]);
  const [westernCollection, setWesternCollection] = useState([]);
  const [varitionId, setVaritionId] = useState([]); //concat 1st arry and 2 nd array using spred
  const [categoryname, setCategoryName] = useState("");
  const [wishlistProductId, setWishlistProductId] = useState("");
  const [cartItemsLength, setCartItemsLength] = useState(0);
  const [userAddress, setUserAddress] = useState([]);
  const token = localStorage.getItem("token");

  const handleParentCategory = async () => {
    try {
      const res = await axios.get(`${API_URL}/products/parent-category`);
      // console.log(res.data);
      setParentCategory(res?.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      throw error; // rethrow the error to propagate it further
    }
  };

  const getallProduct = async () => {
    try {
      const res = await axios.get(`${API_URL}/products`);
      // const res = await axios.get("Dummy_Products.json");
      setAllProduct(res?.data);
      // console.log(">>", res?.data);
    } catch (error) {
      toast.error("Error", error);
    }
  };

  const handleAddProductItem = async (productData) => {
    try {
      const response = await axios.post(`${API_URL}/products`, productData);
      console.log("Server response:", response?.data);
      setProductId(response?.data._id);

      // Handle the response or state updates as needed
      toast.success("ProductItem Added successfully");
    } catch (error) {
      console.error("Error adding product Item:", error);
      toast.error("Error adding product. Check console for details.");
      throw error; // Rethrow the error to propagate it further if needed
    }
  };

  const handleAddProduct = async (formData) => {
    console.log("object==>", formData);
    try {
      const res = await axios.post(
        `${API_URL}/products/add-products/${productId}`,
        formData
      );
      console.log("Server response:", res?.data);
      if (res.status === 200) {
        toast.success("Product Added successfully");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Error adding product. Check console for details.");
      throw error; // Rethrow the error to propagate it further if needed
    }
  };

  const getallFilter = async () => {
    try {
      const res = await axios.get(`${API_URL}/products/get-all-variations`);
      // console.log("....",res.data)
      setFilter(res?.data);
      // console.log("Filter======>>>>",filter);
    } catch (error) {
      toast.error("Error in getting all Filter");
    }
  };



  const getEthnicCategory = async () => {
    try {
      const res = await axios.get(`${API_URL}/products/child-category/1`); // 1 ethinic id
      setEthnicCollection(res?.data);
    } catch (error) {
      console.log("Error in fetching Ethnic Collection", error);
      toast.error("Error in Fetching Ethnic Collection");
    }
  };
  const getWesternCatehory = async () => {
    try {
      const res = await axios.get(`${API_URL}/products/child-category/2`); // 1 ethinic id
      setWesternCollection(res?.data);
    } catch (error) {
      console.log("Error in fetching Western Collection", error);
      toast.error("Error in Fetching Western Collection");
    }
  };
  const getOccasionProducts = async () => {
    const obj = {
      variationOptions: varitionId,
      // priceRange:'2000-4000',
      page: currentPage,
      limit: searchlimits,
      sortOrder: sortOrder,
    };
    // const dataToSend = {
    //   variationOptions: obj.variationOptions,
    //   page: obj.page,
    //   limit: obj.limit
    // };
    try {
      const res = await axios.post(`${API_URL}/products/search`, obj);
      setOccasionProducts(res?.data);
      setTotalPages(res?.data?.totalNumberOfPages);
    } catch (error) {
      toast.error(error);
      console.log("Error in fetching Occasion Products", error);
    }
  };
  const getWishList = async () => {
    setWhislistLoader(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const res = await axios.get(`${API_URL}/user-credential/wishlist`, {
        headers: {
          Authorization: token,
        },
      });
      if (res.data.status === 200) {
        setWishlistProducts(res.data.response);
        setWhislistLoader(false);
      }
      
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleWhilistProduct = async (id) => {
    try {
      const res = await axios.put(
        `${API_URL}/user-credential/add-wishlist/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Server response:", res?.data);
      if (res.status === 200) {
        console.log("Product Added to Whislist successfully");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error(
        "Error adding product to Whislist. Check console for details."
      );
      throw error; // Rethrow the error to propagate it further if needed
    }
  };
  const handleDeleteFromWishlist = async (id) => {
    try {
      const res = await axios.put(
        `${API_URL}/user-credential/remove-wishlist/${id}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (res.status === 200) {
        console.log("Product removed from wishlist successfully");
        setWishlistProducts(
          wishlistproducts.filter((product) => product._id !== id)
        );
      }
    } catch (error) {
      console.log("Error in deleting product from wishlist", error);
      toast.error("Error in deleting product from wishlist");
    }
  };

  const getCartItems = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const res = await axios.get(`${API_URL}/cart`, {
        headers: {
          Authorization: token,
        },
      });
      if (res.data.status === 200) {
        setCartItems(res?.data?.cartDetails);
        setCartItemsLength(res?.data?.totalNumberOfItems);
        // setCartProductSizeId()
        setTotalAmount(res?.data?.totalAmount);
      } else {
        setCartItems([]);
        setCartItemsLength(0);
        setTotalAmount(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const res = await axios.get(`${API_URL}/user-credential/address`, {
        headers: {
          Authorization: token,
        },
      });
      if (res.data.status === 200) {
        setUserAddress(res?.data?.addressDetails);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserAddress();
  }, []);

  useEffect(() => {
    getCartItems();
  }, [cartItemsLength]);

  useEffect(() => {
    getWishList();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterArray]);

  useEffect(() => {
    getOccasionProducts();
  }, [currentPage, searchlimits, varitionId, sortOrder]);

  const debouncedSearchPhrase = useDebounce(query, 500);

  // useEffect(()=>{
  //   if(query !== '')
  //   getSearchProducts()
  // },[debouncedSearchPhrase,filterArray,searchlimits,varitionId,sortOrder])

  useEffect(() => {
    getWesternCatehory();
  }, []);

  useEffect(() => {
    getallFilter();
  }, []);

  useEffect(() => {
    getEthnicCategory();
  }, []);
  useEffect(() => {
    getallProduct();
  }, []);

  useEffect(() => {
    handleParentCategory();
  }, []);

  useEffect(() => {
    // Update varitionId whenever occasionIds or filterArray changes
    setVaritionId([...occasionIds, ...filterArray]);
  }, [occasionIds, filterArray]);

  return (
    <ProductContext.Provider
      value={{
        // setQuery,
        serachQuery,
        setSearchQuery,
        parentCategory,
        allproduct,
        setProductId,
        productId,
        handleAddProductItem,
        handleAddProduct,
        filter,
        setQuery,
        searchProducts,
        query,
        filterArray,
        setFilterArray,
        categoryname,
        setCategoryName,
        totalPages,
        setTotalPages,
        setCurrentPage,
        currentPage,
        ethnicCollection,
        westernCollection,
        categoryId,
        setCategoryId,
        searchlimits,
        setSearchLimits,
        setOccasionIds,
        varitionId,
        OccasionProducts,
        searchlimits,
        wishlistProductId,
        setWishlistProductId,
        token,
        getWishList,
        cartItems,
        setCartItems,
        handleWhilistProduct,
        wishlistproducts,
        setWishlistProducts,
        handleDeleteFromWishlist,
        getCartItems,
        cartItemsLength,
        totalAmount,
        // getSearchProducts,
        sortOrder,
        setSortOrder,
        whislistLoader,
        userAddress,
        setUserAddress,
        setTotalAmount,
        setCartItemsLength,
        setFilterArray,
        setVaritionId,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
