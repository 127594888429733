import React from "react";
import cart from "../Assets/Images/MyCart/cart.png";
import blackcart from "../Assets/Images/MyCart/black-cart.png";
import home from "../Assets/Images/MyCart/home.png";
import payment from "../Assets/Images/MyCart/payment.png";
import { act } from "react-dom/test-utils";
function CartHeader({ activeView, setActiveView }) {
  return (
    <div className="flex justify-center items-center h-32 bg-slate-100">
      <div
        className={`w-14 h-14 flex items-center justify-center rounded-full  cursor-pointer`}
        style={{
          backgroundColor: activeView === "cart" ? "black" : "#D9D9D9",
          color: activeView === "cart" ? "white" : "black",
        }}
        onClick={() => setActiveView("cart")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M17 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2M1 2h3.27l.94 2H20a1 1 0 0 1 1 1c0 .17-.05.34-.12.5l-3.58 6.47c-.34.61-1 1.03-1.75 1.03H8.1l-.9 1.63l-.03.12a.25.25 0 0 0 .25.25H19v2H7a2 2 0 0 1-2-2c0-.35.09-.68.24-.96l1.36-2.45L3 4H1zm6 16a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2m9-7l2.78-5H6.14l2.36 5z"
          />
        </svg>
      </div>
      <span className="h-[0.3vh] w-[20vw] bg-black"></span>
      <div
        className="w-14 h-14 flex items-center justify-center rounded-full  cursor-pointer"
        style={{
          backgroundColor: activeView === "address" ? "black" : "#D9D9D9",
          color: activeView === "address" ? "white" : "black",
        }}
        onClick={() => setActiveView("address")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M6 19h3v-6h6v6h3v-9l-6-4.5L6 10zm-2 2V9l8-6l8 6v12h-7v-6h-2v6zm8-8.75"
          />
        </svg>
      </div>
      <span className="h-[0.3vh] w-[20vw] bg-black"></span>

      <div
        className="w-14 h-14 flex items-center justify-center rounded-full  cursor-pointer"
        style={{
          backgroundColor: activeView === "payment" ? "black" : "#D9D9D9",
          color: activeView === "payment" ? "white" : "black",
        }}
        onClick={() => setActiveView("payment")}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 28 28"><path fill="currentColor" d="M18.25 16.5a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 0-1.5zM2.004 8.75A3.75 3.75 0 0 1 5.754 5H22.25A3.75 3.75 0 0 1 26 8.75v10.5A3.75 3.75 0 0 1 22.25 23H5.755a3.75 3.75 0 0 1-3.75-3.75zm3.75-2.25a2.25 2.25 0 0 0-2.25 2.25v.75H24.5v-.75a2.25 2.25 0 0 0-2.25-2.25zm-2.25 12.75a2.25 2.25 0 0 0 2.25 2.25H22.25a2.25 2.25 0 0 0 2.25-2.25V11H3.505z"/></svg>
      </div>
    </div>
  );
}

export default CartHeader;
