import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === 2 ||  i === totalPages || (i >= currentPage - 2 && i <= currentPage + 2)) {
        pageNumbers.push(i);
      }
    }
  
    const renderDots = (index) => {
      if (pageNumbers[index + 1] - pageNumbers[index] > 1) {
        return <li key={index + 'dots'} className="inline-block mx-1">...</li>;
      }
    };
  
    return pageNumbers.map((number, index) => (
      <React.Fragment key={index}>
        <li key={number} className="inline-block mx-1">
          <button
            onClick={() => onPageChange(number)}
            className={`${
              currentPage === number
                ? "bg-blue-500 text-white"
                : "bg-white text-black"
            } px-3 py-1 border border-gray-300 rounded focus:outline-none focus:shadow-outline`}
          >
            {number}
          </button>
        </li>
        {renderDots(index)}
      </React.Fragment>
    ));
  };
  return (
    <div className="flex justify-center mt-4 lg:py-0 py-5">
      <ul className="flex">
        <li className="inline-block mx-1">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`${
              currentPage === 1 ? "bg-gray-300 text-gray-600" : "bg-white text-black"
            } px-3 py-1 border border-gray-300 rounded-l focus:outline-none focus:shadow-outline`}
          >
            Prev
          </button>
        </li>
        {renderPageNumbers()}
        <li className="inline-block mx-1">
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`${
              currentPage === totalPages ? "bg-gray-300 text-gray-600" : "bg-white text-black"
            } px-3 py-1 border border-gray-300 rounded-r focus:outline-none focus:shadow-outline`}
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;