import React from "react";
import banner1 from "../Assets/Images/MenPage/banner1.png";
import kurtabg from "../Assets/Images/MenPage/kurta-bg.png";
import kurta from "../Assets/Images/MenPage/kurta.png";
import shrewani from "../Assets/Images/MenPage/sherwani.png";
import bg2 from "../Assets/Images/MenPage/bg-2.png";
import jacket from "../Assets/Images/MenPage/jacket.png";
import suit from "../Assets/Images/MenPage/suit.png";
import western from "../Assets/Images/MenPage/western.png";
import { useNavigate } from "react-router-dom";
function MenPage() {
  const navigate = useNavigate();
  const handleProduct = () => {
    navigate("/products");
  };
  return (
    <>
      <div>
        <div>
          <img className="w-full" src={banner1} alt="..." />
        </div>
        <div className="relative">
          <img className="w-full" src={kurtabg} alt="..." />
          <div className="absolute top-40 left-1/2 w-[40rem] transform -translate-x-1/2 -translate-y-1/2 text-center">
            <div className="text-lg">
              “Heritage is complex. For many, it’s restrictive and uniform. For
              others, it’s about making it your own.”
            </div>
          </div>
          <div className="absolute top-64 w-full pt-12 ">
            <div className="relative">
              <img className="w-full px-12" src={kurta} alt="..." />
              <div className="absolute top-60 left-64 transform -translate-x-1/2 -translate-y-1/2 ">
                <div className="text-4xl font-semibold text-white tracking-widest">
                  KURTA
                </div>
              </div>
              <div className="absolute top-72 my-3 left-64 transform -translate-x-1/2 -translate-y-1/2 ">
                <div
                  className=" cursor-pointer  px-10 py-2 bg-black font-semibold  text-white"
                  onClick={handleProduct}
                >
                  EXPLORE
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // */}
        <div>
          <img src={shrewani} className="w-full px-12" alt="..." />
          <div className="text-center text-2xl font-semibold tracking-widest pt-20">
            SHERWANI
          </div>
          <div className="flex justify-center">
            <div
              className="bg-black text-white px-10 py-2 my-3 font-semibold cursor-pointer"
              onClick={handleProduct}
            >
              EXPLORE
            </div>
          </div>
        </div>
        {/* // */}
        <div className="relative">
          <div>
            <img src={bg2} className="w-full" alt="..." />
          </div>
          <div className="absolute top-12 pt-12 px-2  flex justify-center w-full">
            <div className="flex gap-12">
              <div>
                <img src={jacket} className="" alt="..." />
                <div className="text-center text-2xl font-semibold tracking-widest mt-20">
                  NEHRU JACKET
                </div>
                <div className="flex justify-center">
                  <div
                    className="bg-black font-semibold text-white px-10 py-2 my-3 cursor-pointer"
                    onClick={handleProduct}
                  >
                    EXPLORE
                  </div>
                </div>
              </div>
              <div>
                <img src={suit} alt="..." />
                <div className="text-center text-2xl font-semibold tracking-widest mt-20">
                  SALWAR SUIT
                </div>
                <div className="flex justify-center">
                  <div
                    className="bg-black font-semibold text-white px-10 py-2 my-3 cursor-pointer"
                    onClick={handleProduct}
                  >
                    EXPLORE
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-[170vh] flex justify-center w-full">
            <img src={western} className="w-full px-12" alt="..." />
          </div>
          <div className="absolute bottom-40 flex justify-center w-full">
            <div>
              <div className="text-center text-2xl font-semibold tracking-widest mt-20">
                INDO-WESTERN
              </div>
              <div className="flex justify-center">
                <div
                  className="bg-black font-semibold text-white px-10 py-2 my-3 cursor-pointer"
                  onClick={handleProduct}
                >
                  EXPLORE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenPage;
