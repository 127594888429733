import axios from 'axios';
import React from 'react'
import { API_URL } from '../Url';

function PaymentConfirm() {
const handleConfirm= async()=>{
    const token=localStorage.getItem('token');
    console.log("token===>>>>",token);
    try {
        const res= await axios.get(`${API_URL}/payment/stripe/confirm`,{
          headers: {
            Authorization: token
          }
        });
        console.log("re=========>>>>",res)
        if (res?.data) {
          console.log("respond pay,ment==>>>>",res?.data);
          window.location.href = res?.data?.url;
        }
        
    }
    catch (error) {
        console.log("Error in confirming payment", error);
        throw error; // Rethrow the error to propagate it further if needed
    }
}

  return (
    <div className='h-[90vh]'>
    {/* <div>PaymentConfirm</div> */}
    <div className='h-[90vh] flex justify-center items-center'>
    <div className='px-10 py-2 bg-black cursor-pointer text-white text-2xl' onClick={handleConfirm}>Click Here to Confirm</div>
</div>
    </div>
  )
}

export default PaymentConfirm