import React from "react";
import whatsapp from "../Assets/Images/HomePage/whatsapp.png";
import help from "../Assets/Images/HomePage/help.png";

function FooterBtn() {
  const phoneNumber = "1234567890";

  return (
    <>
      <div className="fixed bottom-4 z-10 w-full flex justify-start items-center px-4">
        <img src={help} alt="Help" className="h-10 mr-2 cursor-pointer" />
      </div>

      <div className="fixed bottom-4 right-4 z-10 w-full flex justify-end items-center px-4">
        <a
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={whatsapp}
            alt="WhatsApp"
            className="w-12 h-12 mr-2 cursor-pointer"
          />
        </a>
      </div>
    </>
  );
}

export default FooterBtn;