import React from "react";
import puma from "../../Assets/Images/WomenPage/puma.png";
import chanel from "../../Assets/Images/WomenPage/chanel.png";
import dg from "../../Assets/Images/WomenPage/d&g.png";
import dior from "../../Assets/Images/WomenPage/dior.png";
import zara from "../../Assets/Images/WomenPage/zara.png";
import gucci from '../../Assets/Images/WomenPage/gucci.png'
import versace from  '../../Assets/Images/WomenPage/versace.png'
function WomenBrands() {
  return (
    <div>
      <div className="text-center font-bold text-2xl py-3 uppercase tracking-widest">Brands for you</div>
      {/* <div className="w-10 h-2 bh-black"></div> */}
      <div className="bg-black  lg:px-16 px-3 py-10 lg:py-20">
        <div className="grid grid-cols-5 gap-3  lg:gap-10">
          <img src={puma} className="w-full" alt="" />
          <img src={chanel} className="w-full" alt="" />
          <img src={dg} className="w-full" alt="" />
          <img src={dior} className="w-full" alt="" />
          <img src={zara} className="w-full" alt="" />
        </div>
        <div className="hidden  lg:flex justify-center lg:gap-10 py-10">
            <img src={gucci} alt="" />
            <img src={versace} alt="" />
        </div>
      </div>
    </div>
  );
}

export default WomenBrands;
