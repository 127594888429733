import React, { useContext, useEffect, useState } from "react";
import uparrow from "../../Assets/Images/Header/up-arraw.png";
import ProductCard from "../../Components/ProductCard";
// import { ProductContext } from "../context/ProductContext";
import { useNavigate } from "react-router-dom";
import heartfilled from "../../Assets/Images/ProductCard/heartfilled.svg";
import heart from "../../Assets/Images/ProductCard/heart.svg";
// import ProductPreviewCard from "./ProductPreviewCard";
import { toast } from "react-toastify";
import { ProductContext } from "../../context/ProductContext";
// import Pagination from "../atoms/Pgination";
import axios from "axios";
import { API_URL } from "../../Url";
import Filter from "../Filter";
import Pagination from "../atoms/Pagination";
// import { Pagination } from "react-bootstrap";
function Occasion() {
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [wishlistUpdated, setWishlistUpdated] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [clickedProductIds, setClickedProductIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Featured");
  const context = useContext(ProductContext);
console.log(context);
  const {setVaritionId,sortOrder,setSortOrder,OccasionProducts,totalPages,setCurrentPage,currentPage,searchlimits,getWishList,handleDeleteFromWishlist,setSearchLimits,handleWhilistProduct,wishlistproducts } = useContext(ProductContext);
  console.log("OccasionProducts===>>",OccasionProducts)
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    setVaritionId([])
  },[])

  const handleChange = (e) => {
    setSearchLimits(e.target.value);
    // You can add additional logic here based on the selected option
  };
  const navigate = useNavigate();

  const handleClickProduct = (id) => {
    navigate(`/products/product/${id}`);
  };
  const handlePageChange = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    // getProductByCategory(page);
  };
  const handleSortChange = (event) => {
    setSortOrder(Number(event.target.value));
  };
  const handleWhilistProductClick = async (id) => {
    if (wishlistproducts.some(product => product._id === id)) {
      handleDeleteFromWishlist(id);
    } else {
      handleWhilistProduct(id);
    }
    setWishlistUpdated(prevState => !prevState); // Toggle wishlistUpdated state
  };
  useEffect(() => {
    getWishList();
    setWishlistUpdated(false); // Reset wishlistUpdated state after fetching wishlist
  }, [wishlistUpdated])
 
  return (
    <div className="py-2 lg:w-[90%] w-full">
      <div className="px-3 md:py-3 py-5 md:w-[100%] w-full text-center md:text-3xl inline-block font-bold">
         WOMEN COLLECTION
      </div>
      <div className="lg:w-[813px] hidden md:block text-center mx-auto mb-5 mt-2 text-sm">Daily-wear kurta sets to breezy loungewear, summer dresses to stunning lehengas, find everything you're looking for in our collection of onlineclothes for women.</div>
      
      <div className="flex bg-[#EAEAEA] border-t-4  border-b-4 items-center justify-between my-2 px-3 text-gray-700 py-2">
      <div className="block md:hidden uppercase" onClick={() => setShowFilter(true)}>Filter</div>
        {showFilter &&
          (
          <div className="fixed  w-full top-16 h-[90vh] overflow-y-auto z-50 bg-slate-400 left-0">
            <div className="px-3 py-2">

                <Filter showFilter={ showFilter} setShowFilter={setShowFilter} />
            </div>
              </div>
            )
            }
        <div className="pr-2 hidden md:block">{OccasionProducts?.data?.length} Products</div>
        <select name="sort" id="sort" value={sortOrder} onChange={handleSortChange} className="rounded-lg py-1 px-1">
          <option value="default">Sort By</option>
          <option value="best">Best Selling</option>
          <option value="low">Price, low to high</option>
          <option value="high">Price, high to low</option>
          <option value="1">Date, old to new</option>
          <option value="-1">Date, new to old</option>
        </select>
      </div>
      <div className="grid grid-cols-2 w-full  md:grid-cols-3 lg:grid-col-4 2xl:grid-cols-5 md:gap-y-8 md:gap-x-8 gap-x-3 gap-y-3  py-3">
  {OccasionProducts?.data?.length > 0 ? (
    OccasionProducts?.data?.map((e) => {
      const imageUrl =
        e.productImage?.image?.length > 0
          ? e.productImage.image[0]
          : "https://www.pdquipment.com/wp-content/uploads/dummy-product-image.jpg";
      return (
        <div className=" w-full" key={e._id}>
        <div className=" relative md:h-[385px] h-[30vh] w-[45vw]  md:w-[310px] bg-neutral-600 md:relative">
          <img onClick={() => handleClickProduct(e._id)} className="h-full w-full cursor-pointer" src={imageUrl} alt="prod1"  />
          <img
            onClick={() => handleWhilistProductClick(e._id)}
         
            className="imgab2 absolute right-5  top-5 cursor-pointer"
            src={wishlistproducts?.some(product => product._id === e._id) ? heartfilled : heart} // Use the filled heart if the product is in the wishlist
            style={{ height: "30px" }}
            alt="..."
          />
          </div>
          <div>ABC</div>
          <div className="flex w-full gap-2 md:px-2 md:py-2 font-semibold">{ e.name}</div>
        <div className="flex  md:gap-0 gap-2">
          <div className="font-semibold md:text-lg md:px-2"> ₹ {e.details && e.details.length > 0 ? e.details[0].price : 0}</div>
          <div className=" md:text-lg line-through text-gray-500"> ₹1500</div>
        </div>
      </div>
      );
    })
  ) : (
    <div>No product found</div>
  )}
        </div>
      {/* /pagination button/ */}
    
        {OccasionProducts?.data?.length > 0 ? (
              <>
                <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <>
          </>
        )}

     
    </div>
  );
}

export default Occasion;
