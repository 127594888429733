import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIos } from "react-icons/md";

function TrendingCollection() {
  const data = [
    {
      image:
        "https://www.jomso.com/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/p/f/pf-45.jpg",
      name: "Product 1",
      brandName: "Jomso",
      price: "$100",
      discountPrice: "$50",
    },
    {
      image:
        "https://i.pinimg.com/736x/39/c8/56/39c856773d38289c21bd30c0f3adb8d2.jpg",
      name: "Product 2",
      brandName: "Jomso",
      discountPrice: "$50",

      price: "$200",
    },
    {
      image:
        "https://www.mongoosekart.com/image/cache/data/saree/Maroon%20Color%20Wedding%20Designer%20Saree-800x800.jpeg",
      name: "Product 3",
      brandName: "Jomso",
      discountPrice: "$50",

      price: "$300",
    },
    {
      image:
        "https://5.imimg.com/data5/SELLER/Default/2020/9/IV/CF/GL/112475050/whatsapp-image-2020-08-31-at-12-44-16-pm-500x500.jpeg",
      name: "Product 4",
      brandName: "Jomso",
      discountPrice: "$50",

      price: "$400",
    },
    // add more items as needed
  ];
  const EmptyArrow = () => null;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <MdArrowForwardIos size={20} color="black" />,
    prevArrow: <MdArrowBackIos size={20} color="black" />,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 480, // breakpoint from window width 480px and down
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: <EmptyArrow />,
        prevArrow: <EmptyArrow />,
        }
      }
    ]
  };

  return (
    <div>
      {/* <h2>Trending Collection</h2> */}
      <Slider {...settings}>
        {data.map((item, index) => (
          <div key={index} className="lg:px-3 px-2 ">
            <img
              src={item.image}
              alt={item.name}
              className="lg:h-[60vh] h-[30vh] object-cover  w-full"
            />
            <h3 className="font-bold pt-2 text-base">{item.name}</h3>
            <div>{item.brandName}</div>
            <div className="flex gap-3">
            <p className="font-bold">{item.price}</p>
              <div className="text-neutral-400 line-through">{item.discountPrice}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TrendingCollection;
