import React, { useContext, useEffect, useState } from "react";
import "../Css/ProductDetails.css";
import heartfilled from "../Assets/Images/ProductCard/heartfilled.svg";
import heart from "../Assets/Images/ProductDetails/heart.png";
import rightarraw from "../Assets/Images/ProductDetails/rightarrow.png";
import plus from "../Assets/Images/Header/plus.png";
import arrow from "../Assets/Images/ProductDetails/arrow.png";
import right from "../Assets/Images/ProductDetails/right.png";
// import { toast } from 'react-toastify';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

// import 'ProductDetails.css'
import Breadcrumb from "../Components/Breadcrumb";
import LikedProduct from "../Components/LikedProduct";
import Ratings from "../Components/Ratings";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../Url";
import axios from "axios";
import { ProductContext } from "../context/ProductContext";
// import { Breadcrumb } from "react-bootstrap";
const ProductDetails = () => {
  const { id } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [sizeId, setSizeId] = useState("");
  const [selectedSize, setSelectedSize] = useState(""); // State to track the selected size
  const [selectedPrice, setSelectedPrice] = useState(0); // State to track the selected price
  const [recommendedProducts, setRecommendedProducts] = useState([]); // State to store recommended products
  const {
    cartItems,
    wishlistproducts,
    handleDeleteFromWishlist,
    getCartItems,
  } = useContext(ProductContext);
  const [isInWishlist, setIsInWishlist] = useState(false);
  console.log("SelectedSize", selectedSize);
  console.log("SelectedSizeId==>>>", sizeId);
  console.log("quantity==>>>", quantity);
  // const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [detail, setDetail] = useState({}); // Initialize as an object
  const [productdetail, setProductDetail] = useState({}); // Initialize as an object
  const [productdetails, setProductDetails] = useState(false);
  const [wash, setWash] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    image: "",
    video: "",
  }); // Set the default image
  const [currentIndex, setCurrentIndex] = useState(0);
  // console.log(id);
  console.log("cartItems===>>>", cartItems);
  const productInCart = cartItems?.find(
    (item) => item.productId === id && item.chosenSize === selectedSize
  );
  const [showSharePopup, setShowSharePopup] = useState(false);
  const url = window.location.href; // URL of the current page
  const [isliked, setIsliked] = useState(false);

  const [pincode, setPincode] = useState("");

  const handleInputChange = (e) => {
    setPincode(e.target.value);
  };
  const handleQuantityChange = (event) => {
    setQuantity(Number(event.target.value));
  };

  const handleCheckClick = () => {
    console.log("Checking pincode:", pincode);
  };

  const handleCarouselPrevClick = () => {
    const imageArray = detail.productImage?.image || [];
    const videoArray = detail.productImage?.video || [];

    const prevIndex =
      (currentIndex - 1 + imageArray.length + videoArray.length) %
      (imageArray.length + videoArray.length);

    setSelectedImage({
      image: imageArray[prevIndex] || "",
      video: videoArray[prevIndex - imageArray.length] || "",
    });

    setCurrentIndex(prevIndex);
  };

  const handleCarouselNextClick = () => {
    const imageArray = detail.productImage?.image || [];
    const videoArray = detail.productImage?.video || [];

    const nextIndex =
      (currentIndex + 1) % (imageArray.length + videoArray.length);

    setSelectedImage({
      image: imageArray[nextIndex] || "",
      video: videoArray[nextIndex - imageArray.length] || "",
    });

    setCurrentIndex(nextIndex);
  };

  const toggleVisibility = (filter) => {
    switch (filter) {
      case "product_detail":
        setProductDetails(!productdetails);
        break;

      default:
        break;
    }
  };
  const handleDetail = async (id) => {
    setIsLoader(true);
    try {
      const res = await axios.get(`${API_URL}/products/product/${id}`);
      if (res?.data?.status === 200) {
        setDetail(res?.data?.productResponse);
        getSimilarProducts(
          res?.data?.productResponse.categoryIds,
          res?.data?.productResponse.variationOptionId
        );
        setIsLoader(false);
      }

      console.log(detail);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching data", error);
    }
  };
  // console.log("recco=====>>>>>",recommendedProducts)
  const getSimilarProducts = async (categoryIds, variationOptions) => {
    // console.log('Detail:', detail); // Log detail object
    try {
      const res = await axios.post(`${API_URL}/products/recommend`, {
        categoryIds: categoryIds,
        variationOptions: variationOptions,
      });
      console.log("resssssss", res);
      if (res?.data?.status === 200) {
        setRecommendedProducts(res?.data?.recommendedProducts);
      }
      console.log("Similar Products:", res?.data);
    } catch (error) {
      console.log("Error:", error.message); // Log error message
      console.log("Error details:", error.response?.data); // Log error details from axios
      toast.error("Error fetching data", error);
    }
  };

  const handleWhilistProduct = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.put(
        `${API_URL}/user-credential/add-wishlist/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Server response:", res?.data);
      if (res.status === 200) {
        console.log("Product Added to Wishlist successfully");
        setIsliked(true);
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error(
        "Error adding product to Wishlist. Check console for details."
      );
      throw error; // Rethrow the error to propagate it further if needed
    }
  };

  const handleClick = () => {
    if (isInWishlist) {
      handleDeleteFromWishlist(id); // Replace 'id' with the id of the product
    } else {
      handleWhilistProduct(id); // Replace 'id' with the id of the product
    }
    setIsInWishlist((prevState) => !prevState); // Toggle isInWishlist state
  };

  const handleSizeClick = (size, price, sizeId) => {
    setSelectedSize(size);
    setSizeId(sizeId);
    setSelectedPrice(price);
  };

  const handleAddCart = async (id) => {
    try {
      const body = {
        qty: quantity,
        sizeId: sizeId,
        size: selectedSize,
      };
      const res = await axios.post(`${API_URL}/cart/add-cart/${id}`, body, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("status Code=>>>>>", res.status);
      console.log("res", res);
      if (res?.data?.status === 200) {
        console.log("Server response:", res?.data);
        toast.success(res.data.message);
        getCartItems();
      }
      if (res?.data.status !== 200) {
        console.log("Server response:", res?.data.message);

        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error adding product to cart");
    }
  };

  // console.log("SelectedSize",selectedSize)
  useEffect(() => {
    handleDetail(id);
  }, [id]);

  useEffect(() => {
    // Update selectedImage when product details are loaded
    if (detail.productImage && typeof detail.productImage === "object") {
      const imageArray = detail.productImage.image || [];
      const videoArray = detail.productImage.video || [];

      if (imageArray.length > 0 || videoArray.length > 0) {
        setSelectedImage((prevSelectedImage) => ({
          ...prevSelectedImage,
          image: imageArray.length > 0 ? imageArray[0] : "",
          video: videoArray.length > 0 ? videoArray[0] : "",
        }));
      }
    }

    // Set default size and price if only one size is available
    if (detail.details && detail.details.length >= 1) {
      setSelectedSize(detail.details[0].size);
      setSelectedPrice(detail.details[0].price);
      setSizeId(detail.details[0].sizeId);
    }
  }, [detail]);

  // console.log(">>>>",detail)

  useEffect(() => {
    setIsInWishlist(
      wishlistproducts?.some((wishlistProduct) => wishlistProduct._id === id)
    );
  }, [wishlistproducts, id]);

  return (
    <>
      {isLoader ? (
        <>
          <div className=" flex justify-center h-[90vh] items-center w-full">
            <div
              class="animate-spin inline-block size-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="hidden md:block">
            <Breadcrumb
              breadcrumbs={[
                { title: "Home", path: "/" },
                // { title: "Products", path: "/search" },
                { title: "Productdetail", path: `/products/product/${id}` },
              ]}
            />
          </div>
          <div className="md:flex md:px-16 gap-12 md:py-10 2xl:w-[1440px] mx-auto">
            <div className=" md:gap-3  md:flex md:h-[100vh] h-[65vh]">
              <div className="hidden md:block h-[100vh] space-y-4">
                {detail.productImage?.image.map((imageUrl, index) => (
                  <div
                    key={index}
                    className={`h-[19vh] w-[7vw]   cursor-pointer ${
                      index === currentIndex ? "border-2 border-blue-500" : ""
                    }`}
                    onClick={() => {
                      setSelectedImage((prevSelectedImage) => ({
                        ...prevSelectedImage,
                        image: imageUrl,
                        video: "",
                      }));
                      setCurrentIndex(index);
                    }}
                  >
                    <img
                      className="cursor-pointer  w-full h-full "
                      src={imageUrl}
                      alt={`product-${index}`}
                    />
                  </div>
                ))}
                {detail.productImage?.video.map((videoUrl, index) => (
                  <div
                    key={index}
                    className={`h-[15vh] rounded-lg  cursor-pointer ${
                      index === currentIndex ? "border-2 border-blue-500" : ""
                    }`}
                    onClick={() => {
                      setSelectedImage((prevSelectedImage) => ({
                        ...prevSelectedImage,
                        video: videoUrl,
                        image: "",
                      }));
                      setCurrentIndex(index);
                    }}
                  >
                    <video
                      className="cursor-pointer object-cover w-full h-full rounded-md"
                      // controls // Add controls attribute here
                    >
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ))}
              </div>
              {console.log(selectedImage.image)}
              <div className=" md:h-[90vh] h-[60vh] w-full md:w-[35vw]  relative">
                {selectedImage.image && (
                  <>
                    <img
                      className="w-full h-full object-cover "
                      src={selectedImage.image}
                      alt="products"
                    />
                  </>
                )}
                {selectedImage.video && (
                  <>
                    <video
                      className="cursor-pointer object-cover w-full h-full "
                      controls // Add controls attribute here
                    >
                      <source src={selectedImage.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </>
                )}
                <div
                  className="absolute md:top-[50vh] top-1/2  mx-2 py-1 rounded-full h-10 w-10 transform -translate-y-1/2 left-50 cursor-pointer"
                  onClick={handleCarouselPrevClick}
                >
                  {/* <img src={arrow} className="h-8 w-10 " alt="Previous" /> */}
                  <FaArrowAltCircleLeft size={40} />
                </div>
                <div
                  className="absolute top-1/2 md:top-[50vh] mx-2 py-1 rounded-full  h-10 w-10 transform -translate-y-1/2 right-0 cursor-pointer"
                  onClick={handleCarouselNextClick}
                >
                  {/* <img src={right} alt="Next" /> */}
                  <FaArrowCircleRight size={40} />
                </div>
              </div>
            </div>
            {/* right-section */}

            <div className="md:w-[40%]  md:px-0 px-5 ">
              <div className="text-3xl font-bold uppercase w-[100%] text-center md:text-left">
                {detail.name}
              </div>
              <div className="text-gray-700  py-3 text-sm md:text-left text-center">
                {detail?.description?.length > 200
                  ? detail.description.substring(0, 200) + "..."
                  : detail.description}
              </div>
              <div className="md:flex text-3xl font-semibold gap-4 mt-3">
                <div className="text-[#34251F] text-center md:text-right">
                  Rs. {selectedPrice}.00
                </div>
                <div className="text-black opacity-35 font-bold line-through hidden md:block ">
                  MRP ₹ 2,999
                </div>
              </div>
              <div className="text-gray-400 py-2 md:text-left text-center">
                inclusive of all tasks
              </div>
              <hr className="block md:hidden mt-5" />
              <div className="text-md  md:mt-20 mt-5">SELECT SIZE</div>
              <div className="flex gap-3  py-3 text-xl">
                {detail?.details?.map((sizeInfo, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      handleSizeClick(
                        sizeInfo.size,
                        sizeInfo.price,
                        sizeInfo.sizeId
                      )
                    }
                    className={` text-center rounded-full px-3 py-3 cursor-pointer uppercase w-[55px] h-[55px] ${
                      sizeInfo.size === selectedSize
                        ? "border-2 text-black font-semibold"
                        : "border-2"
                    }`}
                  >
                    {sizeInfo.size}
                  </div>
                ))}
              </div>
              <div className="flex items-center py-3 gap-2">
                <div>Quantity</div>
                <div>
                  <select
                    value={quantity}
                    onChange={handleQuantityChange}
                    className="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none "
                  >
                    {[1, 2, 3, 4, 5].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="my-3 text-gray-400 px-3">
                <ul>
                  <li className="list-disc">Free Shipping On Prepaid & COD*</li>
                  <li className="list-disc">100% Purchase Protection</li>
                  <li className="list-disc">Assured Quality</li>
                </ul>
              </div>
              <hr className="my-7" />
              <div className="flex gap-2">
                {/* <div
              className="px-20 rounded-xl py-3 text-white bg-[#9F2F2F] cursor-pointer"
              onClick={()=>handleAddCart(id)}
            >
              {" "}
              ADD TO BAG
            </div> */}
                {productInCart ? (
                  <div
                    className="lg:px-20 px-[15vw] rounded-xl flex justify-center items-center  text-white bg-black cursor-pointer"
                    onClick={() => navigate("/cart")}
                  >
                    Go to Cart
                  </div>
                ) : (
                  <div
                    className="lg:px-20 px-[15vw] justify-center items-center  rounded-xl flex  text-white bg-black cursor-pointer"
                    onClick={() => handleAddCart(id)}
                  >
                    Add to Bag
                  </div>
                )}
                <div className="border-2 border-[#B7B7B7] px-2 rounded-xl">
                  <img
                    onClick={handleClick}
                    className={
                      isliked === true
                        ? "h-9 mt-2 cursor-pointer"
                        : "mt-2 h-9 cursor-pointer"
                    }
                    src={isInWishlist === true ? heartfilled : heart}
                    alt="heart-logo"
                  />
                </div>
                <div
                  className="border-2 border-[#B7B7B7] px-2 rounded-xl"
                  onClick={() => setShowSharePopup(!showSharePopup)}
                >
                  <img
                    className="py-2 cursor-pointer "
                    src={rightarraw}
                    alt="share-btn"
                  />
                </div>
              </div>
              {/* <div></div> */}
              {showSharePopup && (
                <div className="share-popup space-x-2 mt-5">
                  <WhatsappShareButton url={url}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <FacebookShareButton url={url}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={url}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton url={url}>
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              )}
              <div className="font-semibold mt-7">DELIVERY</div>
              <div className=" md:mr-24 flex justify-between border-2  rounded-lg border-[#B7B7B7]">
                <input
                  className="py-3 rounded w-full md:mr-24 px-2 outline-none"
                  type="text"
                  placeholder="ENTER PINCODE"
                  value={pincode}
                  onChange={handleInputChange}
                />
                <span
                  className={`px-3 py-3 font-semibold   ${
                    pincode
                      ? "active text-[#9F2F2F] cursor-pointer"
                      : "text-[#BD8B8B] cursor-not-allowed"
                  }`}
                  onClick={handleCheckClick}
                >
                  CHECK
                </span>
              </div>
              {/* // */}
              <div
                onClick={() => toggleVisibility("product_detail")}
                className="px-3 py-2 md:mr-24  cursor-pointer mt-7 my-2 border-[#B7B7B7] rounded-md border-2 flex justify-between"
              >
                <div className="text-xl text-gray-400 py-2">
                  PRODUCT DETAILS
                </div>
                <div>
                  <img src={plus} alt="" />
                </div>
              </div>
              {productdetails && (
                <div className="lg:mr-24">
                  <div>
                    <div className="flex justify-between  font-semibold text-black opacity-50">
                      <div className="w-[50] ">Fabric</div>
                      <div className="w-[50%]">Occasion</div>
                    </div>
                    <div className="flex justify-between ">
                      <div className="border-b w-[40%]  border-black">
                        Cotton
                      </div>
                      <div className="border-b w-[50%]  border-black">
                        Casual
                      </div>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="flex justify-between   text-black opacity-50">
                      <div className="w-[50] ">Color</div>
                      <div className="w-[50%]">Pattern</div>
                    </div>
                    <div className="flex justify-between ">
                      <div className="border-b w-[40%]  border-black">
                        Green
                      </div>
                      <div className="border-b w-[50%]  border-black">
                        Plain
                      </div>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="flex justify-between  text-black opacity-50">
                      <div className="w-[50] ">Material</div>
                      <div className="w-[50%]">Discount</div>
                    </div>
                    <div className="flex justify-between ">
                      <div className="border-b w-[40%]  border-black">
                        Cotton
                      </div>
                      <div className="border-b w-[50%]  border-black">30%</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="px-3 py-2 md:mr-24    my-2 border-[#B7B7B7] rounded-md border-2 flex justify-between">
                <div className="text-xl text-gray-400 py-2">WASH & CARE</div>
                <div>
                  <img src={plus} alt="" />
                </div>
              </div>
              <div className="px-3 py-2 md:mr-24    my-2 border-[#B7B7B7] rounded-md border-2 flex justify-between">
                <div className="text-xl text-gray-400 py-2">
                  DELIVERY & RETURNS
                </div>
                <div>
                  <img src={plus} alt="" />
                </div>
              </div>
              <div className="px-3 py-2 md:mr-24    my-2 border-[#B7B7B7] rounded-md border-2 flex justify-between">
                <div className="text-xl text-gray-400 py-2">
                  SHIPPING & RETURNS
                </div>
                <div>
                  <img src={plus} alt="" />
                </div>
              </div>
              <div className="px-3 py-2 md:mr-24    my-2 border-[#B7B7B7] rounded-md border-2 flex justify-between">
                <div className="text-xl text-gray-400 py-2">REVIEWS</div>
                <div>
                  <img src={plus} alt="" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="">
            <LikedProduct
              recommendedProducts={recommendedProducts}
              handleWhilistProduct={handleWhilistProduct}
            />
            {/* <Ratings /> */}
          </div>
        </>
      )}

      {/* Ratings */}
    </>
  );
};
export default ProductDetails;
