import React, { useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.min.css';
import brandbg from "../Assets/Images/HomePage/brandbg.png";
import "swiper/swiper-bundle.css";
import Typewriter from "../Components/Typewriter";
import { useNavigate } from "react-router-dom";
import women from '../Assets/Images/HomePage/women.png'
import w3 from "../Assets/Images/HomePage/w3.png";
import w4 from "../Assets/Images/HomePage/w4.png";
import trend1 from "../Assets/Images/HomePage/trend1.png";
import trend2 from "../Assets/Images/HomePage/trend2.png";
import trend3 from "../Assets/Images/HomePage/trend3.png";
import banner5 from "../Assets/Images/HomePage/banner5.png";
import landingVideo from "../Assets/Videos/landing.mp4";

import FooterBtn from "../Components/FooterBtn";
import TopBrandDeal from "../Components/atoms/TopBrandDeal";
import TrendingCollection from "../Components/molecules/TrendingCollection";
const Home = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleWomenClick = () => {
    navigate("/search");
  };
  return (
    <>
      {/* <FooterBtn /> */}

      <div className="md:h-[90vh] w-full  relative">
        <video
          className="hero-video w-full h-full object-cover "
          autoPlay
          loop
          muted
        >
          <source src={landingVideo} />
        </video>
        <div className="w-full absolute lg:bottom-20 bottom-4 flex justify-center text-white text-xl lg:text-6xl font-bold">
          <Typewriter text="Find The Best Ethnic Style For You" delay={100} />
        </div>
      </div>

      <div className="">
        <img src={brandbg} className="w-full lg:h-[15vh] object-cover" alt="..." />
      </div>

      {/* ethnic section  */}

      <div className="text-center font-semibold text-3xl pt-16 pb-8" style={{fontStyle:"Ubuntu"}}>
        Select Your Style
      </div>
      {/* banner-3 */}
      <div className="md:flex md:space-y-0 space-y-5 px-3 lg:px-24 2xl:w-[1440px] md:mx-auto md:justify-between  md:gap-5 mt-5">
        <div
          className="relative md:w-[690px] md:h-[70vh] overflow-hidden"
          onClick={handleWomenClick}
        >
          <img
            className="md:w-[690px] md:h-[760px] object-cover hover:scale-[1.1] transform transition duration-[4000ms] "
            src={w3}
            alt=""
          />
          <div className="absolute z-[2] bottom-[60px] left-[20vw] md:left-1/3 px-4 py-2 border-2 cursor-pointer bg-white hover:bg-transparent hover:text-white hover:border-white border-black text-2xl transition-colors duration-1000 ease-in-out">
            WOMENS WEAR
          </div>
          <div className="absolute h-1/2 w-full top-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="relative md:w-[690px] md:h-[70vh] overflow-hidden">
          <img
            className="md:w-[690px] md:h-[760px] object-cover hover:scale-[1.1] transform transition duration-[4000ms]"
            src={w4}
            alt=""
          />
          <div className="absolute z-[2] bottom-[60px] left-[20vw] md:left-1/3 px-4 cursor-pointer py-2 border-2 bg-white hover:bg-transparent hover:text-white hover:border-white border-black text-2xl transition-colors duration-1000 ease-in-out">
            WEDDING WEAR
          </div>
          <div className="absolute h-1/2 w-full top-1/2 bg-gradient-to-t from-black"></div>
        </div>
      </div>

      {/* Offer Page
      <div className=" py-10 px-3 md:px-20 2xl:w-[1440px] mx-auto">
        <div className="md:flex md:justify-between md:items-center">
          <div>
            <div className="md:text-6xl text-4xl font-bold">FLAT 80% OFF</div>
            <div className="text-2xl font-bold mt-2">
              GET EXCLUSIVE DEALS TODAY!
            </div>
            <div className="text-base mt-2 md:py-0 py-3">
              4.8 average rating from 1814 reviews
            </div>
          </div>
          <div>
            <img src={ow} alt="" srcset="" />
          </div>
        </div>
      </div> */}
      <div className="py-24 lg:px-24 ">
        <TopBrandDeal />
      </div>

      <div>
        <div className="text-center text-3xl font-extrabold tracking-widest">
          Trending Collection
        </div>
        <div className="text-center lg:text-base text-xs text-zinc-500 lg:px-0 px-3 py-1">
          Check out most promising product bought by our buyers
        </div>
        <div className="lg:px-24 px-3 py-10">
          <TrendingCollection />
        </div>
      </div>

      <div className="bg-red-100 py-24">
        <div className="text-center text-2xl lg:text-3xl font-extrabold tracking-widest  ">
          SHOP THE NEW DROP !
        </div>
        {/* <div className="text-center text-zinc-500 py-1">
          Check out most promising product bought by our buyers
        </div> */}
        <div className="lg:px-24 px-3 w-full py-10">
          <TrendingCollection />
        </div>
      </div>


      <div className="py-24">
        <div className="text-center text-2xl lg:text-3xl font-bold  ">TRENDING COLLECTIONS </div>
        <div className=" grid grid-cols-3 px-3 lg:px-24 lg:gap-3 gap-2 pt-10">
          <img src={trend1} className="w-full" alt="..." />
          <img src={trend2} className="w-full" alt="..." />
          <img src={trend3} className="w-full" alt="..." />
          {/* <img src={trend2} alt="..." /> */}
        </div>
      </div>


      {/* customer speak */}
      {/* <div className="bg-red-100 py-24 w-full px-24 ">
        <div className="text-center text-2xl">Our Customer speaks for us</div>
        <div className="pt-10">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit eligendi dolore adipisci, nesciunt ab eaque saepe sed, harum deleniti a facere. Asperiores nam debitis blanditiis quaerat exercitationem ratione quae non!ˀ</div>
      </div> */}

      {/* BANNER-4 */}
      <div className="hidden lg:block">
        <div className="w-full flex lg:px-8 justify-between items-center  bg-black">
          <img src={women} alt="Your Alt Text" className="lg:w-[12vw] h-[20vh] lg:h-[30vh] object-" />
          <div className="text-white text-center w-[40vw]">“ I prefer traditional clothing through the lens of maximalism or minimalism. The in between leaves me cold.”</div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Home;
