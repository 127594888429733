import React from "react";

const AccountNavigationButton = (props) => {
  const { current, imageUrl, heading, desc, section, setSection } = props.data;
  return (
    <div
      className={`flex lg:w-64 lg:h-24 w-40  py-4 lg:px-3 px-3 lg:justify-start justify-center  rounded lg:gap-3 cursor-pointer ${
        section === current
          ? "bg-slate-200 border-2 border-blue-400"
          : "bg-white"
      } `}
      onClick={() => setSection(current)}
    >
      <img src={imageUrl} alt="logo" className="object-contain" />
      <div className="flex flex-col justify-center">
        <div>{heading}</div>
        <div className="text-gray-500 text-sm lg:block hidden">{desc}</div>
      </div>
    </div>
  );
};

export default AccountNavigationButton;
