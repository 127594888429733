import React, { useContext, useState } from "react";
import Cart from "./Cart";
import CartHeader from "../Components/CartHeader";
import FooterHeader from "../Components/FooterHeader";
import AddressPage from "./AddressPage";
import SuccessPage from "./Payment/SuccessPage";
import Summery from "./Payment/Summery";
import { ProductContext } from "../context/ProductContext";
import { useNavigate } from "react-router-dom";

function CartPage() {
  const {cartItems} = useContext(ProductContext);
  const [activeView, setActiveView] = useState("cart");
  const [selectedAdd, setSelectedAdd] = useState("")
  const [address,setAddress] = useState([])
  const navigate=useNavigate()
  console.log("=====>>>>", selectedAdd);
  return (
    <>
    { cartItems.length===0 ?(
      <div className="flex justify-center items-center h-[90vh]">
        <div className="">
          <div className="flex justify-center">

          <img className="h-52 w-52 object-fill animate-bounce" src="https://img.freepik.com/premium-vector/shopping-cart-with-cross-mark-wireless-paymant-icon-shopping-bag-failure-paymant-sign-online-shopping-vector_662353-912.jpg" alt="" />
          </div>

          <div className="p-4 my-10 text-lg text-center text-yellow-700 rounded-lg bg-yellow-50 " role="alert">
                <span className="font-medium ">Uh-oh ! </span> Your Cart Is Empty !
          </div>
          <div>Before Proceed to checkout, you must add some products to your cart.</div>
          <div className="text-center">You will find a lot of interesting products on our "Shop" Page .</div>
          <div className="flex justify-center py-5">

          <button className="px-10 py-3 bg-green-600 text-white hover:bg-green-700" onClick={()=>navigate('/search')}>RETURN TO SHOP</button>
          </div>
        </div>
      </div>
    ):(
      <>

      <div className="h-full bg-slate-100 ">
        <CartHeader activeView={activeView} setActiveView={setActiveView} />
        {activeView === "cart" && (
          <>
            <Cart setActiveView={setActiveView} />
          </>
        )}
        {activeView === "address" && (
          <>
            <AddressPage address={address} setAddress={setAddress} selectedAdd={selectedAdd} setSelectedAdd={setSelectedAdd} setActiveView={setActiveView} />
          </>
        )}
        {activeView === "payment" && (
          <Summery address={address}  selectedAdd={selectedAdd} />
        )}
        
        <FooterHeader />
      </div>
      </>
    )}

    </>
  );
}

export default CartPage;
