import React, { useContext, useEffect, useState } from "react";
import uparrow from "../Assets/Images/Header/up-arraw.png";
import ProductCard from "./ProductCard";
// import { ProductContext } from "../context/ProductContext";
import { useNavigate, useParams } from "react-router-dom";
// import ProductPreviewCard from "./ProductPreviewCard";
import heartfilled from "../Assets/Images/ProductCard/heartfilled.svg";
import heart from "../Assets/Images/ProductCard/heart.svg";

import men1 from "../Assets/Images/HomePage/m1.png";
import axios from "axios";
import { API_URL } from "../Url";
import { toast } from "react-toastify";
import { products } from "../Assets/Data/Products";
import { ProductContext } from "../context/ProductContext";
import Pagination from "./atoms/Pagination";
import Filter from '../Components/Filter'
function Product() {
  const [isliked, setIsliked] = useState([]);
  const [wishlistUpdated, setWishlistUpdated] = useState(false);
  // const [showComponent, setShowComponent] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const { categoryId } = useParams()
  const [wishlistIds, setWishlistIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Added currentPage state
  const [totalPages, setTotalPages] = useState(1);
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [clickedProductIds, setClickedProductIds] = useState([]);
  const [limits, setLimits] = useState("9");
  const {setFilterArray,setSortOrder,sortOrder ,filterArray,wishlistproducts,setWishlistProducts,handleDeleteFromWishlist, handleWhilistProduct,getWishList} = useContext(ProductContext);
  // const [isliked, setIsliked] = useState(true);
  const [hoverStatus, setHoverStatus] = useState(false);

  const token=localStorage.getItem('token');
  console.log("token===>>>", token)

  useEffect(() => {
    setFilterArray([])
    // getProductByCategory()

  },[])

  // const[pages,setPages]=useState('')
const[allProducts,setAllProducts]=useState([])
  const handleChange = (e) => {
    setLimits(e.target.value);
    // You can add additional logic here based on the selected option
  };

  const navigate = useNavigate();

  const handleClick = () => {
    setIsliked(!isliked);
  };
  const handleWhilistProductClick = async (id) => {
    const wishlist = wishlistproducts || [];
    if (wishlist.some(product => product._id === id)) {
      handleDeleteFromWishlist(id);
    } else {
      handleWhilistProduct(id);
    }
    setWishlistUpdated(prevState => !prevState); // Toggle wishlistUpdated state
  };
console.log("categoryId==>>",categoryId)
console.log("filterArray==>>",filterArray)
  // console.log("limits===>>>",selectedOption)
  useEffect(() => {
    getWishList();
    setWishlistUpdated(false); // Reset wishlistUpdated state after fetching wishlist
  }, [wishlistUpdated]);
const getProductByCategory = async () => {
  const requestBody = {
    variationOptions:filterArray,
    page: currentPage,
    limit: limits,
    sortOrder: sortOrder,
  };

  try {
    const res = await axios.post(`${API_URL}/products/category/${categoryId}`, requestBody);
    setAllProducts(res?.data);
    setTotalPages(res?.data?.totalNumberOfPages);

    // console.log(">>", res?.data);
  } catch (error) {
    console.log("error====>>>", error);
    toast.error("Error in fetching Product By Category");
  }
};
console.log("CLiked product ID===>>>",clickedProductIds)



  const handlePageChange = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    // getProductByCategory(page);
  };
  console.log("Product BY Category===>>>>",allProducts.data)


  useEffect(()=>{
    getProductByCategory()
      },[filterArray, currentPage,categoryId,limits,sortOrder])

  const handleClickProduct = (id) => {
    navigate(`/products/product/${id}`);
  };
  const handleSortChange = (event) => {
    setSortOrder(Number(event.target.value));
  };
//  console.log(first)
  return (
    <div className="py-2 lg:w-[90%] w-full">
      <div className="px-3 md:py-3 py-5 md:w-[100%] w-full text-center md:text-3xl inline-block font-bold">
       {/* {categoryName}COLLECTION */}
      </div>
      <div className="lg:w-[813px] hidden md:block text-center mx-auto mb-5 mt-2 text-sm">Daily-wear kurta sets to breezy loungewear, summer dresses to stunning lehengas, find everything you're looking for in our collection of onlineclothes for women.</div>
      <div className="flex bg-[#EAEAEA] border-t-4  border-b-4 items-center justify-between my-2 px-3 text-gray-700 py-2">
      <div className="block md:hidden uppercase" onClick={() => setShowFilter(true)}>Filter</div>
        {showFilter &&
          (
          <div className="absolute  w-full top-14 h-[80vh] overflow-y-auto z-50 bg-slate-400 left-0">
            <div className="px-3 py-2">

                <Filter showFilter={ showFilter} setShowFilter={setShowFilter} />
            </div>
              </div>
            )
            }
        <div className="pr-2 hidden md:block">{allProducts?.data?.length} Products</div>
        <select name="sort" id="sort" value={sortOrder} onChange={handleSortChange} className="rounded-lg py-1 px-1 uppercase">
          <option value="default">Sort By</option>
          <option value="best">Best Selling</option>
          <option value="low">Price, low to high</option>
          <option value="high">Price, high to low</option>
          <option value="1">Date, old to new</option>
          <option value="-1">Date, new to old</option>
        </select>
      </div>
      <div className="grid grid-cols-2 w-full  md:grid-cols-3 lg:grid-col-3 2xl:grid-cols-4 md:gap-y-8 md:gap-x-8 gap-x-3 gap-y-3  py-3">
          {allProducts?.data?.length > 0 ? (
            allProducts?.data?.map((e) => {
              const imageUrl =
                e.productImage?.image?.length > 0
                  ? e.productImage.image[0]
                  : "https://www.pdquipment.com/wp-content/uploads/dummy-product-image.jpg";

              return (
                <div className="w-full " key={e._id}>
                  <div className=" relative md:h-[385px] h-[30vh] w-[45vw]  md:w-[310px] bg-neutral-600 md:relative ">
                    <img onClick={() => handleClickProduct(e._id)} className="  h-full w-full cursor-pointer " src={imageUrl} alt="prod1"  />
                    <img
                      onClick={() => handleWhilistProductClick(e._id)}
                      className="imgab2 absolute md:absolute md:right-5 top-2  right-2 md:top-5 cursor-pointer"
                      src={wishlistproducts?.some(product => product._id === e._id) ? heartfilled : heart} // Use the filled heart if the product is in the wishlist
                      style={{ height: "30px" }}
                      alt="..."
                    />
                  </div>
                  {/* <div>ABC</div> */}
                  <div className="flex w-full gap-2 md:px-0 md:py-1  overflow-hidden font-semibold">{ e.name}</div>
                  <div className="flex md:gap-2 gap-2">
                    <div className="font-semibold  md:px-0"> Rs. {e.details && e.details.length > 0 ? e.details[0].price : 0}</div>
                    <div className="  line-through text-gray-500"> ₹1500</div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No product found</div>
          )}
      </div>
      {/* /pagination button/ */}
      {allProducts?.data?.length > 0 ? (
        <>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
      />
        </>
        
        ) : (
          <>
          </>
        )}
      
    </div>
  );
}

export default Product;
