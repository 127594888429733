import React, { useState } from "react";
import AccountNavigationButton from "../atoms/AccountNavigationButton";
import order from "../../Assets/Images/AccountPage/order.png";
import profile from "../../Assets/Images/AccountPage/profile.png";
import address from "../../Assets/Images/AccountPage/address.png";
export const AccountNavigation = ({ section, setSection }) => {
  const data1 = {
    current: "order",
    imageUrl: order,
    heading: "Your Order",
    desc: "Track, return or buy",
    section: section,
    setSection: setSection,
  };
  const data2 = {
    current: "profile",
    imageUrl: profile,
    heading: "Profile",
    desc: "Edit Profile and contact details",
    section: section,
    setSection: setSection,
  };
  const data3 = {
    current: "address",
    imageUrl: address,
    heading: "Address",
    desc: "Edit addresses for order",
    section: section,
    setSection: setSection,
  };
  return (
    <div className="flex lg:flex-col lg:gap-2 gap-2 lg:mx-0 mx-2">
      <AccountNavigationButton data={data1} />
      <AccountNavigationButton data={data2} />
      <AccountNavigationButton data={data3} />
    </div>
  );
};
