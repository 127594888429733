import axios from 'axios'
import React from 'react'
import { API_URL } from '../../Url'
import { toast } from 'react-toastify';

const AddressCard = ({data,toggleModal,deleteAddress, defaultAdd, setDefaultAdd,setRender,render, setSelectedAdd, selectedAdd}) => {
  const token = localStorage.getItem("token");
  const handleDefault=()=>{
    axios.put(`${API_URL}/user-credential/make-default/${data._id}`,{},{
      headers: {
        Authorization: token,
      }
    }).then((res)=>{
      if(res.data.status===200){
        toast.success(res.data.message)
        setRender(!render)
        setDefaultAdd(data._id)
      }else{
        toast.error(res.data.message)
      }
    }).catch((err)=>{
      toast.error(err)
    })
  }

  return (
    <div className={`border-2 shadow-md cursor-pointer lg:w-[26vw]  w-full ${selectedAdd===data._id ? "border-red-300" : ""}`} onClick={()=>setSelectedAdd(data._id)}>
        <div className='px-4 pt-3  h-[25vh] overflow-y-hidden'>
            <div className='flex justify-between items-baseline'>
            <div className='font-bold text-lg mb-3'>{data.name}</div>
            <div className='mb-3 text-sm py-1 border-2 rounded-2xl px-2 bg-slate-100'>{data.addressType}</div>
            </div>
            <div className='text-sm'>{data.flatNumber},{data.landmark}, {data.district}</div>
            <div className='text-sm'>{data.locality}, {data.state}</div>
            <div className='text-sm'>India- {data.pincode}</div>  
            <div className='flex justify-between mt-4'>
            <div className='text-sm'>Phone- <span className='font-semibold'>{data.mobile}</span></div>
            { defaultAdd===data._id ?(

            <span className='text-sm text-gray-500 bg-slate-200 px-2 rounded-sm border-1'> Default</span>
            ):
            (
              <div className='text-s text-blue-600 cursor-pointer hover:text-blue-300' onClick={handleDefault}>Make Default</div>
            )
            }
            </div>   
        </div>
        <div className='flex mt-4'>
            <div id='edit' className='w-[50%] border-r-2 border-t-2 py-2  text-center cursor-pointer hover:bg-pink-50 text-[#D18B91]' onClick={()=>toggleModal(data)}>EDIT</div>
            <div className='w-[50%] border-l-2 border-t-2 py-2 text-center cursor-pointer hover:bg-pink-50 text-[#D18B91]' onClick={()=>deleteAddress(data._id)}>REMOVE</div>
        </div>
    </div>
  )
}

export default AddressCard