import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../Url";

function ForgotPassword() {
  const [verify, setVerify] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const hanldeForgot = async (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Email is required");
    }
    try {
      const res = await axios.post(
        `${API_URL}/user-credential/reset-password`,
        {
          email: email,
        }
      );
      if (res?.data?.statusCode === 200) {
        console.log("resdata======", res.data);
        toast.success(res?.data?.message);
        // setEmail("");
        setVerify(true);
      }
      if (res?.data?.statusCode !== 200) {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      //   toast.error(res.data.message);
      console.log("Error in forgot password", error);
    }
  };
  const handleVerifySubmit = async (e) => {
    e.preventDefault();
    if (!code || !password || !confirmPassword) {
      setErrorMessage("All fields are required");
    } else if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
      // Continue with form submission
    }
    try {
      const res = await axios.post(`${API_URL}/user-credential/verify-code`, {
        verificationCode: code,
        newPassword: password,
        email: email,
      });
      if (res?.data?.statusCode === 200) {
        console.log("resdata======", res.data);
        toast.success(res?.data?.message);
        setEmail("");
        setCode("");
        setPassword("");
        setConfirmPassword("");

        setVerify(true);
      }
      if (res?.data?.statusCode !== 200) {
        toast.error(res?.data?.message);
        // setEmail("");
        // setCode("");
        // setPassword("");
        // setConfirmPassword("");
      }
    } catch (error) {
      toast.error("Error in verify code");
      console.log("Error in verify code", error);
    }
  };
  return (
    <>
      {verify ? (
        <>
          <div className="flex justify-center items-center h-screen bg-gray-200">
            <div className="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
              <div className="flex justify-center items-center">
                <span className="text-gray-700 font-semibold text-2xl">
                  Verify Code
                </span>
              </div>
              <div className="mt-4">
                <label className="block">
                  {/* <span className="text-gray-700 text-sm px">Verify Code :</span> */}
                  <input
                    type="text"
                    className="form-input py-2 px-2 mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    placeholder="Verification code "
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label className="block">
                  {/* <span className="text-gray-700 text-sm">Email</span> */}
                  <input
                    type="password"
                    className="form-input py-2 px-2 mt-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label className="block">
                  {/* <span className="text-gray-700 text-sm">Email</span> */}
                  <input
                    type="password"
                    className="form-input py-2 px-2 mt-3 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                {errorMessage && (
                  <p className="text-red-500 mt-2">{errorMessage}</p>
                )}

                <div className="mt-6">
                  <button
                    onClick={handleVerifySubmit}
                    className="py-2 px-4 text-center bg-blue-600 rounded-md w-full text-white text-sm hover:bg-blue-500 focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center items-center h-screen bg-gray-200">
            <div className="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
              <div className="flex justify-center items-center">
                <span className="text-gray-700 font-semibold text-2xl">
                  Forgot Password
                </span>
              </div>
              <div className="mt-4">
                <label className="block">
                  {/* <span className="text-gray-700 text-sm">Email</span> */}
                  <input
                    type="email"
                    className="form-input py-2 px-2 mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                {errorMessage && (
                  <p className="text-red-500 mt-2">{errorMessage}</p>
                )}

                <div className="mt-6">
                  <button
                    onClick={hanldeForgot}
                    className="py-2 px-4 text-center bg-blue-600 rounded-md w-full text-white text-sm hover:bg-blue-500 focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ForgotPassword;
