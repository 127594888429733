import React, { useEffect, useState } from 'react'
import AddressCard from './atoms/AddressCard'
import AddAddressModal from './AddAddressModal'
import axios from 'axios';
import { API_URL } from '../Url';
import { toast } from 'react-toastify';

const AddressDetails = () => {
  const [active,setActive] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [address,setAddress] = useState([])
  const [render,setRender] = useState(false)
  const token = localStorage.getItem("token")
  const [defaultAdd,setDefaultAdd] = useState("")
  const [product, setProduct] = useState()
  const defaultData = {
    addressType: "Home",
    district: "",
    flatNumber: "",
    landmark: "",
    locality: "",
    mobile: "",
    name: "",
    pincode:"",
    state: "",
    userId: "",
    _id: ""
  }

  const resetProduct = ()=>{
    setProduct(defaultData)
  }

  useEffect(()=>{
    setActive(true)
    axios.get(`${API_URL}/user-credential/address/`,{
      headers: {
        Authorization: token,
      }
    }).then((res)=>{
      if(res.data.status===200){
        setAddress(res.data.addressDetails)
        setActive(false)
      }
    }).catch((err)=>{console.log(err)})

    
    axios.get(`${API_URL}/user-credential/default-address`,{
      headers: {
        Authorization: token,
      }
    }).then((res)=>{
      if(res.data.status===200){
        setDefaultAdd(res.data.addressDetails._id)
      }else{
        console.log("res from addr ",res)
      }
    }).catch((err)=>{console.log(err)})

  },[render,isModalOpen])
  
  const toggleModal = (data) => {
    setProduct(data)
    setIsModalOpen(!isModalOpen);
  };

  const deleteAddress = (id)=>{
    axios.delete(`${API_URL}/user-credential/delete-address/${id}`,{
      headers: {
        Authorization: token,
      }
    }).then((res)=>{
      if(res.data.status===200){
        toast.success(res.data.message)
        setRender(!render)
      }else{
        toast.error(res.data.message)
      }
    }).catch((err)=>{
      toast.error(err)
    })
  }

  return (
    <>
    {active?(
      <>
      <div className=" flex justify-center w-full">
       <div class="animate-spin inline-block size-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
        <span class="sr-only">Loading...</span>
      </div>
       </div>
      </>

    ):(
      <>
      <div className="lg:w-[55vw] bg-white py-4 lg:px-20 px-3 lg:my-0 my-2">
      <h1 className="text-leftfont-semibold text-3xl pb-3 mb-3 border-b-2 border-slate-400">Saved Addresses</h1>
      <div id='add' className='border-3 border-[#D18B91] p-1 text-[#D18B91] cursor-pointer text-center w-48 ms-auto rounded-lg hover:bg-pink-50' onClick={()=>toggleModal(defaultData)}>ADD NEW ADDRESS</div>
      <div className='flex flex-col gap-4'>
      {
        address?.map((data)=>{
          return(
            <div key={data._id}>
            <AddressCard data={data} toggleModal={toggleModal} deleteAddress={deleteAddress} defaultAdd={defaultAdd} setDefaultAdd={setDefaultAdd} render={render} setRender={setRender} selectedAdd={defaultAdd} setSelectedAdd={(e)=>{return}}/>
            </div>
          )
        })
      }
      </div>
      {isModalOpen && (
        <AddAddressModal product={product} setProduct={setProduct} resetProduct={resetProduct} onClose={toggleModal} /> // Render the modal if isModalOpen is true
      )}
    </div>
      </>
    )}

    </>
    
  )
}

export default AddressDetails