import React, { createContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import MyCart from "./Pages/MyCart";
import Home from "./Pages/Home";
import ProductDetails from "./Pages/ProductDetails";
import ProductList from "./Pages/ProductPage";
import AddProduct from "./Pages/AddProduct";
import AddProductItems from "./Pages/AddProductItems";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MenPage from "./Pages/MenPage";
import WomenPage from "./Pages/Women/WomenPage";
import WeddingPage from "./Pages/WeddingPage";
// import Cart from "./Pages/Cart";
import CartPage from "./Pages/CartPage";
import SearchPage from "./Pages/SearchPage";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import OccasionPage from "./Pages/occasion/OccasionPage";
import Wishlist from "./Pages/Wishlist/WhisList";
import Account from "./Pages/Account";
import SuccessPage from "./Pages/Payment/SuccessPage";
import FailurePage from "./Pages/Payment/FailurePage";
import Loader from "./Components/atoms/ProductLoader";
import ProtectedRoute from "./ProtectedRoute";
import PaymentConfirm from "./Pages/PaymentConfirm";
import TermsCondition from "./Pages/TermsCondition";
import { ReturnPolicy } from "./Pages/ReturnPolicy";
import { ShippingsPolicy } from "./Components/ShippingsPolicy";
import ForgotPassword from "./Pages/ForgotPassword";
import OrderSummery from "./Pages/OrderSummery";
import About from "./Pages/About";
// import DropDown from "./Pages/DropDown";

export default function ComponentRoutes() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return (
    <>
      <Routes>
        <Route path="/" exact={true} element={<Home />} />

        {/* <Route path="/login" exact={true} element={<Login />} />
        <Route path="/signup" exact={true} element={<Signup />} /> */}

        {/* <Route path="/loader" exact={true} element={<Loader />} /> */}
        <Route path="/payment-confirm" exact={true} element={<PaymentConfirm />} />
        <Route path="/men" exact={true} element={<MenPage />} />
        <Route path="/women" exact={true} element={<WomenPage />} />
        <Route path="/wedding" exact={true} element={<WeddingPage />} />
        <Route
          path="/products/:categoryId"
          exact={true}
          element={<ProductList />}
        />
        <Route
          path="/products/product/:id"
          exact={true}
          element={<ProductDetails />}
        />
       <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route path="/login" exact={true} element={<Login />} />
        <Route path="/signup" exact={true} element={<Signup />} />
        <Route path="/terms" element={<TermsCondition />} />
        <Route path="/return" element={<ReturnPolicy />} />
        <Route path="/shipping" element={<ShippingsPolicy />} />
        <Route path="/Forgot-password" element={<ForgotPassword />} />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <CartPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        />
    

        <Route path="/order-summery" element={<OrderSummery />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/occasion" element={<OccasionPage />} />
        <Route path="/addproducts" element={<AddProduct />} />
        <Route path="/addproductsitem" element={<AddProductItems />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/failure" element={<FailurePage />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <ToastContainer hideProgressBar autoClose={2000} />
    </>
  );
}
