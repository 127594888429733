import React from 'react'

export const ShippingsPolicy = () => {
  return (
    <div className='w-[1000px] pt-12 mx-auto'>
    <h1 className='pb-8 font-bold text-2xl'>Shipping Policy</h1>
    <p className='pb-6 '>Our Shipping Policy was last updated on 01.03.2024</p>
    <h2 className='font-bold pb-6 text-gray-600'>
        Definiation
    </h2>
    <ul className='text-gray-600 pb-6 list-disc ml-12'>
        <li>"Company" (referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer) refers to SQUBIX</li>
        <li>"Goods" refers to the items offered for sale on the Service.</li>
        <li>"Orders" means a request by You to purchase Goods from Us.</li>
        <li>"Service" refers to the Website.</li>
        <li>"Website" refers to SQUBIX accessible from squbix.com</li>
        <li>"You" means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
    </ul>

    <p className='text-gray-600 pb-4'> Thank you for visiting and shopping at squbix-ecomerce. The following terms and conditions constitute our Shipping Policy. 
    This Shipping was generated by TermsFeed Shipping Policy Generator. </p>

    <h2 className='font-bold pb-6 '> Domestic Shipping Policy </h2>
    <h2 className='font-bold pb-6 text-gray-600'> Shipment processing times </h2>
    <p className='text-gray-600 pb-6'>All Orders are processed within 2-3 business days. Orders are not shipped or delivered on weekends or holidays. </p>
    <p className='text-gray-600 pb-6'>If We are experiencing a high volume of orders, shipments may be delayed by a few days. 
    Please allow additional days in transit for delivery. If there will be a significant delay in shipment of Your Order, We will contact You via email or telephone.</p>

    <h2 className='font-bold pb-6 text-gray-600'>Shipping rates & delivery estimates</h2>
    <p className='pb-4'>Shipping charges for Your Orders will be calculated and displayed at checkout.</p>

    <ul className='text-gray-600 pb-6 list-disc ml-12'>
        <li className='pb-4'>
            <p className='text-gray-900 font-semibold'>Shipping method: FedEx Standard</p>
            <p>Shipment cost: Free</p>
            <p>Estimated delivery time: 3-5 business days</p>
        </li>
        <li className='pb-4'>
            <p className='text-gray-900 font-semibold'>Shipping method: FedEx Two Days</p>
            <p>Shipment cost: $12.95 USD</p>
            <p>Estimated delivery time: 2 business days</p>
        </li>
        <li className='pb-4'>
            <p className='text-gray-900 font-semibold'>Shipping method: FedEx Standard</p>
            <p>Shipment cost: $19.95 USD</p>
            <p>Estimated delivery time: 1-2 business days</p>
        </li>
    </ul>

    <p className='text-gray-600 pb-4'>Delivery delays can occasionally occur.</p>

    <h2 className='font-bold pb-6'>Shipment to P.O. boxes or APO/FPO addresses</h2>
    <p className='text-gray-600 pb-4'>SQUBIX ships to addresses within the INDIA, INDIA Territories, and APO/FPO/DPO addresses.</p>

    <h2 className='font-bold pb-6'>Shipment confirmation & Order tracking</h2>

    <p className='text-gray-600 pb-4'>You will receive a Shipment Confirmation Email Once Your Order has shipped containing your tracking number(s). 
    The tracking number will be active within 24 hours.</p>

    <h2 className='font-bold pb-6'>Customs, Duties and Taxes</h2>

    <p className='text-gray-600 pb-4'>SQUBIX is not responsible for any customs and taxes applied to Your Order. 
    All fees imposed during or after shipping are the responsibility of the customer (tariffs, taxes)</p>


    <h2 className='font-bold pb-6'>Damages</h2>
    <p className='text-gray-600 pb-4'>SQUBIX is not liable for any products damaged or lost during shipping. 
    If You received Your Order damaged, please contact the shipment carrier to file a claim.</p>
    <p className='text-gray-600 pb-4'>Please save all packaging materials and damaged goods before filing a claim.</p>

    <h2 className='font-bold pb-6'>Contact Us</h2>
    <p className='text-gray-600 pb-4'>If you have any questions about this Shipping Policy, you can contact Us:</p>
    <ul className='text-gray-600 pb-6 list-disc ml-12'>
        <li>By visiting this page on our website: 9876543211</li>
        <li>By sending us an email: bhubaneswar@squbix.com</li>
    </ul>



    </div>
  )
}
