import React, { useEffect, useState } from "react";
import MyOrderCard from "./atoms/MyOrderCard";
import axios from "axios";
import { API_URL } from "../Url";

const OrderDetails = () => {
  const [active, setActive] = useState(false);
  const [year, setYear] = useState("2024");
  const token = localStorage.getItem("token");
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    setActive(true);
    axios
      .get(`${API_URL}/order`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data);
          setActive(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  console.log("orders===>>>>>", orders);
  return (
    <>
      {active ? (
        <>
          <div className=" flex justify-center w-full">
            <div
              class="animate-spin inline-block size-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="lg:w-[55vw] w-full lg:my-0 my-2 bg-white p-4">
            <h1 className="text-center font-semibold text-xl lg:text-3xl">
              My Orders
            </h1>
            <p className="text-center text-slate-500 text-sm my-2">
              Track your open order & view the summary of your past order
            </p>
            <select
              value={year}
              className="border-2 p-1 mt-2"
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
            {orders.length ? (
              orders.map((val) => {
                return (
                  <div key={val._id}>
                    <div className="mt-4">Order ID: {val._id}</div>
                    <MyOrderCard data={val.productIds} />
                  </div>
                );
              })
            ) : (
              <div
                className="p-4 my-10 text-sm text-center text-yellow-700 rounded-lg bg-yellow-50"
                role="alert"
              >
                <span className="font-medium">Uh-oh! </span> We couldn't find
                any orders at the moment, but you can place one now to get
                started!
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetails;
