import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../Components/Breadcrumb';
import { ImCancelCircle } from "react-icons/im";

function Failed() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [navigate]);

  return (
    <>
      {/* <Breadcrumb /> */}
      <div className='h-[85vh] w-full flex justify-center items-center'>
        <div className='flex flex-col justify-center items-center'>
          <div className='h-40 w-40 rounded-full bg-red-500 flex justify-center items-center'>
            <ImCancelCircle className='h-20 w-24' color='white' alt="" />
          </div>
          <div className='text-3xl font-semibold text-red-500 mt-20'>Payment Failed</div>
          <div className='text-sm font-semibold text-black mt-5'>Redirecting in {countdown} seconds...</div>
        </div>
      </div>
    </>
  );
}

export default Failed;