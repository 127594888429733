import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../Url";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ProfileDetails = ({ data }) => {
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState(data.firstName);
  const [lastName, setLastName] = useState(data.lastName);
  const [email, setEmail] = useState(data.email);
  const [gender, setGender] = useState(data.gender);
  const [dob, setDob] = useState(data.dob);
  const phoneNumber = data.phoneNumber;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleSubmit = () => {
    const data = {
      firstName,
      lastName,
      email,
      gender,
      dob,
      phoneNumber,
    };
    axios
      .put(`${API_URL}/user-credential/update-user`, data, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.message);
          toast.success(res.data.message);
        } else {
          console.log(res.data);
          toast.error("Profile updation failed!!");
        }
      });
  };

  const handleChange = ()=>{
    if(edit){
      handleSubmit()
    }
    setEdit(!edit)
  }

  return (
    <div className="lg:w-[55vw]  lg:my-0 my-2 bg-white py-4 lg:px-20">
      <h1 className="text-center font-semibold text-xl lg:text-3xl">My Profile</h1>
      <p className="text-center text-slate-500 text-sm my-2 pb-3 border-b-2 border-slate-400">
        Fill your details for a personalized shopping experience
        </p>
      <form onSubmit={(e)=>e.preventDefault()} className="lg:pt-4 lg:mx-4 mx-3">
        <div className="lg:flex justify-between items-center mb-4 ">
          <label htmlFor="firstName" className="text-lg text-gray-500">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            className={`lg:w-[495px] w-full h-[52px] bg-slate-200 ${!edit && "text-gray-400"} px-3 rounded-lg text-base`}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={edit !== true}
          />
        </div>
        <div className="lg:flex justify-between items-center mb-4 ">
          <label htmlFor="lastName" className="text-lg text-gray-500">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            className={`w-full lg:w-[495px] h-[52px] bg-slate-200 ${!edit && "text-gray-400"} px-3 rounded-lg text-base`}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={edit !== true}
          />
        </div>
        <div className="lg:flex justify-between items-center mb-4 ">
          <label htmlFor="email" className="text-lg text-gray-500">
            Email ID
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className={`w-full lg:w-[495px] h-[52px] bg-slate-200 ${!edit && "text-gray-400"} px-3 rounded-lg text-base`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={edit !== true}
          />
        </div>
        <div className="lg:flex justify-between items-center mb-4 ">
          <label htmlFor="phoneNumber" className="text-lg text-gray-500">
            Phone Number
          </label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            className={`w-full lg:w-[495px] h-[52px] bg-slate-200 ${"text-gray-400"} px-3 rounded-lg text-base`}
            value={phoneNumber}
            disabled
          />
        </div>
        <div className="lg:flex justify-between items-center mb-16 ">
          <div className="flex justify-between lg:gap-[47px] items-center">
            <label htmlFor="dob" className="text-lg text-gray-500">
              Date of birth
            </label>
            <input
              type="date"
              name="phoneNumber"
              id="phoneNumber"
              className={`h-[52px] bg-slate-200 ${!edit && "text-gray-400"} px-3 rounded-lg text-base`}
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              disabled={edit !== true}
            />
          </div>
          <div className="flex lg:justify-start justify-between lg:mt-0 mt-4 items-center gap-4">
            <label htmlFor="gender" className="text-lg text-gray-500">
              Gender
            </label>
            <select
              name="gender"
              id="gender"
              className={`w-44 h-[52px] bg-slate-200 ${!edit && "text-gray-400"} ps-3 pe-10 rounded-lg text-base`}
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              disabled={edit !== true}
            >
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="pnts">Prefer not to say</option>
            </select>
          </div>
        </div>
        <button
          className="bg-black text-white h-10 px-4 text-lg rounded-md flex justify-center items-center mx-auto"
          onClick={handleChange}
        >
        {
          !edit ?
          "EDIT" :
          "SAVE DETAILS"
        }
        </button>
      </form>
    </div>
  );
};

export default ProfileDetails;
