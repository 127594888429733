import React from "react";
import banner1 from "../Assets/Images/WeddingPage/banner1.png";
import kurtabg from "../Assets/Images/WeddingPage/bridalbg.png";
import bridal from "../Assets/Images/WeddingPage/bridal.png";
import bridal1 from "../Assets/Images/WeddingPage/bridal1.png";
import banner2 from "../Assets/Images/WeddingPage/banner2.png";
import banner3 from "../Assets/Images/WeddingPage/banner3.png";
import sangeet from "../Assets/Images/WeddingPage/sangeet.png";
import haldi from "../Assets/Images/WeddingPage/haldi.png";
import reception from "../Assets/Images/WeddingPage/reception.png";
import bg from "../Assets/Images/WeddingPage/bg.png";
import banner4 from "../Assets/Images/WeddingPage/banner4.png";
import banner5 from "../Assets/Images/WeddingPage/banner5.png";
import banner6 from "../Assets/Images/WeddingPage/banner6.png";
import { useNavigate } from "react-router-dom";
import "../Css/ImageGallery.css"

function WeddingPage() {
  const navigate = useNavigate();
  const prodImg = [
    {
      id:0,
      img: "https://i.pinimg.com/236x/ca/27/6a/ca276a8984f445731beef2a57a59970c.jpg"
    },
    {
      id:1,
      img: "https://www.shaadidukaan.com/vogue/wp-content/uploads/2020/03/Bridal.jpg"
    },
    {
      id: 2,
      img : "https://www.shutterstock.com/image-photo/celebrity-fashion-model-indoor-bridal-600nw-2341231371.jpg"
    },
    {
      id: 3,
      img: "https://images.unsplash.com/photo-1610047614301-13c63f00c032?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YnJpZGFsfGVufDB8fDB8fHww"
    },
    {
      id: 4,
      img: "https://rukminim2.flixcart.com/image/850/1000/xif0q/poster/j/o/r/medium-premium-quality-beauty-parlour-bridal-mackup-wall-poster-original-imagmds4vk2g9yzp.jpeg?q=20&crop=false"
    }
  ]
  
  const handleProduct = () => {
    navigate("/products");
  };


  return (
    <>
    <div>
        <div>
          <img className="w-full" src={banner1} alt="..." />
        </div>
        <div>
          <img src={banner2} className="w-full mt-5" alt=".." />
        </div>
        <div>
          <img src={banner4} className="w-full mt-5 px-5" alt=".." />
        </div>
        <div>
          <img src={banner5} className="w-full mt-3 px-5" alt=".." />
        </div>
        
        <div className="md:p-5  2xl:w-[1440px] mx-auto">
        <div className="flex justify-between md:px-10 pb-3">
          <div className="">Best Sales</div>
          <div className="border-b-2 border-black cursor-pointer">
            VIEW ALL BEST PRODUCTS
          </div>
        </div>
        <div className="md:flex md:justify-evenly md:gap-9 px-5">
          <div>
            <div className="">
              <img className="h-full w-full object-contain" src={bridal} alt="" />
            </div>
            <div className="text-center font-bold pt-4">Sherwani</div>
            <div className="text-center  ">Rs. 26,800.00</div>
          </div>
          <div>
            <div className="">
              <img className="h-full w-full object-contain" src={bridal1} alt="" />
            </div>
            <div className="text-center font-bold pt-4">Jacket</div>
            <div className="text-center  ">Rs. 6,800.00</div>
          </div>
          <div className="">
            <div className="relative">
              <img className=" h-full w-full object-contain" src={bridal} alt="" />
            </div>
            <div className="text-center font-bold pt-4">Kurta set</div>
            <div className="text-center  ">Rs. 6,800.00</div>
          </div>
          <div>
            <div className="">
              <img className="h-full w-full object-contain" src={bridal1} alt="" />
            </div>
            <div className="text-center font-bold pt-4">Jacket</div>
            <div className="text-center  ">Rs. 6,800.00</div>
          </div>
        </div>
      </div>
      <div>
          <img src={banner6} className="w-full" alt=".." />
        </div>
        
    </div>
    </>
  );
}

export default WeddingPage;
