import React from 'react'
import Occasion from '../../Components/molecules/Occasion'
 import Breadcrumb from '../../Components/Breadcrumb'
import FooterHeader from '../../Components/FooterHeader'
import Filter from '../../Components/Filter'
import FooterBtn from '../../Components/FooterBtn'

function OccasionPage() {
  
  return (
    <>
      {/* <FooterBtn/> */}
      <div className="hidden md:block">
          <Breadcrumb />
      </div>
      <div className="flex w-full gap-5 px-3">
        <div className="lg:w-[22%] hidden md:block">
          <Filter/>
        </div>
        <div className="lg:w-[78%] w-full   ">
        <Occasion/>
        </div>
      </div>
      <FooterHeader/>

    </>
  )
}

export default OccasionPage