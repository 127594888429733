import React from "react";
import cartimg from '../Assets/Images/HomePage/cart.png'
import { IoBagHandleOutline } from "react-icons/io5";

const CartSvg = ({ count, color }) => {
  const Count = Number(count);
  const FS = Count < 10 ? 10 : Count < 100 ? 7.8 : 5;
//   const circleRadius = 6; // Define the radius value here

  // Determine path fill based on the color prop
  const pathFill = color || "white";

  return (
    <>
      <div className="relative">
        <IoBagHandleOutline className="w-full h-8" />

        {Count >= 0 && (
          <>
            <div
              className="absolute top-0 right-0 -mt-2 -mr-2 flex items-center justify-center w-5 h-5 bg-red-500 rounded-full"
            >
              <span className="text-white text-xs">{Count}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CartSvg;
