import React, { useContext, useEffect, useState } from "react";
import CartSvg from "../SVGs/CartSvg";
import profile from "../Assets/Images/HomePage/profile.png";
import heart from "../Assets/Images/HomePage/heart.png";
import search from "../Assets/Images/Header/search.png";
import { Link, useNavigate } from "react-router-dom";
// import g1 from "../Assets/Images/Header/g1.png";
import closeicon from "../Assets/Images/Header/closeicon.webp";
import { ProductContext } from "../context/ProductContext";
import useDebounce from "../utils/useDebounce";
import { toast } from "react-toastify";
import { RiMenu2Line } from "react-icons/ri";
import { FiHeart } from "react-icons/fi";
import { TiThMenu } from "react-icons/ti";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaMicrophone, FaSearch } from "react-icons/fa";
import { VscAccount } from "react-icons/vsc";
import { GoHeart } from "react-icons/go";
import logo from "../Assets/Images/Header/LOGO.png";
function Navbar() {
  const navigate = useNavigate();
  const [isListening, setIsListening] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    setSearchQuery,
    query,
    setQuery,
    ethnicCollection,
    westernCollection,
    filter,
    setOccasionIds,
    cartItemsLength,
    getCartItems,
    setCategoryName,
    categoryname,
  } = useContext(ProductContext);

  const handleClick = (categoryId) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/products/${categoryId}`);
  };

  let megaMenuTimeout; // Variable to store timeout reference

  const handleHome = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsOpen(false);
    navigate("/");
  };

  const handleMenuHover = (menu) => {
    clearTimeout(megaMenuTimeout); // Clear any existing timeout
    setShowMegaMenu(true);
    setActiveMenu(menu);
  };

  const handleMenuLeave = () => {
    // Use a timeout to delay hiding the MegaMenu
    megaMenuTimeout = setTimeout(() => {
      setShowMegaMenu(false);
      setActiveMenu(null);
    }, 200); // You can adjust the delay (300 milliseconds in this case)
  };

  const handleMen = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/men");
  };

  const handleWomen = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/women");
  };

  const handleWedding = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/wedding");
  };
  const hanldeSelect = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/products");
  };
  const handleCart = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/cart");
    setIsOpen(false);
  };
  const handleWishlist = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/wishlist");
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(query); // Set the search query here
    setIsOpen(false);
    navigate("/search");
  };
  console.log("Navbar Query===>", query);
  const occasionFilter = filter.find((item) => item.name === "Occasion");
  const handleAllProductClick = () => {
    setOccasionIds([]);
    navigate("/search");
  };
  const handleOcasionPage = (occasionId) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setOccasionIds([occasionId]);
    navigate("/occasion");
  };

  const handleMicClick = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setQuery(transcript);
      setSearchQuery(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (!isListening) {
      recognition.start();
    } else {
      recognition.stop();
    }
  };

  useEffect(() => {
    getCartItems();
  }, []);
  const handleSetCategory = (categoryname) => {
    localStorage.setItem("category", categoryname);
    // await setCategoryName(categoryname)
  };
  console.log("CAtegoryName", categoryname);
  // const[check,setCheck]=use
  return (
    <div className="w-full sticky top-0 bg-white  z-10 shadow-lg">
      <nav className="grid grid-cols-3 items-center py-4 md:pb-0 lg:shadow-none shadow-md ld:px-0 px-3">
        <div
          onClick={handleHome}
          className=" lg:mx-auto md:col-start-2 text-2xl font-semibold text-gray-800 text-left md:text-center"
        >
          <span className="cursor-pointer">
            <img src={logo} alt="logo" />
          </span>
        </div>

        <div className="hidden lg:flex md:items-center md:justify-end md:space-x-5 md:col-start-3 mr-7 ">
          <div className="relative">
            <form onSubmit={handleSearch}>
              <FaMicrophone
                onClick={handleMicClick}
                className="absolute cursor-pointer left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={20}
              />
              <input
                type="text"
                placeholder="Search for style"
                className="pl-10 pr-5 py-1.5 border border-gray-300 rounded-3xl tracking-wider bg-slate-100"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button type="submit">
                <FaSearch
                  className="absolute cursor-pointer right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={20}
                />
              </button>
            </form>
          </div>
          <Link to="/account">
            <VscAccount size={33} />
          </Link>
          <Link to="/wishlist">
            <GoHeart size={35} />
          </Link>
          <div className="cursor-pointer" onClick={handleCart}>
            <CartSvg count={cartItemsLength} />
          </div>
        </div>
        {/* Mobile View */}
        <div className="md:hidden col-start-3 flex justify-end  space-x-6 me-2">
          <Link to="/account" onClick={() => setIsOpen(false)}>
            <VscAccount size={25} />
          </Link>
          <Link to="/wishlist" onClick={() => setIsOpen(false)}>
            <GoHeart size={25} />
          </Link>
          {/* <div className="cursor-pointer" onClick={handleCart}>
            <CartSvg count={cartItemsLength} />
          </div> */}
          <div className="col-start-1 ">
            <button type="button" onClick={() => setIsOpen(!isOpen)}>
              <GiHamburgerMenu size={25} />
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="absolute top-0 left-0 w-full h-screen bg-gray-200 flex flex-col items-start p-6 space-y-8 mt-16 md:relative md:flex-row md:space-y-0 md:space-x-6 md:mt-0 md:w-auto md:h-auto md:bg-transparent">
            <div className="flex justify-between w-full items-center">
              <div>
                <form onSubmit={handleSearch} className="w-full relative">
                  <input
                    type="text"
                    placeholder="Search for style"
                    className="px-2 w-[77vw] py-2 border border-gray-300 rounded-md"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <button type="submit">
                    <FaSearch
                      className="absolute cursor-pointer right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                      size={20}
                    />
                  </button>
                </form>
              </div>
              <div>
                <div className="cursor-pointer" onClick={handleCart}>
                  <CartSvg count={cartItemsLength} />
                </div>
              </div>
            </div>

            <div className="mx-auto" onClick={() => setIsOpen(false)}>
              <Link
                to="/women"
                className="border-1 py-2 rounded px-2 border-black w-full text-xl"
              >
                WOMEN
              </Link>
            </div>
            <div className="mx-auto" onClick={() => setIsOpen(false)}>
              <div className="border-1 rounded py-2 px-2 border-black w-full text-xl">
                WEDDING
              </div>
            </div>
            <div className="mx-auto" onClick={() => setIsOpen(false)}>
              <Link
                to="/"
                className="border-1 rounded py-2 px-2 border-black w-full text-xl"
              >
                DESIGNER
              </Link>
            </div>
            <div className="mx-auto" onClick={() => setIsOpen(false)}>
              <Link
                to="/"
                className="border-1 rounded py-2 px-2 border-black w-full text-xl"
              >
                BESTSELLER
              </Link>
            </div>
            <div className="mx-auto" onClick={() => setIsOpen(false)}>
              <Link
                to="/"
                className="border-1 rounded py-2 px-2 border-black w-full text-xl"
              >
                SALE
              </Link>
            </div>
          </div>
        )}
      </nav>
      <div className=" hidden  md:flex lg:flex justify-center w-full gap-20 text-md pt-2 pb-3  relative">
        <div
          onMouseEnter={() => handleMenuHover("WOMEN")}
          onMouseLeave={handleMenuLeave}
          className="relative group"
        >
          <span
            onClick={() => {
              handleWomen();
              setShowMegaMenu(false);
            }}
            className="cursor-pointer"
          >
            WOMEN
          </span>
          {showMegaMenu && activeMenu === "WOMEN" && (
            <div className="fixed  top-24  left-0 right-0 bg-white p-3 border rounded-b-lg shadow-md z-90">
              <div className="flex justify-between px-12 py-3">
                {/* left Side */}
                <div className="flex gap-20">
                  {/* <div>
                    <div className="cursor-pointer">OCCASION</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      {occasionFilter &&
                        occasionFilter.value.map((el) => {
                          if (el) {
                            return (
                              <li
                                key={el._id}
                                className="cursor-pointer hover:text-black hover:text-black"
                                onClick={() => {
                                  handleOcasionPage(el._id);
                                  setShowMegaMenu(false);
                                }}
                              >
                                {el.value.toUpperCase()}
                              </li>
                            );
                          }
                        })}
                      <li
                        onClick={() => {
                          handleAllProductClick();
                          setShowMegaMenu(false);
                        }}
                        className="cursor-pointer hover:text-black "
                      >
                        ALL PRODUCTS
                      </li>
                    </ul>
                  </div> */}
                  <div>
                    <div>ETHNIC COLLECTION</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      {ethnicCollection.map((e) => {
                        return (
                          <>
                            <li
                              onClick={() => {
                                handleClick(e.id);
                                setShowMegaMenu(false);
                                handleSetCategory(e.categoryName);
                              }}
                              className="cursor-pointer hover:text-black"
                            >
                              {e.categoryName.toUpperCase()}
                            </li>
                          </>
                        );
                      })}
                      <li
                        onClick={() => {
                          handleAllProductClick();
                          setShowMegaMenu(false);
                        }}
                        className="cursor-pointer hover:text-black "
                      >
                        ALL PRODUCTS
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div>WESTERN COLLECTION</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      {westernCollection.map((e) => {
                        return (
                          <>
                            <li
                              onClick={() => {
                                handleClick(e.id);
                                setShowMegaMenu(false);
                              }}
                              className="cursor-pointer  hover:text-black"
                            >
                              {e.categoryName.toUpperCase()}
                            </li>
                          </>
                        );
                      })}
                      <li
                        onClick={() => {
                          handleAllProductClick();
                          setShowMegaMenu(false);
                        }}
                        className="cursor-pointer hover:text-black "
                      >
                        ALL PRODUCTS
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Right Side */}
                <div className="flex gap-2">
                  <div className="h-[50vh] w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702965625Manish_Malhotra_22.webp"
                      alt=""
                    />
                  </div>
                  <div className="h-[50vh]  w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702983802MM_22-02-20230398c.webp"
                      alt=""
                    />
                  </div>
                  <div className="h-[50vh] w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702965625Manish_Malhotra_22.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          // onMouseEnter={() => handleMenuHover("WEDDING")}
          onMouseLeave={handleMenuLeave}
          className="relative group"
        >
          <span className="cursor-pointer ">WEDDING</span>
          {showMegaMenu && activeMenu === "WEDDING" && (
            <div className="fixed  top-32  left-0 right-0 bg-white p-3 border rounded-lg shadow-md z-20">
              <div className="flex justify-between px-12 py-3">
                {/* left Side */}
                <div className="flex gap-5">
                  <div>
                    <div className=" cursor-pointer">COLLECTIONS</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li className=" cursor-pointer hover:text-black">
                        BRIDAL COUTURE 2023/24
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB BRIDAL COUTURE' 22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        FASHION SOIRÉE
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        MIJWAN '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB MENSWEAR '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        NOORANIYAT '21
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        RUHAANIYAT
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        TABAN
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        VIEW ALL
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>OCCASION</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>GROOM</li>
                      <li>SANGEET/COCKTAIL</li>
                      <li>FASHION SOIRÉE</li>
                      <li>MEHENDI</li>
                      <li>HALDI</li>
                      <li>RECEPTION</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>

                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                </div>
                {/* Right Side */}
                <div className="flex gap-2">
                  <div className="h-[50vh]  w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702983802MM_22-02-20230398c.webp"
                      alt=""
                    />
                  </div>
                  <div className="h-[50vh] w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702965625Manish_Malhotra_22.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          // onMouseEnter={() => handleMenuHover("DESIGNER")}
          onMouseLeave={handleMenuLeave}
          className="relative group"
        >
          <span className="cursor-pointer ">DESIGNER</span>
          {showMegaMenu && activeMenu === "DESIGNER" && (
            <div className="fixed  top-32  left-0 right-0 bg-white p-3 border rounded-lg shadow-md z-20">
              <div className="flex justify-between px-12 py-3">
                {/* left Side */}
                <div className="flex gap-5">
                  <div>
                    <div className=" cursor-pointer">COLLECTIONS</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li className=" cursor-pointer hover:text-black">
                        BRIDAL COUTURE 2023/24
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB BRIDAL COUTURE' 22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        FASHION SOIRÉE
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        MIJWAN '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB MENSWEAR '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        NOORANIYAT '21
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        RUHAANIYAT
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        TABAN
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        VIEW ALL
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>OCCASION</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>GROOM</li>
                      <li>SANGEET/COCKTAIL</li>
                      <li>FASHION SOIRÉE</li>
                      <li>MEHENDI</li>
                      <li>HALDI</li>
                      <li>RECEPTION</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>

                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                </div>
                {/* Right Side */}
                <div className="flex gap-2">
                  <div className="h-[50vh]  w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702983802MM_22-02-20230398c.webp"
                      alt=""
                    />
                  </div>
                  <div className="h-[50vh] w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702965625Manish_Malhotra_22.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          // onMouseEnter={() => handleMenuHover("BESTSELLER")}
          onMouseLeave={handleMenuLeave}
          className="relative group"
        >
          <span className="cursor-pointer ">BESTSELLER</span>
          {showMegaMenu && activeMenu === "BESTSELLER" && (
            <div className="fixed  top-32  left-0 right-0 bg-white p-3 border rounded-lg shadow-md z-20">
              <div className="flex justify-between px-12 py-3">
                {/* left Side */}
                <div className="flex gap-5">
                  <div>
                    <div className=" cursor-pointer">COLLECTIONS</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li className=" cursor-pointer hover:text-black">
                        BRIDAL COUTURE 2023/24
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB BRIDAL COUTURE' 22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        FASHION SOIRÉE
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        MIJWAN '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB MENSWEAR '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        NOORANIYAT '21
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        RUHAANIYAT
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        TABAN
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        VIEW ALL
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>OCCASION</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>GROOM</li>
                      <li>SANGEET/COCKTAIL</li>
                      <li>FASHION SOIRÉE</li>
                      <li>MEHENDI</li>
                      <li>HALDI</li>
                      <li>RECEPTION</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>

                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                </div>
                {/* Right Side */}
                <div className="flex gap-2">
                  <div className="h-[50vh]  w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702983802MM_22-02-20230398c.webp"
                      alt=""
                    />
                  </div>
                  <div className="h-[50vh] w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702965625Manish_Malhotra_22.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          // onMouseEnter={() => handleMenuHover("SALE")}
          onMouseLeave={handleMenuLeave}
          className="relative group"
        >
          <span className="cursor-pointer ">FESTIVE</span>
          {showMegaMenu && activeMenu === "SALE" && (
            <div className="fixed  top-32  left-0 right-0 bg-white p-3 border rounded-lg shadow-md z-20">
              <div className="flex justify-between px-12 py-3">
                {/* left Side */}
                <div className="flex gap-5">
                  <div>
                    <div className=" cursor-pointer">COLLECTIONS</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li className=" cursor-pointer hover:text-black">
                        BRIDAL COUTURE 2023/24
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB BRIDAL COUTURE' 22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        FASHION SOIRÉE
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        MIJWAN '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        KHAAB MENSWEAR '22
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        NOORANIYAT '21
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        RUHAANIYAT
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        TABAN
                      </li>
                      <li className=" cursor-pointer hover:text-black">
                        VIEW ALL
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>OCCASION</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>GROOM</li>
                      <li>SANGEET/COCKTAIL</li>
                      <li>FASHION SOIRÉE</li>
                      <li>MEHENDI</li>
                      <li>HALDI</li>
                      <li>RECEPTION</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>

                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                  <div>
                    <div>CATEGORIES</div>
                    <ul className="py-3 space-y-3  text-sm text-zinc-600  ">
                      <li>SHERWANI SETS </li>
                      <li>KURTA SETS</li>
                      <li>BUNDI SETS</li>
                      <li>JACKET SETS</li>
                      <li>SHIRTS</li>
                      <li>BLAZER SETS</li>
                      <li>VIEW ALL</li>
                    </ul>
                  </div>
                </div>
                {/* Right Side */}
                <div className="flex gap-2">
                  <div className="h-[50vh]  w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702983802MM_22-02-20230398c.webp"
                      alt=""
                    />
                  </div>
                  <div className="h-[50vh] w-[15vw]">
                    <img
                      className="h-full w-full object-contain"
                      src="https://cdn.pixelbin.io/v2/black-bread-289bfa/81ub5U/original/manish-category/1702965625Manish_Malhotra_22.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          // onMouseEnter={() => handleMenuHover("SALE")}
          // onMouseLeave={handleMenuLeave}
          className="relative group"
        >
          <span className="cursor-pointer " onClick={() => navigate("/about")}>
            ABOUT
          </span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
