import React from "react";
import girlweek from "../Assets/Images/WomenPage/img.jpg";
import women2 from "../Assets/Images/WomenPage/wpmen2.jpg";
import { useNavigate } from "react-router-dom";

function WeekOfProduct() {
  const navigate = useNavigate();
  return (
    <div className="lg:h-[75vh] h-full bg-red-50 w-full lg:flex gap-3">
      <div className="lg:w-[50%] relative">
        <img src={girlweek} alt="..." className="h-full w-full object-cover" />
        <div className="bg-black/60 absolute bottom-0 left-0 lg:p-16 p-3 px-5 lg:px-20">
          <div className="text-white font-medium text-xl">
            Top Product Of the Week
          </div>
          <div
            className="text-white border px-5 py-2 inline-block rounded-md mt-4 cursor-pointer"
            onClick={() => navigate("/search")}
          >
            EXPLORE ITEMS
          </div>
        </div>
      </div>
      <div className="lg:w-[50%] lg:h-[75vh] h-full lg:mt-0 mt-4 ">
        <div className="relative">
          <img src={women2} alt="" className="w-full h-[37vh] object-cover" />
          <div className="absolute bottom-0 left-0 px-3 lg:px-10 py-5">
            <div className="text-white font-medium text-xl">
              Top Product Of the Week
            </div>
            <div
              className="text-white border px-5 py-2 inline-block rounded-md mt-4 cursor-pointer"
              onClick={() => navigate("/search")}
            >
              EXPLORE ITEMS
            </div>
          </div>
        </div>
        <div className="bg-black/50 lg:block flex justify-center lg:h-[36.3vh] p-6 mt-3 ">
          <div>
            <div className="text-white font-medium text-xl">
              Top Product Of the Week
            </div>
            <div className="lg:block flex justify-center">

            <div
              className="text-white border px-5 py-2 inline-block rounded-md mt-4 cursor-pointer"
              onClick={() => navigate("/search")}
            >
              EXPLORE ITEMS
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeekOfProduct;
