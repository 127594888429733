import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../Url";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
function Filter({ filterset, categoryId }) {
  const [filter, setFilter] = useState([]);

  const [openFilters, setOpenFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const getallFilter = async () => {
    try {
      const res = await axios.get(`${API_URL}/products/get-all-variations`);
      setFilter(res?.data);
      setOpenFilters(new Array(res?.data.length).fill(true));
    } catch (error) {
      console.log("Error in getting all Filter");
    }
  };

  useEffect(() => {
    getallFilter();
    setSelectedFilters([]); // Reset the selected filters when the category changes
  }, [categoryId]);

  useEffect(() => {
    filterset(selectedFilters);
  }, [selectedFilters]);

  const handleCheckboxChange = (event, id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (event.target.checked) {
      setSelectedFilters([...selectedFilters, id]);
    } else {
      setSelectedFilters(selectedFilters.filter((filterId) => filterId !== id));
    }
  };
  const handleColorBoxClick = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((filterId) => filterId !== id));
    } else {
      setSelectedFilters([...selectedFilters, id]);
    }
  };

  // console.log("selectedPrice====>>>>>>", selectedPrice);
  return (
    <div className="w-full ">
      {filter
        .filter((item) => item.name !== "Price") // filter out the price filter
        .map((item, index) => (
          <div key={item.name} className="my-2 p-3 border rounded-md">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => {
                const newOpenFilters = [...openFilters];
                newOpenFilters[index] = !newOpenFilters[index];
                setOpenFilters(newOpenFilters);
              }}
            >
              <div className=" text-lg cursor-pointer">{item.name}</div>
              <div>
                {" "}
                {openFilters[index] ? (
                  <FaChevronDown color="gray" />
                ) : (
                  <FaChevronRight color="gray" />
                )}
              </div>
            </div>

            {openFilters[index] &&
              item.value.map((val) => (
                <div
                  key={val._id}
                  className="ml-4 my-2 flex items-center space-y-1 "
                >
                  {item.name === "Color" ? (
                    <div
                    style={{ backgroundColor: val.value }}
                    className={`w-5 h-5 mr-2 rounded-full cursor-pointer ${selectedFilters.includes(val._id) ? 'border-2 border-blue-500' : ''}`}
                    onClick={() => handleColorBoxClick(val._id)}
                  />
                  ) : (
                    <input
                      type="checkbox"
                      id={val._id}
                      name={val.value}
                      value={val.value}
                      className="mr-2 h-4 w-4"
                      checked={selectedFilters.includes(val._id)}
                      onChange={(event) => handleCheckboxChange(event, val._id)}
                    />
                  )}
                  <label htmlFor={val._id} className="text-sm cursor-pointer">
                    {val.value}
                  </label>
                </div>
              ))}
            {selectedFilters.some((id) =>
              item.value.map((val) => val._id).includes(id)
            ) && (
              <div className="flex flex-row-reverse">
                <button
                  className="mt-2  text-black py-1 px-2 rounded "
                  onClick={() =>
                    setSelectedFilters(
                      selectedFilters.filter((id) =>
                        item.value.every((val) => val._id !== id)
                      )
                    )
                  }
                >
                  Clear
                </button>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default Filter;
