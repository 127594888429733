import React from 'react'

export const ReturnPolicy = () => {
  return (
    <div className='w-[1000px] mx-auto pt-12'>
        <h1 className='pb-8 font-bold text-2xl'>RETURN POLICY</h1>
        <p className='pb-8 '>Last updated January 01, 2024</p>
        <p className='text-gray-600 pb-6'>Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely 
        completely satisfied with your purchase for any reason, you may return it to us for a full refund or an exchange.
        Please see below for more information on our return policy.</p>

        <h2 className='font-bold pb-6'> RETURN </h2>
        <p className='text-gray-600 pb-6'>All return must be poatmarked within five(5) days of the purchase date. All returned items
         must be in new and unused condition, with all original tags and labels attached. </p>

        <h2 className='font-bold pb-6'>
        RETURN PROCESS
        </h2>
        <p className='text-gray-600 pb-6'>To return an item, please email customer service at <a className='text-blue-800' href='bhubaneswar@squbix.com'>bhubaneswar@squbix.com</a> to obtain a Return Merchandise Authorisation (RMA) number. 
        After receiving a RMA number, 
        place the item securely in its original packaging, and mall your return to the following address:</p>
        <div className='text-gray-600 pb-6'>
        <p>Squbix warehouse</p>
        <p>Attn: ReturnPolicy</p>
        <p>RMA #256156156154</p>
        <p>bhubaneswar@squbix.com</p>
        <p>Khorda, bhubaneswar, 759024</p>
        <p>India</p>
        </div>
        <p className='text-gray-600 pb-6'>
        Please note, you will be responsible for all return shipping charges. We strongly recommend that you use a 
        trackable method to mail your return. You may also use the prepaid shipping label enclosed with your package. 
        If you use the prepaid shipping label, 49 will be deducted from your return to cover shipping charges.
        </p>

        <h2 className='font-bold pb-6'>REFUNDS</h2>
        <p className='text-gray-600 pb-6'>After receiving your return and inspecting the condition of your item, we will process your return or 
        exchange. Please allow at least five (5) days from the receipt of your item to process your return or exchange. 
        We will notify you by emall when your retum has been processed.</p>

        <h2 className='font-bold pb-6'>
        EXCEPTIONS
        </h2>
        <p className='text-gray-600 pb-2'>For defective or damaged products, please contact us at the contact details below to arrange a refund or exchange</p>

        <p className='text-gray-600 font-semibold pb-1'>Please Note:</p>
        <ul className='text-gray-600 pb-6 list-disc ml-12'>
            <li>A 2% restocking fee will be charged for all returns.</li>
            <li>No used items should be returned</li>
            <li>No torn items should be returned</li>
        </ul>
        <h2 className='font-bold pb-6 '>QUESTIONS</h2>
        <p className='pb-2 text-gray-600'>If you have any questions concerning our return policy, please contact us at:</p>
        <p>9876000000</p>
        <p className='text-gray-600 pb-6'><a className='text-blue-800' href='bhubaneswar@squbix.com'>bhubaneswar@squbix.com</a></p>
    </div>
  )
}
