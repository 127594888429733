import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { ProductContext } from "../context/ProductContext";
import heartfilled from "../Assets/Images/ProductCard/heartfilled.svg";
import heart from "../Assets/Images/ProductCard/heart.svg";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
function LikedProduct({ recommendedProducts, handleWhilistProduct }) {
  const { handleDeleteFromWishlist, wishlistproducts, getWishList } =
    useContext(ProductContext);
  const [wishlistUpdated, setWishlistUpdated] = useState(false);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,

    nextArrow: <GrNext size={30} color="black" className="px-5" />,
    prevArrow: <GrPrevious size={10} color="black" className="px-5" />,
  };

  const handleWhilistProductClick = async (id) => {
    if (wishlistproducts.some((product) => product._id === id)) {
      handleDeleteFromWishlist(id);
    } else {
      handleWhilistProduct(id);
    }
    setWishlistUpdated((prevState) => !prevState); // Toggle wishlistUpdated state
  };

  const handleClickProduct = (id) => {
    navigate(`/products/product/${id}`);
  };
  useEffect(() => {
    getWishList();
    setWishlistUpdated(false); // Reset wishlistUpdated state after fetching wishlist
  }, [wishlistUpdated]);

  return (
    <div className="py-10">
      <div className="text-center  text-2xl ">You may also like </div>
      <div className="hidden lg:flex justify-center py-5 ">
        <div className="lg:w-[80vw]  p-10  w-full lg:p-7">
          <Slider {...settings}>
            {recommendedProducts.length > 0 ? (
              recommendedProducts.map((e) => {
                const imageUrl =
                  e.productImage?.image?.length > 0
                    ? e.productImage.image[0]
                    : "https://www.pdquipment.com/wp-content/uploads/dummy-product-image.jpg";

                return (
                  <div key={e._id}>
                    <div className="w-[20vw] ">
                      <div className="relative lg:h-[60vh] h-[20vh] lg:w-[20vw] bg-neutral-600 md:relative">
                        <img
                          className="h-full w-full cursor-pointer object-fill"
                          src={imageUrl}
                          alt="prod1"
                          onClick={() => handleClickProduct(e._id)}
                        />
                        <img
                          onClick={() => handleWhilistProductClick(e._id)}
                          className="imgab2 absolute md:absolute md:right-5 top-2 right-2 md:top-5 cursor-pointer"
                          src={
                            wishlistproducts?.some(
                              (product) => product._id === e._id
                            )
                              ? heartfilled
                              : heart
                          } // Use the filled heart if the product is in the wishlist
                          style={{ height: "30px" }}
                          alt="..."
                        />
                      </div>
                      <div className="flex w-full gap-2 md:px-0 md:py-1 overflow-hidden font-semibold">
                        {e.name}
                      </div>
                      <div className="flex md:gap-2 gap-2">
                        <div className="font-semibold md:px-0">
                          {" "}
                          Rs.{" "}
                          {e.details && e.details.length > 0
                            ? e.details[0].price
                            : 0}
                        </div>
                        <div className="line-through text-gray-500"> ₹1500</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No product found</div>
            )}
          </Slider>
        </div>
      </div>
      <div className="block lg:hidden">
        <div className="lg:w-[80vw]  p-5  w-full lg:p-7">
          <Swiper
            spaceBetween={50}
            slidesPerView={1.5}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {recommendedProducts.length > 0 ? (
              recommendedProducts.map((e) => {
                const imageUrl =
                  e.productImage?.image?.length > 0
                    ? e.productImage.image[0]
                    : "https://www.pdquipment.com/wp-content/uploads/dummy-product-image.jpg";

                return (
                  <SwiperSlide key={e._id}>
                    <div className="w-full ">
                      <div className="relative  h-[40vh]  w-[63vw] bg-neutral-600 md:relative">
                        <img
                          className="h-full w-full cursor-pointer object-fill"
                          src={imageUrl}
                          alt="prod1"
                          onClick={() => handleClickProduct(e._id)}
                        />
                        <img
                          onClick={() => handleWhilistProductClick(e._id)}
                          className="imgab2 absolute md:absolute md:right-5 top-2 right-2 md:top-5 cursor-pointer"
                          src={
                            wishlistproducts?.some(
                              (product) => product._id === e._id
                            )
                              ? heartfilled
                              : heart
                          } // Use the filled heart if the product is in the wishlist
                          style={{ height: "30px" }}
                          alt="..."
                        />
                      </div>
                      <div className="flex w-full gap-2 md:px-0 md:py-1 overflow-hidden font-semibold">
                        {e.name}
                      </div>
                      <div className="flex md:gap-2 gap-2">
                        <div className="font-semibold md:px-0">
                          {" "}
                          Rs.{" "}
                          {e.details && e.details.length > 0
                            ? e.details[0].price
                            : 0}
                        </div>
                        <div className="line-through text-gray-500"> ₹1500</div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            ) : (
              <div>No product found</div>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default LikedProduct;
