import React, { useState, useEffect, useContext } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import { toast } from "react-toastify";
import Breadcrumb from "../Components/Breadcrumb";
import { v4 as uuidv4 } from "uuid";

// import useEffect fro,
// import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
// import { useNavigate } from "react-router-dom";
import closed from "../Assets/Images/ProductDetails/close-icon.png";
import { API_URL } from "../Url";
import { ProductContext } from "../context/ProductContext";
import VariationDropDown from "./VariationDropDown";

function AddProduct() {
  const [selectedSubVariationIds, setSelectedSubVariationIds] = useState([]);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  // const [variations, setVariations] = useState([]);
  // const [selectedVariation, setSelectedVariation] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  // const [allproduct, setAllProduct] = useState([]);
  const { allproduct, handleAddProduct, filter, setProductId, productId } =
    useContext(ProductContext);
  // const arr = filter;

  // const [productId, setProductId] = useState("");
  const [loader, setLoader] = useState(false);
  // console.log(">>>>", selectedValue);

  const [options, setOptions] = useState([
    { sizeId: uuidv4(), size: "default", quantity: "", price: "" },
  ]);

  const dropdownOptions = ["m", "xl", "l", "s"];
  const resetForm = () => {
    setProductName("");
    setProductDescription("");
    setProductId("");
    setImageFiles([]);
    setVideoFiles([]);
    setLoader(false);
    setOptions([{ uuid: uuidv4(), size: "default", quantity: "", price: "" }]);
    setSelectedSubVariationIds([]); // Resetting selectedSubVariationIds
    // setSelectedVariation(''); // Resetting selectedVariation
    // setSelectedValue(''); // Resetting selectedValue
  };
  // console.log(">>>>+++",selectedSubVariationIds)
  const handleAddProductSubmit = async (event) => {
    event.preventDefault();

    const details = options
      .filter(
        (option) => option.size !== "default" && option.quantity && option.price
      )
      .map(({ sizeId, size, quantity, price }) => ({
        sizeId,
        size,
        qty: parseInt(quantity, 10),
        price: parseInt(price, 10),
      }));
    console.log("Details====>>>>>:", details);

    if (
      !productName ||
      !productDescription ||
      !imageFiles.length ||
      !details.length ||
      !selectedSubVariationIds
    ) {
      toast.error("All fields are required");
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < imageFiles.length; i++) {
      formData.append("images", imageFiles[i]);
    }

    if (videoFiles.length > 0) {
      for (let j = 0; j < videoFiles.length; j++) {
        formData.append("videos", videoFiles[j]);
      }
    }
    formData.append("name", productName);
    formData.append("description", productDescription);
    formData.append("details", JSON.stringify(details));

    formData.append("variationOptionId", selectedSubVariationIds);

    // console.table("FormData contents:>>>>", formData);

    try {
      setLoader(true);
      console.log("ids==>>>", selectedSubVariationIds);
      await handleAddProduct(formData);

      setLoader(false);
      resetForm();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Error:", error);
    }
    setLoader(false);
  };

  const handleFileChange = (event, fileType) => {
    const files = Array.from(event.target.files);
    if (fileType === "image") {
      setImageFiles([...imageFiles, ...files]);
    } else if (fileType === "video") {
      setVideoFiles([...videoFiles, ...files]);
    }
  };

  const removeFile = (index, fileType) => {
    if (fileType === "image") {
      const newImageFiles = [...imageFiles];
      newImageFiles.splice(index, 1);
      setImageFiles(newImageFiles);
    } else if (fileType === "video") {
      const newVideoFiles = [...videoFiles];
      newVideoFiles.splice(index, 1);
      setVideoFiles(newVideoFiles);
    }
  };

  const handleSizeChange = (index, newSize) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = {
        ...newOptions[index],
        sizeId: uuidv4(), // Generate new UUID
        size: newSize,
      };
      return newOptions;
    });
  };

  const handleQuantityChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].quantity = value;
    setOptions(updatedOptions);
  };

  const handlePriceChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].price = value;
    setOptions(updatedOptions);
  };

  const handleAddDropdown = () => {
    setOptions([
      ...options,
      { uuid: uuidv4(), size: "default", quantity: "", price: "" },
    ]);
  };

  const handleRemoveDropdown = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleProductChange = (event) => {
    const selectedProductId = event.target.value;
    setProductId(selectedProductId);
  };

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // console.log("variantion===>>>", selectedVariation);
  console.log("Options===>>>", options);
  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { title: "Home", path: "/" },
          { title: "Addproducts", path: "/addproducts" },
          // { title: "Shoes", path: "/products/shoes" },
        ]}
      />
      <div className=" bg-gray-200 text-[#9f2f2f] text-lg md:text-3xl py-3 md:px-16 px-5 font-bold  ">
        ADD PRODUCT
      </div>
      <div className="flex w-full  justify-center  bg-gray-100 ">
        <div className="md:w-[80vw] w-full px-5 md:px-12 py-4  rounded-md ">
          <form>
            {/* Product Dropdown */}
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col space-y-2">
                <label className="font-semibold">
                  Name <span className="text-red-800">*</span>
                </label>
                <input
                  type="text"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label className="font-semibold">
                  Description <span className="text-red-800">*</span>
                </label>
                <textarea
                  rows={4}
                  type="text"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="mt-4 text-lg">
              <label className="block text-gray-700 text-lg font-bold mb-2">
                Select Category : <span className="text-red-800">*</span>
              </label>
              <select
                required
                className="w-full p-2 border rounded-md"
                value={productId}
                onChange={handleProductChange}
              
              >
                <option value="">Select Category</option>
                {allproduct.map((product) => (
                  <option key={product._id} value={product._id}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="container md:mx-auto my-8">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="my-4 p-4 border border-gray-300 rounded "
                >
                  <label
                    htmlFor={`size-${index}`}
                    className="mr-2 font-semibold"
                  >
                    Select Size : <span className="text-red-800">*</span>
                  </label>
                  <select
                    id={`size-${index}`}
                    value={option.size}
                    onChange={(e) => handleSizeChange(index, e.target.value)}
                    className="border border-gray-300 rounded p-2 md:mr-4"
                  >
                    <option value="default">Select Size</option>
                    {dropdownOptions
                      .filter(
                        (size) =>
                          !options
                            .slice(0, index)
                            .some((opt) => opt.size === size)
                      )
                      .map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                  </select>

                  {option.size !== "default" && (
                    <div className="my-4 md:flex ">
                      <div>
                        <label
                          htmlFor={`quantity-${index}`}
                          className="md:mr-2 mr-3 font-semibold"
                        >
                          Quantity :
                        </label>
                        <input
                          type="text"
                          id={`quantity-${index}`}
                          name={`quantity-${index}`}
                          value={option.quantity}
                          onChange={(e) =>
                            handleQuantityChange(index, e.target.value)
                          }
                          className="border border-gray-300 rounded p-2 mr-4 md:my-0 my-3"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor={`price-${index}`}
                          className="md:mr-2 mr-10 font-semibold"
                        >
                          Price :
                        </label>
                        <input
                          type="text"
                          id={`price-${index}`}
                          name={`price-${index}`}
                          value={option.price}
                          onChange={(e) =>
                            handlePriceChange(index, e.target.value)
                          }
                          className="border border-gray-300 rounded p-2"
                        />
                      </div>
                    </div>
                  )}

                  {index !== 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveDropdown(index)}
                      className="bg-red-500 text-white px-2 py-1 md:mx-0 mx-5 rounded mt-2"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}

              <button
                type="button"
                onClick={handleAddDropdown}
                disabled={options.length === dropdownOptions.length}
                className="bg-green-700 text-white px-4 py-2 rounded"
              >
                Add Size
              </button>
            </div>

            {/* variantions */}
            {/* Variation Dropdown */}
            <VariationDropDown
              selectedSubVariationIds={selectedSubVariationIds}
              setSelectedSubVariationIds={setSelectedSubVariationIds}
            />

            {/* Value Dropdown */}
            {/* {selectedVariation && (
              <div className="mt-4 text-lg">
                <label className="block text-gray-700 text-lg font-bold mb-2">
                  Select Value : <span className="text-red-800">*</span>
                </label>
                <select
                  required
                  className="w-full p-2 border rounded-md"
                  value={selectedValue}
                  onChange={handleValueChange}
                >
                  <option value="">Select Value</option>
                  {filter
                    .find((variation) => variation.name === selectedVariation)
                    ?.value.map((value) => (
                      <option key={value._id} value={value.value}>
                        {value.value}
                      </option>
                    ))}
                </select>
              </div>
            )} */}

            {/* // */}

            {/* file upload */}
            {/* image */}
            <div className="w-full mt-4">
              <div className="block text-gray-700 text-lg font-bold mb-2">
                Product Image : <span className="text-red-800">*</span>
              </div>
              <label
                htmlFor="image-file" // Updated id here
                className="w-full h-64  border-gray-300 rounded-lg cursor-pointer bg-gray-50  "
              >
                <div className="flex flex-col border-2 border-dashed items-center justify-center pt-5 pb-6">
                  {/* ... (SVG icon) */}
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    {/* Your SVG path here */}
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    Supported formats: Images (PNG, JPG, GIF), Videos (MP4)
                  </p>
                </div>
                <input
                  id="image-file" // Updated id here
                  type="file"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, "image")}
                  multiple
                  accept="image/*"
                />
              </label>
            </div>

            <div className="flex flex-wrap py-3">
              {imageFiles.map((file, index) => (
                <div key={index} className="relative mr-2 mb-2">
                  <img
                    className="w-32 h-32 object-cover rounded-md"
                    src={URL.createObjectURL(file)}
                    alt={`Image ${index + 1}`}
                  />
                  <button
                    className="absolute top-0 right-0  text-white p-1 rounded-full"
                    onClick={() => removeFile(index, "image")}
                  >
                    <img className="h-5" src={closed} alt="" />
                  </button>
                </div>
              ))}
            </div>
            {/* video */}
            <div className="w-full mt-4">
              <div className="block text-gray-700 text-lg font-bold mb-2">
                Product Video : <span className="text-red-800">*</span>
              </div>
              <label
                htmlFor="video-file" // Updated id here
                className="w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-gray-50  "
              >
                <div className="flex flex-col border-2 border-dashed items-center justify-center pt-5 pb-6">
                  {/* ... (SVG icon) */}
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    {/* Your SVG path here */}
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    Supported formats: Images (PNG, JPG, GIF), Videos (MP4)
                  </p>
                </div>
                <input
                  id="video-file" // Updated id here
                  type="file"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, "video")}
                  // multiple
                  accept="video/*"
                />
              </label>
            </div>

            <div className="flex flex-wrap my-3">
              {videoFiles.map((file, index) => (
                <div key={index} className="relative mr-2 mb-2">
                  <video className="w-32 h-32 object-cover rounded-md" controls>
                    <source src={URL.createObjectURL(file)} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button
                    className="absolute top-0 right-0  text-white p-1 rounded-full"
                    onClick={() => removeFile(index, "video")}
                  >
                    <img className="h-5" src={closed} alt="" />
                  </button>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="mt-6 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-700"
                onClick={handleAddProductSubmit}
              >
                {loader ? "Uploading..." : "Add Product"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
