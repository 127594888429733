import React, { useContext, useEffect, useState } from "react";
import "../Css/SignupLogin.css";
import welcome from "../Assets/Images/SignupLogin/welcome.png";
import google from "../Assets/Images/SignupLogin/devicon_google.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Url";
// import { toast } from "react-toastify";
import { ProductContext } from "../context/ProductContext";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
function Login() {
  const { getCartItems } = useContext(ProductContext);
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(true);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Inavlid Credential!!");
  useEffect(() => {
    localStorage.removeItem("token");
  }, []);
  const handleLogin = (e) => {
    e.preventDefault();
    setIsError(false);

    if ((!email && !phoneNumber) || !password) {
      setIsError(true);
      setErrorMsg("Please fill all the fields!");
      return;
    }
    let data = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email) {
      if (regex.test(email)) {
        data = { email: email };
      } else {
        setIsError(true);
        setErrorMsg("Email is not valid");
        return;
      }
    }
    if (phoneNumber) {
      const regexNum = /^[0-9]{10}$/;
      if (phoneNumber.length !== 10) {
        setIsError(true);
        setErrorMsg("Phone number should be of length 10");
        return;
      }
      if (!regexNum.test(phoneNumber)) {
        setIsError(true);
        setErrorMsg("Phone number is not valid");
        return;
      }
      data = { ...data, phoneNumber: phoneNumber };
    }
    if (password) {
      data = { ...data, password: password };
    }
    console.log(data);
    axios.post(`${API_URL}/user-credential/login`, data).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message);
        localStorage.setItem("token", res.data.token);
        // toast.success(res.data.message);
        getCartItems();
        // localStorage.setItem("user", JSON.stringify(res.data.user))
        navigate("/");
      } else {
        console.log(res);
        setIsError(true);
        setErrorMsg("Invalid Credentials");
      }
    });
  };
  const handleReset = () => {
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setIsError(false);
    setErrorMsg("");
  };
  const toggle = () => {
    setIsEmail(!isEmail);
    handleReset();
  };

  return (
    <div>
      <section className="md:h-screen">
        <div className="container h-full lg:px-20 px-5 py-5 md:py-10 mx-auto">
          <div className=" g-6 flex h-full flex-wrap items-center justify-center">
            {/* <!-- Left column container with background--> */}
            <div></div>
            <div className="hidden mb-12 md:flex items-center justify-center md:mb-0 md:w-8/12 lg:w-6/12 image-container">
              <img src={welcome} className="image p-20" alt="Phone" />
            </div>

            {/* <!-- Right column container with form --> */}
            <div className="md:w-8/12 lg:w-6/12 lg:bg-[#f1f1f1] md:rounded-r-3xl  flex items-center justify-center h-full w-full">
              <div className="w-80 md:py-0 py-10">
                {/* For Email */}
                <form onSubmit={handleLogin}>
                  {isEmail ? (
                    <div
                      className="relative mb-6 h-full"
                      data-te-input-wrapper-init
                    >
                      <input
                        type="text"
                        className="peer block min-h-[auto] w-full rounded-lg border-2 px-3 py-[0.32rem] leading-[2.15] "
                        id="email"
                        placeholder="Email address"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div
                      className="relative mb-6 h-full"
                      data-te-input-wrapper-init
                    >
                      <input
                        type="text"
                        className="peer block min-h-[auto] w-full rounded-lg border-2 px-3 py-[0.32rem] leading-[2.15] "
                        id="number"
                        placeholder="Mobile Number"
                        autoComplete="off"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <input
                      type="password"
                      className="peer block min-h-[auto] w-full rounded-lg border-2 px-3 py-[0.32rem] leading-[2.15] "
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="mb-6 flex items-center justify-between">
                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                      <input
                        className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-600 outline-none"
                        type="checkbox"
                        value=""
                        id="exampleCheck3"
                      />
                      <label
                        className="inline-block pl-[0.15rem] hover:cursor-pointer"
                        htmlFor="exampleCheck3"
                      >
                        Remember me
                      </label>
                    </div>
                    <div
                      onClick={() => navigate("/Forgot-password")}
                      className="text-primary cursor-pointer transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                    >
                      Forgot password?
                    </div>
                  </div>
                  {isError && <p className="text-red-500 mb-2">{errorMsg}</p>}
                  <button
                    type="submit"
                    className="inline-block w-full rounded bg-black px-7 pb-2.5 pt-3 mb-4 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    LOGIN
                  </button>
                  <Link
                    to="/signup"
                    className="text-gray-600 transition duration-150 ease-in-out hover:text-slate-900 text-center w-100 block text-sm"
                  >
                    REGISTER
                  </Link>
                </form>
                {/* <!-- Divider --> */}
                <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                  <p className="mx-4 mb-0 text-center font-semibold">OR</p>
                </div>

                {/* <!-- Social login buttons --> */}
                {/* <a
                  className="mb-4 flex w-full items-center justify-center rounded bg-white px-7 pb-2.5 pt-3 text-center text-sm font-medium uppercase leading-normal text-dark shadow-[0_4px_9px_-4px_#000] transition duration-150 ease-in-out hover:bg-dark-600 hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0),0_4px_18px_0_rgba(0,0,0,0)] focus:bg-dark-600 focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0),0_4px_18px_0_rgba(0,0,0,0)] focus:outline-none focus:ring-0 active:bg-dark-700 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.3),0_4px_18px_0_rgba(0,0,0,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(0,0,0,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.2),0_4px_18px_0_rgba(0,0,0,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.2),0_4px_18px_0_rgba(0,0,0,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.2),0_4px_18px_0_rgba(0,0,0,0.1)]"
                  href="#!"
                  role="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img src={google} className="mr-5 h-6" alt="google"></img>
                  Continue with Google
                </a> */}
                <div className="flex justify-center py-3 ">
                  <GoogleOAuthProvider clientId="603043942114-evic0nuv2ghv6jeo9a45ad2qfb1ufiua.apps.googleusercontent.com">
                    <GoogleLogin
                      className="cursor-pointer w-full"
                      buttonText="Continue with Google"
                      onSuccess={async (credentialResponse) => {
                        console.log(credentialResponse);
                        const res = await axios.post(
                          `${API_URL}/user-credential/sso-register`,
                          {
                            token: credentialResponse.credential,
                          }
                        );
                        console.log("res===>>", res.data);
                        if (res.data.status === 200) {
                          localStorage.setItem("token", res.data.token);
                          getCartItems();
                          navigate("/");
                        }
                        // localStorage.setItem("AuthData", JSON.stringify(data));
                        // setAuthData(data);
                      }}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                      // onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </GoogleOAuthProvider>
                </div>
                <a
                  className="mb-3 flex w-full items-center justify-center rounded bg-white px-7 pb-2.5 pt-3 text-center text-xs font-medium uppercase leading-normal text-dark shadow-[0_4px_9px_-4px_#000] transition duration-150 ease-in-out hover:bg-dark-600 hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0,),0_4px_18px_0_rgba(0,0,0,0)] focus:bg-dark-600 focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0),0_4px_18px_0_rgba(0,0,0,0)] focus:outline-none focus:ring-0 active:bg-dark-700 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.3),0_4px_18px_0_rgba(0,0,0,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(0,0,0,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.2),0_4px_18px_0_rgba(0,0,0,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.2),0_4px_18px_0_rgba(0,0,0,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.2),0_4px_18px_0_rgba(0,0,0,0.1)]"
                  href="#!"
                  role="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={toggle}
                >
                  {isEmail ? "Login with Phone Number" : "Login with Email"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
