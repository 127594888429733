import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../Url";
import { toast } from "react-toastify";

const VariationDropDown = ({
  setSelectedSubVariationIds,
  selectedSubVariationIds,
}) => {
  const [variations, setVariations] = useState([
    { id: 1, variation: "", subVariation: "" },
  ]);
  const [allVariations, setAllVariations] = useState([]);
  const [variationOptionId, setVariationOptionId] = useState([]);
  useEffect(() => {
    // Reset variations when selectedSubVariationIds is empty
    if (selectedSubVariationIds.length === 0) {
      setVariations([{ id: 1, variation: "", subVariation: "" }]);
    }
  }, [selectedSubVariationIds]);
  
  useEffect(() => {
    const fetchVariations = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/products/get-all-variations`
        );
        setAllVariations(response.data);
      } catch (error) {
        console.error("Error fetching variations:", error);
      }
    };
    fetchVariations();
  }, []);

  const handleVariationChange = (e, index) => {
    const newVariations = [...variations];
    newVariations[index].variation = e.target.value;
    newVariations[index].subVariation = "";
    setVariations(newVariations);
  };

  const handleSubVariationChange = (e, index) => {
    const newVariations = [...variations];
    newVariations[index].subVariation = e.target.value;

    const subVariationId = allVariations
      .find((v) => v.name === newVariations[index].variation)
      .value.find((subVariation) => subVariation.value === e.target.value)._id;

    setVariations(newVariations);

    setSelectedSubVariationIds((prevIds) => {
      const newIds = [...prevIds];
      newIds[index] = subVariationId;
      return newIds.filter(Boolean);
    });
  };

  const removeVariation = (index) => {
    const newVariations = [...variations];
    newVariations.splice(index, 1);

    setSelectedSubVariationIds((prevIds) => {
      const newIds = [...prevIds];
      newIds.splice(index, 1);
      return newIds;
    });

    setVariations(newVariations);
  };

  const addVariation = () => {
    const latestVariation = variations[variations.length - 1];
    if (!latestVariation.variation || !latestVariation.subVariation) {
      // Display toast if variation name or value is not selected
      toast.error("Please choose a variation name and value before adding.");
      return;
    }

    setVariations([
      ...variations,
      { id: variations.length + 1, variation: "", subVariation: "" },
    ]);
    setSelectedSubVariationIds((prevIds) => [...prevIds, ""]);
  };

  const renderVariationOptions = (index) => {
    const existingVariations = variations.slice(0, index);
    const availableVariations = allVariations.filter(
      (option) =>
        !existingVariations.some((v) => v.variation === option.name) &&
        option.name?.toLowerCase() !== "price"
    );

    return availableVariations.map((option) => (
      <option key={option.name} value={option.name}>
        {option.name}
      </option>
    ));
  };

  const hasAvailableVariations = variations.length < allVariations.length;

  return (
    <div className="space-y-4">
      {variations.map((variation, index) => (
        <div key={variation.id} className="flex space-x-4">
          <div className="w-1/2">
            <label
              htmlFor={`variation-${index}`}
              className="block font-semibold"
            >
              Variation :
            </label>
            <select
              required
              id={`variation-${index}`}
              value={variation.variation}
              onChange={(e) => handleVariationChange(e, index)}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Variation</option>
              {renderVariationOptions(index)}
            </select>
          </div>
          <div className="w-1/2">
            {variation.variation && (
              <div>
                <label
                  htmlFor={`subVariation-${index}`}
                  className="block font-semibold"
                >
                   Value :
                </label>
                <select
                  required
                  id={`subVariation-${index}`}
                  value={variation.subVariation}
                  onChange={(e) => handleSubVariationChange(e, index)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                >
                  <option value="">Select Value</option>
                  {allVariations
                    .find((v) => v.name === variation.variation)
                    .value.map((subVariation) => (
                      <option key={subVariation._id} value={subVariation.value}>
                        {subVariation.value}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
          {index !== 0 && (
            <button
              onClick={() => removeVariation(index)}
              className="bg-red-500 text-white rounded-md px-3 py-2 mt-auto"
            >
              Remove
            </button>
          )}
        </div>
      ))}
      {hasAvailableVariations && (
        <button
          onClick={(e) => {
            e.preventDefault();
            addVariation();
          }}
          className="bg-blue-500 text-white rounded-md px-3 py-2"
        >
          Add Variation
        </button>
      )}
    </div>
  );
};

export default VariationDropDown;
